.survey-sec {
  display: flex;

  height: 100vh;
  .left {
    width: 50%;
    align-items: center;
    background: linear-gradient(270deg, #32c4c0, #1f2d5c);
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    text-align: center;
    .survey-logo {
      width: 200px;
    }
    .account-vector {
      max-width: 400px;
      max-height: 400px;
    }
    h4 {
      font-family: "Zen Kaku Gothic Antique";
      font-size: 30px;
      line-height: 120%;
      margin-bottom: 10px;
    }
  }
  .inner {
    height: 100%;
    width: 50%;
    border-radius: 12px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .survey-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      row-gap: 20px;
      width: 500px;

      .top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 11px 11px 0px 0px;
        row-gap: 10px;

        h2 {
          font-family: "Zen Kaku Gothic Antique";
          font-size: 35px;
          font-weight: 600;
          line-height: 100%; /* 121.429% */
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 20px 20px;
        row-gap: 25px;
        width: 100%;
        h3 {
          color: #000;
          font-family: Zen Kaku Gothic Antique;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 170% */
        }
        input,
        textarea {
          border: none;
          outline: 0px;
          border-radius: 0px;
          border-bottom: 1px solid #bdbdbd;
          box-shadow: none;
          padding-left: 0px;
          color: #212121;
          font-family: "Zen Kaku Gothic Antique";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          width: 100%;
        }
        textarea {
          min-height: 100px;
          height: 100%;
        }
        .buttons {
          display: flex;
          justify-content: end;
          column-gap: 10px;
          .cancel-btn {
            border-radius: 96px;
            border: 3px solid var(--as, #32c4c0);
            color: #000;
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;

            /* Text Single/200/Bold */
            font-family: DM Sans;
            font-size: 12.8px;
            font-style: normal;
            font-weight: 700;
            height: 56px;
            padding: 0px 40px;
            text-align: center;
            background: white;
          }
          .save-btn {
            border-radius: 96px;
            background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
            color: var(--neutral-100, #fff);
            text-align: center;
            font-feature-settings: "clig" off, "liga" off;

            /* Text Single/200/Bold */
            font-family: DM Sans;
            font-size: 12.8px;
            font-style: normal;
            font-weight: 700;
            height: 56px;
            padding: 0px 40px;
            text-align: center;
            border: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .survey-sec {
    .left {
      gap: 30px;
      .account-vector {
        max-width: 300px;
        max-height: 300px;
      }
      h4 {
        font-size: 25px;
      }
    }
    .inner {
      .survey-card {
        width: 450px;

        .top {
          h2 {
            font-size: 30px;
          }
        }
        .bottom {
          padding: 10px 0px;
          h3 {
            font-size: 22px;
          }
          input,
          textarea {
            font-size: 18px;
          }
          .buttons {
            .cancel-btn {
              height: 40px;
              padding: 0px 30px;
            }
            .save-btn {
              height: 40px;
              padding: 0px 30px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .survey-sec {
    .left {
      display: none;
    }
    .inner {
      height: 100%;
      width: 100%;
      .survey-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        row-gap: 10px;
        width: 100%;
        padding: 20px;
        .top {
          h2 {
            font-size: 25px;
          }
        }
        .bottom {
          padding: 0;
          row-gap: 10px;
          h3 {
            font-size: 20px;
          }
          input,
          textarea {
            font-size: 17px;
          }
          textarea {
            min-height: 50px;
          }
          .buttons {
            display: flex;
            justify-content: end;
            column-gap: 10px;
            .cancel-btn {
              height: 40px;
              padding: 0px 30px;
            }
            .save-btn {
              height: 40px;
              padding: 0px 30px;
            }
          }
        }
      }
    }
  }
}
