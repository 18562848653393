.contactus-sec {
  background: linear-gradient(315deg, #fff, #32c4c0, #4a3aff, #231f37, #000);
  background-size: 250% 250%;
  width: 100%;
  padding: 75px 100px;

  .main {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    border-radius: 41px;
    background: rgb(255, 255, 255);
    margin-top: 50px;

    .content-side {
      width: 50%;
      display: flex;
      // align-items: center;
      justify-content: center;
      .inner {
        width: 100%;
        max-width: 545px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        justify-content: center;
        padding: 50px;
        h3 {
          color: var(--black, #000);
          font-family: "Inter";
          font-size: 54px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          margin-bottom: 0;
        }
        p {
          color: #000;
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 171.429% */
          letter-spacing: 0.14px;
          margin-bottom: 0;
        }
        input {
          border: 1px solid var(--Gray-5, #e0e0e0);
          padding: 12px 20px;
          color: #828282;
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.14px;
        }
        .Contact-dropdown {
          border: 1px solid var(--Gray-5, #e0e0e0);
          padding: 12px 20px;
          color: black;
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          letter-spacing: 0.14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .dropdown-toggle {
            color: black;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .contact-dropdownmenu {
            width: 100%;
            position: absolute;
            inset: 0px auto auto 0px;
            transform: translate(0px, 54px) !important;
            border: 1px solid var(--Gray-5, #e0e0e0);
            border-radius: 0px;
          }
        }
        .send-btn {
          background: linear-gradient(90deg, #265d7c 0%, #2fa8ae 100%);
          display: flex;
          padding: 12px 24px;
          justify-content: center;
          align-items: center;
          border: none;
          color: white;
        }
        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .ist {
            display: flex;
            align-items: center;
            column-gap: 15px;
            img {
              width: 28px;
              height: 28px;
              object-fit: cover;
            }
            .detail {
              display: flex;
              flex-direction: column;

              h6 {
                color: var(--black, #000);
                font-family: "Inter";
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; /* 153.846% */
                letter-spacing: 0.26px;
              }
              span {
                color: #32c4c0;
                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.26px;
              }
            }
          }
        }
      }
    }
    .image-side {
      width: 50%;
      img {
        width: 30%;
        height: 30%;
        margin-left: 160px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .contactus-sec {
    .main {
      .content-side {
        .inner {
          max-width: 400px;
          h3 {
            font-size: 40px;
          }

          input {
            padding: 10px 15px;
            line-height: 120%;
          }
          .Contact-dropdown {
            padding: 10px 15px;
            line-height: 120%; /* 171.429% */
          }
          .send-btn {
            padding: 10px 20px;
          }
          .info {
            flex-wrap: wrap;
            row-gap: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .contactus-sec {
    .main {
      .content-side {
        .inner {
          row-gap: 15px;
          .info {
            row-gap: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contactus-sec {
    padding: 20px;

    .main {
      border-radius: 20px;
      flex-direction: column;
      .content-side {
        width: 100%;
        .inner {
          row-gap: 10px;
          padding: 15px;
          h3 {
            font-size: 25px;
          }

          input {
            padding: 10px;
            font-size: 14px;
            line-height: 120%;
          }
          .Contact-dropdown {
            padding: 10px;
            font-size: 14px;
            line-height: 120%;
            .dropdown-toggle {
              font-size: 14px;
            }
          }
          .send-btn {
            padding: 10px 20px;
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 15px;
            .ist {
              display: flex;
              align-items: center;
              column-gap: 15px;
            }
          }
        }
      }
      .image-side {
        display: none;
      }
    }
  }
}


@media screen and (max-width: 990px) {
  .image-side {
    display: none;
  }
  .cardUpdatedresponsiveness{
    width: 100%;
  }
}