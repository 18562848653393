.users-sec {
  margin: 70px 75px 150px 65px;
  overflow-x: auto;

  .css-rqglhn-MuiTable-root {
    min-width: 1400px;
  }

  .MuiTableCell-head {
    background: #297e91;
    color: white;
  }

  .tableuser {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
    object-fit: cover !important;
  }
}

// .profileImg {
//   width: 50px;
//   height: 50px;
//   border-radius: 50px;
//   object-fit: cover !important;
// }

@media screen and (max-width: 600px) {
  .users-sec {
    margin: 0px;
    overflow-x: auto;

    .MuiTableCell-body {
      padding: 10px 5px 10px 10px !important;
    }
  }
}
