.coming-soon-sec {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url(../assets/image/home/coming-soon-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  h1 {
    color: #fff;
  }
}
.whyUs-sec {
  .whyUs-banner {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;

    .left {
      color: white;
      position: relative;
      z-index: 2;
      text-align: center;
      // max-width: 65%;
      max-width: 1360px;
      width: 100%;
      // margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .right {
      img {
        width: 100%;
      }
    }
  }

  .hero-bg-video {
    width: 100%;
    z-index: 1;
    // transform: rotate(180deg);
    &::after {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #fff;
      backdrop-filter: blur(6.25197696685791px);
    }
  }

  .intro-sec {
    padding: 75px 204px 0px 198px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 144px;

    .left {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;

      // max-width: 610px;
      p {
        text-align: left;
      }
    }
  }

  .intro-sec-roadmap {
    padding: 0px 204px 150px 198px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .roadmap-cards-top {
      display: flex;
      margin-top: 100px;
      margin-bottom: -47px;
      gap: 100px;
      .card1 {
        display: flex;
        flex-direction: column;
        gap: 50px;
        .main-card1 {
          position: relative;
          .r-card {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f5f5f5;
            padding: 30px;
            border: 12px solid #32c4c0;
            border-top-left-radius: 50px;
            border-bottom-right-radius: 50px;
            min-width: 350px;
            min-height: 170px;

            p {
              max-width: 230px;
              text-align: center;
            }
          }
          .triangle-div {
            position: absolute;
            bottom: -33px;
            left: 59px;
            width: 0px;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 22px solid transparent;
            border-top: 39px solid #32c4c0;
          }
        }
        .circle1 {
          position: relative;
          width: 60px;
          height: 60px;
          border-radius: 100px;
          background: #32c4c0;
          margin-left: 24px;
          z-index: 9;

          p {
            color: white !important;
            font-weight: 400;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
          }
        }
      }
      .card2 {
        display: flex;
        flex-direction: column;
        gap: 50px;
        .main-card2 {
          position: relative;
          .r-card {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f5f5f5;
            padding: 30px;
            border-top-left-radius: 50px;
            border-bottom-right-radius: 50px;
            min-width: 350px;
            min-height: 170px;
            border: 12px solid #203863;

            p {
              max-width: 230px;
              text-align: center;
            }
          }
          .triangle-div {
            position: absolute;
            bottom: -33px;
            left: 53px;
            width: 0px;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 22px solid transparent;
            border-top: 39px solid #203863;
          }
        }
        .circle3 {
          position: relative;
          width: 60px;
          height: 60px;
          border-radius: 100px;
          background: #32c4c0;
          margin-left: 24px;
          z-index: 9;

          p {
            color: white !important;
            font-weight: 400;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
          }
        }
      }
    }
    .partition-line {
      width: 100%;
      border-top: 2px dashed black;
    }
    .roadmap-cards-bottom {
      display: flex;
      gap: 100px;
      margin-top: -47px;
      .card4 {
        display: flex;
        flex-direction: column;
        gap: 60px;
        .main-card4 {
          position: relative;
          .r-card {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f5f5f5;
            padding: 30px;
            border: 12px solid #203863;
            border-top-left-radius: 50px;
            border-bottom-right-radius: 50px;
            min-width: 350px;
            min-height: 170px;
            p {
              max-width: 230px;
              text-align: center;
            }
          }
          .triangle-div {
            position: absolute;
            top: -43px;
            right: 53px;
            border-left: 22px solid transparent;
            border-right: 0px solid transparent;
            border-bottom: 49px solid #203863;
          }
        }
        .circle- {
          display: flex;
          justify-content: flex-end;
          margin-right: 23px;
          .circle2 {
            z-index: 9;
            position: relative;
            width: 60px;
            height: 60px;
            border-radius: 100px;
            background: #203863;
            p {
              color: white !important;
              font-weight: 400;
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%);
              top: 50%;
            }
          }
        }
      }
      .card5 {
        display: flex;
        flex-direction: column;
        gap: 60px;
        .main-card5 {
          position: relative;
          .r-card {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #f5f5f5;
            padding: 30px;
            border: 12px solid #32c4c0;
            border-top-left-radius: 50px;
            border-bottom-right-radius: 50px;
            min-width: 350px;
            min-height: 170px;
            p {
              max-width: 230px;
              text-align: center;
            }
          }
          .triangle-div {
            position: absolute;
            top: -43px;
            right: 53px;
            border-left: 22px solid transparent;
            border-right: 0px solid transparent;
            border-bottom: 49px solid #32c4c0;
          }
        }
        .circle- {
          display: flex;
          justify-content: flex-end;
          margin-right: 23px;
          .circle4 {
            z-index: 9;
            position: relative;
            width: 60px;
            height: 60px;
            border-radius: 100px;
            background: #203863;
            p {
              color: white !important;
              font-weight: 400;
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%);
              top: 50%;
            }
          }
        }
      }
    }
  }

  .why-card-sec {
    position: relative;
    overflow: hidden;

    min-height: 1780px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin-top: -2rem;
    background: url("../../public/assests/images/pattern_large.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: -210px;
    margin-bottom: -420px;

    .title-wrapper {
      width: 62%;
      text-align: center;
      padding-bottom: 110px;

      h3 {
        z-index: 4;
        position: relative;
        color: white;
      }
    }

    .cards-wrapper {
      display: flex;
      justify-content: center;
      z-index: 3;
      position: relative;
      flex-wrap: wrap;
      gap: 50px;

      .simplicard {
        // border: 3.041px solid #fff;
        transition: 0.5s ease-in-out;
        text-align: center;
        width: 455.593px;
        height: 264.591px;
        flex-shrink: 0;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #fff;
        border-radius: 10px;
        position: relative;

        .card-image-wrapper {
          position: relative;

          .setting {
            position: absolute;
            bottom: 0;
            transition: 0.5s ease-in-out;
          }

          .file {
            transition: 0.5s ease-in-out;
          }

          .showonhover {
            position: absolute;
            visibility: hidden;
            left: 0;
          }
        }

        .card-title {
          color: #32c4c0;
          padding-top: 25px;
        }

        p {
          font-size: 16px;
        }

        &:hover {
          background-color: transparent;
          cursor: pointer;

          .efficiency {
            svg .svg-elem-1 {
              stroke-dashoffset: 226.5587615966797px;
              stroke-dasharray: 113.27938079833984px;
              fill: transparent;
              -webkit-transition: stroke-dashoffset 1s
                  cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
              transition: stroke-dashoffset 1s
                  cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
            }

            svg.active .svg-elem-1 {
              stroke-dashoffset: 113.27938079833984px;
              fill: transparent;
            }

            svg .svg-elem-2 {
              stroke-dashoffset: 434.6294860839844px;
              stroke-dasharray: 217.3147430419922px;
              fill: transparent;
              -webkit-transition: stroke-dashoffset 1s
                  cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
              transition: stroke-dashoffset 1s
                  cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
            }

            svg.active .svg-elem-2 {
              stroke-dashoffset: 217.3147430419922px;
              fill: transparent;
            }

            svg .svg-elem-3 {
              stroke-dashoffset: 249.04422698000386px;
              stroke-dasharray: 124.52211349000193px;
              fill: transparent;
              -webkit-transition: stroke-dashoffset 1s
                  cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
              transition: stroke-dashoffset 1s
                  cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
            }

            svg.active .svg-elem-3 {
              stroke-dashoffset: 124.52211349000193px;
              fill: transparent;
            }

            svg .svg-elem-4 {
              stroke-dashoffset: 73.11503837897544px;
              stroke-dasharray: 36.55751918948772px;
              fill: transparent;
              -webkit-transition: stroke-dashoffset 1s
                  cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
              transition: stroke-dashoffset 1s
                  cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
                fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
            }

            svg.active .svg-elem-4 {
              stroke-dashoffset: 36.55751918948772px;
              fill: transparent;
            }
          }

          svg .svg-elem-1 {
            stroke-dashoffset: 481.5933532714844px;
            stroke-dasharray: 240.7966766357422px;
            fill: transparent;
            -webkit-transition: stroke-dashoffset 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
            transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715)
                0s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s;
          }

          svg.active .svg-elem-1 {
            stroke-dashoffset: 240.7966766357422px;
            fill: transparent;
          }

          svg .svg-elem-2 {
            stroke-dashoffset: 440.7178955078125px;
            stroke-dasharray: 220.35894775390625px;
            fill: transparent;
            -webkit-transition: stroke-dashoffset 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
            transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715)
                0.12s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s;
          }

          svg.active .svg-elem-2 {
            stroke-dashoffset: 220.35894775390625px;
            fill: transparent;
          }

          svg .svg-elem-3 {
            stroke-dashoffset: 534.86181640625px;
            stroke-dasharray: 267.430908203125px;
            fill: transparent;
            -webkit-transition: stroke-dashoffset 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.24s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
            transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715)
                0.24s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1s;
          }

          svg.active .svg-elem-3 {
            stroke-dashoffset: 267.430908203125px;
            fill: transparent;
          }

          svg .svg-elem-4 {
            stroke-dashoffset: 216.03350830078125px;
            stroke-dasharray: 108.01675415039062px;
            fill: transparent;
            -webkit-transition: stroke-dashoffset 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.36s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
            transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715)
                0.36s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s;
          }

          svg.active .svg-elem-4 {
            stroke-dashoffset: 108.01675415039062px;
            fill: transparent;
          }

          svg .svg-elem-5 {
            stroke-dashoffset: 85.60433197021484px;
            stroke-dasharray: 42.80216598510742px;
            fill: transparent;
            -webkit-transition: stroke-dashoffset 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.48s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
            transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715)
                0.48s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s;
          }

          svg.active .svg-elem-5 {
            stroke-dashoffset: 42.80216598510742px;
            fill: transparent;
          }

          svg .svg-elem-6 {
            stroke-dashoffset: 36.31852722167969px;
            stroke-dasharray: 18.159263610839844px;
            fill: transparent;
            -webkit-transition: stroke-dashoffset 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
            transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715)
                0.6s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s;
          }

          svg.active .svg-elem-6 {
            stroke-dashoffset: 18.159263610839844px;
            fill: transparent;
          }

          svg .svg-elem-7 {
            stroke-dashoffset: 36.318389892578125px;
            stroke-dasharray: 18.159194946289062px;
            fill: transparent;
            -webkit-transition: stroke-dashoffset 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.72s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
            transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715)
                0.72s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s;
          }

          svg.active .svg-elem-7 {
            stroke-dashoffset: 18.159194946289062px;
            fill: transparent;
          }

          svg .svg-elem-8 {
            stroke-dashoffset: 36.318519592285156px;
            stroke-dasharray: 18.159259796142578px;
            fill: transparent;
            -webkit-transition: stroke-dashoffset 1s
                cubic-bezier(0.47, 0, 0.745, 0.715) 0.84s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
            transition: stroke-dashoffset 1s cubic-bezier(0.47, 0, 0.745, 0.715)
                0.84s,
              fill 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s;
          }

          svg.active .svg-elem-8 {
            stroke-dashoffset: 18.159259796142578px;
            fill: transparent;
          }

          // ===============================
          svg {
            position: relative;
            z-index: 111;
          }

          .setting {
            transform: rotate(359deg);
            filter: brightness(0) invert(1);
          }

          .file {
            filter: brightness(0) invert(1);
          }

          img {
            z-index: 2;
            position: relative;
          }

          .showonhover {
            // display: block;
            visibility: visible;
          }

          .show {
            visibility: hidden;
          }

          h5 {
            color: white;
            z-index: 2;
          }

          p {
            color: white;
            z-index: 2;
          }

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            backdrop-filter: blur(6.25197696685791px);
            border-radius: 10.141px;
            opacity: 0.6;

            border-radius: 10.141px;
            border: 3.041px solid #fff;
            opacity: 0.6;
            background: linear-gradient(
              90deg,
              rgba(204, 149, 192, 0.6) 0%,
              rgba(219, 212, 180, 0.6) 50%,
              rgba(122, 161, 210, 0.6) 100%
            );
            backdrop-filter: blur(6.33788537979126px);
            z-index: 1;
          }
        }
      }
    }

    .pattern {
      position: absolute;
      width: 100%;
      top: 0;
      z-index: 2;
    }
  }

  .learning_sec {
    padding: 200px 204px;
    background-color: #f5f5f5;

    h1 {
      font-family: Poppins;
      font-size: 80px;
      font-weight: 600;
      color: #000428;
      text-align: center;
    }

    .tab_section {
      margin-top: 50px;

      h2 {
        font-size: 50px;
        font-family: Poppins;
        font-weight: 600;
        color: #000428;
        text-align: center;
        line-height: 80px !important;
        margin-bottom: 10px;
      }

      .tab_btns {
        display: flex;
        gap: 30px;
        margin-bottom: 40px;
        overflow-x: auto;
        justify-content: center;

        .tab_btn {
          background: transparent;
          border: none;
          outline: none;
          font-size: 20px;
          font-weight: 500;
          font-family: Poppins;
        }

        .active_tab {
          background: transparent;
          border: none;
          outline: none;
          font-size: 20px;
          font-weight: 500;
          font-family: Poppins;
          border-bottom: 4px solid #32c4c0;
        }
      }

      .tab_content {
        .slider_container {
          h3 {
            font-size: 30px;
            font-weight: 500;
            color: #000428;
            font-family: Poppins;
            margin-bottom: 35px;
          }

          .slider {
            img {
              border-radius: 8px;
            }
            .slick-next:before {
              content: "";
            }

            .slick-prev:before {
              content: "";
            }
          }

          .slider_card {
            max-width: 320px;
            min-height: 300px;

            img {
              min-height: 230px;
              width: 100%;
              margin-bottom: 20px;
            }

            p {
              font-size: 20px;
              font-weight: 600;
              font-family: Poppins;
            }
          }
        }
        .course-video {
          width: 320px;
          height: 230px;
          border-radius: 8px;
          margin-bottom: 20px;
          object-fit: cover;
        }
      }
    }
  }

  .newsletter-section {
    display: flex;

    .left {
      background: linear-gradient(90deg, #000428 0%, #004e92 100%);
      padding-left: 30px;
      padding-right: 30px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-top: 80px;
      padding-bottom: 80px;

      .newsletter-module {
        // border-radius: 30px;
        // background: #fff;
        // box-shadow: 0px 34px 69px 0px rgba(0, 0, 0, 0.19);
        padding: 40px;
        color: black;
        text-align: center;
        max-width: 740px;
        margin: 0 auto;
        margin-top: 50px;
        height: 350px;

        h5 {
          color: #32c4c0;
          text-align: center;
          font-family: Inter;
          font-size: 26px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        p {
          color: #353535;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        input {
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.12);
          width: 100%;
          padding: 8px 10px;
          text-align: center;
          margin-top: 16px;
        }

        button {
          border-radius: 50px;
          border: 2px solid rgba(255, 255, 255, 0.35);
          background: linear-gradient(270deg, #32c4c0 0%, #1f2d5c 100%);
          box-shadow: 0px 10px 40px 0px #32c4c0;
          width: 100%;
          color: #fff;
          text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          padding: 20px;
          display: flex;
          justify-content: center;
          gap: 10px;
          margin-top: 16px;
          border: 0;

          img {
            margin-top: 2.8px;
          }
        }
      }
    }
  }

  .numbers-section {
    display: flex;
    justify-content: center;
    padding: 100px 0px;
    color: white;

    .numbers-content {
      background: url("../../public/assests/images/numberBg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 80%;
      display: flex;
      gap: 73px;
      padding: 50px 20px;
      align-items: center;

      .first {
        h3 {
          color: #fff;
          font-family: Poppins;
          font-size: 42.231px;
          font-style: normal;
          font-weight: 600;
          line-height: 55.745px;
          /* 132% */
        }
      }

      .second {
        .number {
          color: #fff;
          font-family: Poppins;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 35px;
          /* 132% */

          .countdowns {
            text-align: center;
          }
        }

        p {
          color: #fff;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }

  .intro-sec.goodbye {
    padding-bottom: 150px;
    padding-top: 100px;
    h3 {
      font-size: 46px;
    }
    button {
      width: fit-content;
      width: 204.63px;
      height: 68px;
      margin-top: 35px;
    }
  }
}
@media screen and (max-width: 1650px) {
  .whyUs-sec {
    .intro-sec-roadmap {
      padding: 0px 100px 150px 100px;
    }
  }
}
@media screen and (max-width: 1460px) {
  .whyUs-sec {
    .intro-sec-roadmap {
      .roadmap-cards-top {
        gap: 80px;
        .card1 {
          .main-card1 {
            .r-card {
              padding: 25px;
              min-width: 300px;
              min-height: 160px;

              p {
                font-size: 18px !important;
              }
            }
          }
        }
        .card2 {
          .main-card2 {
            .r-card {
              padding: 25px;
              min-width: 300px;
              min-height: 160px;

              p {
                font-size: 18px !important;
              }
            }
          }
        }
      }
      .roadmap-cards-bottom {
        gap: 80px;
        .card4 {
          .main-card4 {
            .r-card {
              padding: 25px;
              min-width: 300px;
              min-height: 160px;
              p {
                font-size: 18px !important;
              }
            }
          }
        }
        .card5 {
          .main-card5 {
            .r-card {
              padding: 25px;
              min-width: 300px;
              min-height: 160px;
              p {
                font-size: 18px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1250px) {
  .whyUs-sec {
    .intro-sec-roadmap {
      .roadmap-cards-top {
        gap: 50px;
        .card1 {
          .main-card1 {
            .r-card {
              min-width: 250px;

              p {
                font-size: 16px !important;
              }
            }
          }
        }
        .card2 {
          .main-card2 {
            .r-card {
              min-width: 250px;

              p {
                font-size: 16px !important;
              }
            }
          }
        }
      }
      .roadmap-cards-bottom {
        gap: 50px;
        .card4 {
          .main-card4 {
            .r-card {
              min-width: 250px;
              p {
                font-size: 16px !important;
              }
            }
          }
        }
        .card5 {
          .main-card5 {
            .r-card {
              min-width: 250px;
              p {
                font-size: 16px !important;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1338px) {
  .whyUs-sec {
    h1 {
      font-size: 35px;
      line-height: normal;
    }
  }
}

@media (max-width: 1521px) {
  .learning_sec {
    padding: 100px !important;
  }

  .whyUs-sec {
    .why-card-sec {
      padding-top: 100px;
      // min-height: unset;
      // background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
      // margin-top: 100px;
      // margin-bottom: 0px;
      padding-bottom: 100px;
      margin-bottom: -420px;

      .cards-wrapper {
        display: flex;
        justify-content: center;
        z-index: 3;
        position: relative;
        gap: 30px;
      }
    }
  }

  .numbers-section {
    .second {
      .number {
        h3 {
          font-size: 28px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .learning_sec {
    padding: 100px !important;
  }

  .whyUs-sec {
    .newsletter-section {
      background: linear-gradient(90deg, #000428 0%, #004e92 100%);
      flex-direction: column;
      align-items: center;
      padding: 50px;

      .left {
        background: unset;
        padding-left: 0;

        .content {
          .newsletter-module {
            max-width: 55%;
            margin: 0 auto;
            margin-bottom: 50px;
            margin-top: 50px;
          }
        }
      }

      .right {
        display: none;
      }
    }

    .intro-sec {
      display: flex;
      padding: 100px 100px;

      .left {
        width: 50%;
      }

      .right {
        width: 50%;
      }
    }
  }
}

@media (max-width: 1316px) {
  .learning_sec {
    padding: 100px !important;
  }

  .newsletter-section {
    .left.news-left {
      .content {
        h3 {
          text-align: center;
        }
      }
    }
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
  }

  50% {
    stroke-dashoffset: 240.7966766357422px;
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 0;
    fill: rgb(255, 255, 255);
  }
}

@media (max-width: 1800px) {
  .learning_sec {
    padding: 100px;
  }

  .whyUs-sec .intro-sec {
    padding: 75px 100px 0px 100px;

    gap: 50px;
  }
}

@media (max-width: 1500px) {
  .whyUs-sec .why-card-sec .cards-wrapper .simplicard {
    width: 33.33%;
  }

  .whyUs-sec {
    h3 {
      font-size: 37px;
      line-height: normal;
    }
  }
}

@media (max-width: 1740px) {
  .learning_sec {
    padding: 100px !important;
  }
  .whyUs-sec .why-card-sec {
    // margin-bottom: -140px;
    margin-bottom: -360px;
  }
}

@media screen and (max-width: 1500px) {
  .whyUs-sec .whyUs-banner h1 {
    font-size: 65.886px;
    line-height: 62.586px;
  }
  .whyUs-sec .learning_sec .tab_section .tab_btns {
    gap: 25px;
  }
}

@media screen and (max-width: 1400px) {
  .whyUs-sec .learning_sec .tab_section h2 {
    font-size: 40px;
    text-align: center;
    line-height: 50px !important;
  }
}

@media screen and (max-width: 1366px) {
  .slick-prev {
    left: -30px !important;
  }
  .whyUs-sec .intro-sec.goodbye {
    padding-bottom: 100px;
    padding-top: 70px;
  }
}

@media (max-width: 600px) {
  .coming-soon-sec {
    height: 30vh;
    background-size: cover;
    h1 {
      font-size: 28px;
      text-align: center;
    }
  }
  .whyUs-sec {
    .intro-sec-roadmap {
      padding: 0px 20px 70px 20px;
      .roadmap-cards-top {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        margin-bottom: 0px;
        gap: 20px;
        .card1 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0px;
          .main-card1 {
            .r-card {
              width: 340px;
              min-height: 140px;

              p {
                font-size: 14px !important;
              }
            }
            .triangle-div {
              display: none;
            }
          }
          .circle1 {
            display: none;
          }
        }
        .card2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0px;
          .main-card2 {
            .r-card {
              width: 340px;
              min-height: 140px;

              p {
                font-size: 14px !important;
              }
            }
            .triangle-div {
              display: none;
            }
          }
          .circle3 {
            display: none;
          }
        }
      }
      .partition-line {
        display: none;
      }
      .roadmap-cards-bottom {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        .card4 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0px;
          .main-card4 {
            .r-card {
              width: 340px;
              min-height: 140px;
              p {
                font-size: 14px !important;
              }
            }
            .triangle-div {
              display: none;
            }
          }
          .circle- {
            display: none;
          }
        }
        .card5 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0px;
          .main-card5 {
            .r-card {
              width: 340px;
              min-height: 140px;
              p {
                font-size: 14px !important;
              }
            }
            .triangle-div {
              display: none;
            }
          }
          .circle- {
            display: none;
          }
        }
      }
    }
  }
  .whyUs-sec .learning_sec h1 {
    font-size: 16px;
  }

  .whyUs-sec .learning_sec .tab_section h2 {
    font-size: 14px;
    line-height: 25px !important;
    padding: 12px 0px;
  }

  .whyUs-sec .learning_sec .tab_section {
    margin-top: 0px;
  }

  .whyUs-sec .learning_sec .tab_section .tab_btns .active_tab {
    font-size: 12px;
  }
  .whyUs-sec .learning_sec .tab_section .tab_btns {
    justify-content: left !important;
  }
  .whyUs-sec .learning_sec .tab_section .tab_btns .tab_btn {
    font-size: 12px;
  }

  .whyUs-sec .learning_sec .tab_section .tab_content .slider_container h3 {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .slick-next {
    right: -70px !important;
    height: 20px !important;
  }

  .slick-prev {
    left: -15px !important;
    z-index: 30 !important;
    height: 20px !important;
    // height: 25px !important;
  }

  .whyUs-sec
    .learning_sec
    .tab_section
    .tab_content
    .slider_container
    .slider_card
    p {
    font-size: 14px;
    line-height: 100%;
  }
  .whyUs-sec {
    .whyUs-banner {
      height: 300px;
      h1 {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: unset !important;
      }
      p {
        font-size: 12px;
      }
      .hero-bg-video {
        height: 100%;
        object-fit: cover;
      }
      .left {
        top: 56%;
        max-width: 100%;
        width: 100%;
      }
    }

    .intro-sec {
      flex-direction: column;
      padding: 40px 20px 10px 20px;
      gap: 20px;

      .left {
        width: 100%;
      }

      .right {
        width: 100%;
        h3 {
          font-size: 18px;
          margin-bottom: 5px;
        }
        p {
          font-size: 10px;
          line-height: 15px;
        }
      }
    }

    .why-card-sec {
      padding-block: 20px;
      margin-block: 50px;
      min-height: 0px;
      background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);

      .title-wrapper {
        padding-bottom: 20px;
        width: 70%;
        h3 {
          font-size: 17px;
        }
      }
      .cards-wrapper {
        align-items: center;
        flex-direction: column;
        gap: 15px;

        .simplicard {
          width: 100%;
          padding: 10px;
          height: 200px;

          .card-image-wrapper {
            .setting {
              width: 45%;
            }
            .file {
              width: 75%;
            }
            .svg {
              width: 75%;
            }
          }

          .efficiency {
            svg {
              width: 75%;
            }
          }

          .card-title {
            font-size: 14px;
            line-height: 20px;
            padding-top: 5px;
          }
          p {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }

    .learning_sec {
      padding: 20px !important;
      overflow: hidden;
    }
    .intro-sec.goodbye.goodbye {
      margin-top: -50px;
      padding-bottom: 50px;
    }

    .newsletter-section {
      padding-top: 30px;
      padding-bottom: 40px;
      padding-inline: 20px;

      .left.news-left {
        width: 100%;
        padding: 0px;

        .content {
          width: 100%;
          h3 {
            font-size: 14px;
          }
          p {
            font-size: 11px;
            line-height: 20px;
          }
          .newsletter-module {
            max-width: 100% !important;
            margin-block: 10px;
            padding: 20px;

            h5 {
              font-size: 14px;
            }
            input {
              margin-top: 5px;
              font-size: 10px;
            }
            button {
              font-size: 12px;
              padding: 15px 5px;
            }
          }
        }
      }
    }

    .numbers-section {
      padding-block: 40px;
      .numbers-content {
        width: 100%;
        padding: 10px 0px;
        .second {
          .number {
            font-size: 10px;
            line-height: 20px;

            h3 {
              font-size: 12px;
              margin-bottom: 3px !important;
            }

            .countdown {
              gap: 10px !important;
            }
          }

          p {
            font-size: 20px;
          }
        }
      }
    }
  }
  .footer-logo {
    max-width: 60% !important;
  }
}

.card_center {
  display: flex !important;
  justify-content: center;
}
