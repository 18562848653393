.summarize-topbar {
  gap: 100px;
  // background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
  background: linear-gradient(270deg, #32c4c0 0%, #1f2d5c 100%);
  height: 131px;
  padding: 0 75px 0 53px;
  // position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;

  .right-content {
    border-radius: 38.384px;
    background: var(--base-background-white, #fff);
    box-shadow: 0px -0.76768px 0px 0px #f1f1f1 inset,
      0px 0.76768px 13.81829px 0px rgba(0, 0, 0, 0.09);
    display: flex;
    // max-width: 1259px;
    width: 100%;
    padding: 15.354px 27.637px;
    justify-content: space-between;
    align-items: center;

    .profileIcon {
      object-fit: cover;
      margin: auto;
      width: 100%;
      height: 29px;
      border-radius: 100%;
    }
  }

  .left-content {
    img {
      width: 172px;
      height: 40px;
    }
  }
}

button#dropdown-basic {
  background: transparent;
  border: none;
  padding: 0;
}

@media screen and (max-width: 600px) {
  .summarize-topbar .left-content {
    display: flex;
    margin-top: 15px;
    align-self: flex-start;
    background-color: red;
  }
}
