.top-pricing-content {
  // background: linear-gradient(90deg, #070e4e 0%, #004e92 100%);
  background: linear-gradient(269deg, #32c4c0 0%, #1f2d5c 100%);
  .inner-content {
    padding: 96px 270px 96px 270px;
    h3 {
      color: #fff;
    }
    p {
      margin-top: 10px;
      color: #fff;
      font-size: 20px;
    }
    ol {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
      padding: 0 300px;
      gap: 10px;
      li {
        color: #fff;
        list-style: none;
        margin-top: 10px;
        span {
          font-weight: 700;
        }
      }
    }
  }
}
.package-sec {
  background-color: white;
  padding: 76px 270px 130px 270px;

  .regist-heading {
    font-size: 50px;
    font-weight: bold;
    color: #1f2d5c;
    margin-bottom: 37px;
  }
  // Package selection section --------------------------------------------------------------------------------- //
  .package-selection {
    display: flex;
    align-items: center;
    gap: 14px;
    .step-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #1f2d5c;
      color: white;
      width: 50px;
      height: 51px;
      padding: 15px 0px 16px 0px;
      border-radius: 44px;
    }
    .step-pack-select {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 36px; /* 180% */
    }
  }

  // select plan checkbox--------------------------------------------------------------------------------- //
  .select-plan {
    margin-top: 60px;
    color: #170f49;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    input[type="checkbox"] {
      position: relative;
      width: 74px;
      height: 33px;
      -webkit-appearance: none;
      appearance: none;
      background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
      outline: none;
      border-radius: 2rem;
      cursor: pointer;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 33px;
      height: 33px;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.5s;
    }

    input[type="checkbox"]:checked::before {
      transform: translateX(125%);
      background: #fff;
    }

    input[type="checkbox"]:checked {
      background: linear-gradient(90deg, #dc2424 0%, #4a569d 100%);
    }
  }

  // Package cards section--------------------------------------------------------------------------------- //
  .package-cards-sec {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    padding-top: 84px;
    padding-bottom: 130px;

    button {
      border: none;
    }
  }

  // Registration form section--------------------------------------------------------------------------------- //
  .registration-form {
    background: #fafafc;
    color: #1f2d5c;
    box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.15);
    padding: 80px 156px 95px 157px;

    .personal-input {
      margin-top: 5rem;
    }
    .input-fields {
      display: grid;
      grid-template-columns: 1fr 1fr; /* Two columns layout for input fields */
      gap: 46px;

      .form-group {
        position: relative;

        label {
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
        }

        input {
          width: 100%;
          padding: 8px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          border-radius: 8px;
          border: 1px solid #ddd;
          background: #fff;
          box-sizing: border-box;
          margin-bottom: 1.5rem;
        }
      }
    }

    // Connection details--------------------------------------------------------------------------------- //
    .connection-main {
      .connection-details {
        margin-top: 4.625rem;
        font-family: Archivo;
        font-size: 24px;
        font-weight: 600;
        line-height: 34px; /* 141.667% */
      }
      .connection-input {
        margin-top: 2.5rem;
      }
      .agree-terms {
        margin-bottom: 5rem;
        label {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
        }
        input {
          margin-right: 0.5rem;
        }
      }
    }

    // Payment Input--------------------------------------------------------------------------------- //
    .payment-input {
      margin-top: 5rem;

      .form-group {
        position: relative;
        margin-bottom: 66px !important;

        label {
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
        }

        .cardiv {
          width: 100%;
          padding: 10px;
          border-radius: 8px;
          border: 1px solid #ddd;
          background: #fff;
        }
        input {
          width: 100%;
          padding: 8px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          border-radius: 8px;
          border: 1px solid #ddd;
          background: #fff;
          box-sizing: border-box;
          margin-bottom: 1.5rem;
        }
      }
      .CES-input {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 46px;
      }
    }
    .loader-container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999; /* Adjust the z-index as needed */
      background-color: rgba(
        255,
        255,
        255,
        0.7
      ); /* Optional overlay background */
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      overflow: hidden; /* Prevent scroll when loader is displayed */
    }
    // Billing Address--------------------------------------------------------------------------------- //
    .billing-main {
      .billing-address {
        // margin-top: 4.625rem;
        font-family: Archivo;
        font-size: 24px;
        font-weight: 600;
        line-height: 34px; /* 141.667% */
      }
      .billing-input {
        select {
          width: 100%;
          padding: 8px;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          border-radius: 8px;
          border: 1px solid #ddd;
          background: #fff;
          box-sizing: border-box;
          margin-bottom: 1.5rem;

          option {
            padding: 8px;

            font-size: 14px;
            font-weight: 400;
            line-height: 24px; /* 171.429% */
            border-radius: 8px;
            border: 1px solid #ddd;
            background: #fff;
            box-sizing: border-box;
          }
        }
      }
      .credit-agree-terms {
        margin-bottom: 5rem;
        label {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          input {
            margin-right: 0.5rem;
          }
        }
      }
    }

    .btn-finalize {
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 111.111% */
      height: 72px;
      padding: 0px 52.5px 0px 51.5px;
      border-radius: 8px;
      background: #1f2d5c;
      color: #fff;
    }
  }

  .eye-btn {
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
}

// Media Queries Section--------------------------------------------------------------------------------- //
@media (max-width: 1850px) {
  .top-pricing-content {
    .inner-content {
      padding: 96px 170px 96px 170px;
    }
  }
}
@media (max-width: 1780px) {
  .package-sec {
    padding: 76px 120px 130px 120px;
    .package-cards-sec {
      padding: 70px 25px 100px 25px;
      gap: 20px;
    }
    .registration-form {
      padding: 80px 100px 95px 100px;
    }
  }
}
@media (max-width: 1640px) {
  .top-pricing-content {
    .inner-content {
      padding: 96px 100px 96px 100px;

      p {
        font-size: 20px;
      }
      ol {
        padding: 0 150px;
        gap: 10px;
        li {
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
}
@media (max-width: 1500px) {
  .package-sec {
    padding: 0px 100px 100px 100px;

    .package-cards-sec {
      padding: 70px 0px 100px 0px;
      gap: 30px;
      .package-card {
        width: 100%;
      }
    }

    .registration-form {
      padding: 70px 100px 70px 100px;
    }
  }
}

@media (max-width: 1300px) {
  .package-sec {
    padding: 70px 50px 100px 50px;

    .package-cards-sec {
      padding: 70px 0px 100px 0px;
      .package-card {
        width: 100%;
        padding: 40px 25px 40px 25px;
      }
    }
  }

  .registration-form {
    padding: 70px 40px 70px 40px;
  }
  .top-pricing-content {
    .inner-content {
      p {
        font-size: 18px !important;
      }
      ol {
        padding: 0 30px;
        li {
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .package-sec {
    padding: 20px;
    margin-top: 0px !important;
    .h-pricing {
      padding-top: 20px;

      .header {
        h3 {
          font-size: 22px;
          line-height: 10px;
        }
      }

      .price-card-wrapper {
        .price-card {
          padding: 40px 20px;
        }
      }
    }
    .select-plan {
      margin-top: 40px;
      margin-bottom: 30px;
      font-size: 14px;
    }
    .regist-heading {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .package-selection {
      .step-circle {
        width: 30px;
        height: 30px;
        font-size: 12px;
      }
      .step-pack-select {
        font-size: 14px;
      }
    }
    .package-cards-sec {
      grid-template-columns: 1fr;
      justify-content: center;
      gap: 20px;
      padding: 10px;
      .package-card {
        width: 100%;
        padding: 40px 25px 40px 25px;

        button {
          border: none;
        }
      }
    }

    .registration-form {
      width: 100%;
      margin-top: 20px;
      padding: 20px;

      .personal-input {
        margin-top: 2rem;
      }
      .input-fields {
        grid-template-columns: 1fr;
        gap: 0px;

        .form-group {
          label {
            font-size: 12px;
          }
        }
      }

      .payment-input {
        margin-top: 2rem;
        .form-group {
          label {
            font-size: 12px;
          }
          input {
            margin-bottom: 2rem;
            font-size: 12px;
          }
        }
        .CES-input {
          grid-template-columns: 1fr;
          gap: 0px;
        }
      }
      .connection-main {
        .connection-details {
          margin-top: 20px;
          font-size: 18px;
        }
        hr {
          margin-top: 0.2rem;
        }
        .connection-input {
          margin-top: 1rem;
        }
        .agree-terms {
          margin-bottom: 2rem;

          label {
            font-size: 10px;
          }
        }
      }
      .billing-main {
        .billing-address {
          margin-top: 20px;
          font-size: 18px;
        }
        hr {
          margin-top: 0.2rem;
        }

        .credit-agree-terms {
          margin-bottom: 40px;
          label {
            font-size: 10px;
          }
        }

        .billing-input {
          select {
            font-size: 12px;
            option {
              font-size: 12px;
            }
          }
        }
      }

      .finalizeBtn {
        .btn-finalize {
          text-align: center;
          width: 100%;
          height: 70px;
          font-size: 14px;
          padding-inline: 15px;
        }
      }
    }
  }
  .top-pricing-content {
    .inner-content {
      padding: 56px 20px 46px 20px;
      h3 {
        font-size: 32px !important;
      }
      p {
        font-size: 16px !important;
      }
      ol {
        padding: 0 0px;
        gap: 10px;
        li {
          font-size: 14px;
          span {
            font-size: 15px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
