.simpliee-dropdown {
  max-width: 200px !important;
  // width: 129.017px;
  width: 100%;
  height: 43.97px;
  border-radius: 8px;
  border: 1px solid #9ba4f1;
  background: #f8fbff;

  .dropdown-toggle {
    padding: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    color: #1f2d5c;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding: 0 10px;

    // padding: 0 16px;
    &::after {
      border: 0;
    }

    &:focus-visible {
      box-shadow: none;
    }

    &:active {
      box-shadow: none;
      background: transparent;
    }

    img {
      width: 14px;
      height: 14px;
    }
  }

  .dropdown-menu {
    width: 100%;
    border: 1px solid #9ba4f1;
    --bs-dropdown-min-width: 0rem;
    top: 10px !important;

    position: relative;
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: #32c3c0;
    }
    &::-webkit-scrollbar-track:hover {
      background-color: #b8c0c2;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #1f2f5e;
    }
    &::-webkit-scrollbar-track:active {
      background-color: #f8fbff;
    }
    li {
      a {
        font-family: "Inter";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        &:hover {
          background: #f8fbff;
        }
      }
    }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 32px;
    }
  }
  .drop-search-box {
    padding: 0 10px;
    position: sticky;
    top: 0px;
    z-index: 22;
    max-width: 450px !important;

    input {
      width: 100%;
      border-radius: 6px;
      border: 1px solid #9ba4f1;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      padding-left: 8px;
      font-size: 13px;
      height: 24px;
    }
    &::before {
      content: "";
      position: absolute;
      top: -10px;
      left: 0;
      right: 0%;
      height: 37px;
      z-index: -1;
      background-color: #fff;
    }
  }
}
.dropdown-wrapper {
  width: 180px;
}
.no-after {
  &::after {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .dropdown-wrapper {
    width: 100%;
  }
  .simpliee-dropdown {
    max-width: 100% !important;
  }
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: center;

  .search-input {
    flex: 1;
    margin-right: 10px;
  }

  button {
    padding: 10px 10px;
    border: 1px solid;
    background-color: #1f2f5e;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 135px;
    height: 37px;

  }
}
