.profile-comp {
  margin: 0px 140px 54px 140px;
  display: flex;
  flex-direction: column;
  row-gap: 60px;

  .top-sec {
    padding: 20px 27px 23px 35px;
    border-radius: 14px;
    border: 1px solid #eceef6;
    background: #1f2d5c;
    box-shadow: 0px 3.32px 18.31px 0px rgba(0, 0, 0, 0.09);
    display: flex;
    justify-content: space-between;
    color: #fff;

    .plan,
    .payment,
    .renewal,
    .cancel {
      display: flex;
      flex-direction: column;
      row-gap: 22px;
    }

    .payment,
    .renewal,
    .cancel {
      span {
        font-size: 14px;
      }
    }

    .cancel {
      justify-content: center;

      button {
        border-radius: 96px;
        border: 2px solid #fff;
        background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
        padding: 15px 43px;
        color: var(--neutral-100, #fff);
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        font-family: "DM Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }

    p {
      font-family: "Inter";
      font-size: 20.259px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0.203px;
      margin: 0px;

      span {
        color: #2d99a4;
        font-size: 25px;
      }
    }
  }

  .top-empty {
    padding: 20px 27px 23px 35px;
    border-radius: 14px;
    border: 1px solid #eceef6;
    background: #1f2d5c;
    box-shadow: 0px 3.32px 18.31px 0px rgba(0, 0, 0, 0.09);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

    p {
      font-family: "Inter";
      font-size: 20.259px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 0.203px;
      margin: 0px;

      span {
        font-size: 25px;
        color: #2d99a4;
      }
    }

    button {
      border-radius: 96px;
      border: 2px solid #fff;
      background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
      padding: 15px 43px;
      color: var(--neutral-100, #fff);
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: "DM Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
  }

  .profile-section {
    border-radius: 14px;
    border: 1px solid #eceef6;
    background: #fff;
    box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.09);

    padding: 45px 45px 90px 73px;
    display: flex;
    flex-direction: column;
    row-gap: 70px;

    .profile-top {
      display: flex;
      align-items: center;
      column-gap: 23px;

      img {
        width: 92px;
        height: 92px;
        object-fit: cover;
        border-radius: 92px;
        border: 1.895px solid var(--primary-blue-100, #d8d8fe);
      }

      svg {
        width: 92px;
        height: 92px;
        object-fit: cover;
        border-radius: 92px;
        border: 1.895px solid var(--primary-blue-100, #d8d8fe);
      }

      .about {
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        h6 {
          color: #000;
          font-family: "Inter";
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0px;
        }

        p {
          color: #858585;
          font-family: "Inter";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0px;
        }
      }
    }

    .deatils {
      display: flex;
      justify-content: space-between;
      padding-right: 270px;
      column-gap: 150px;
      position: relative;

      .edit-profile {
        color: var(--neutral-100, #fff);
        text-align: center;
        font-feature-settings: "clig" off, "liga" off;
        border: none;
        font-family: "DM Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        padding: 15px 60px;
        position: absolute;
        right: 20px;
        bottom: -60px;
        border-radius: 96px;
        background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
      }

      .content {
        display: flex;
        flex-direction: column;
        row-gap: 23px;

        div {
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          padding-bottom: 17px;
          border-bottom: 1px solid #e8e8e8;
        }
      }

      h4 {
        color: #1f2d5c;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0px;
      }

      input,
      textarea {
        color: #858585;
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0px;

        &:disabled {
          background: transparent;
        }
      }

      .profile-left,
      .profile-right {
        width: 50%;
        display: flex;
        flex-direction: column;
        row-gap: 37px;

        input,
        textarea {
          width: 100%;
          border: none;
          outline: none;
        }

        textarea {
          min-height: 50px;
        }
      }

      h2 {
        color: #000;
        font-family: "Inter";
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0px;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .profile-comp {
    margin: 0px 100px 54px 100px;
    row-gap: 30px;

    .profile-section {
      border-radius: 14px;
      border: 1px solid #eceef6;
      background: #fff;
      box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.09);
      padding: 30px 40px 40px 50px;
      row-gap: 50px;

      .profile-top {
        display: flex;
        align-items: center;
        column-gap: 23px;

        img {
          width: 92px;
          height: 92px;
          border-radius: 92px;
          border: 1.895px solid var(--primary-blue-100, #d8d8fe);
        }

        .about {
          display: flex;
          flex-direction: column;
          row-gap: 2px;

          h6 {
            color: #000;
            font-family: "Inter";
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0px;
          }

          p {
            color: #858585;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0px;
          }
        }
      }

      .deatils {
        display: flex;
        justify-content: space-between;
        padding-right: 200px;
        column-gap: 100px;
        position: relative;

        .edit-profile {
          font-size: 16px;
          line-height: 18px;
          padding: 15px 30px;
          position: absolute;
          right: 0px;
          bottom: 0px;
          border-radius: 96px;
        }

        .content {
          display: flex;
          flex-direction: column;
          row-gap: 23px;

          div {
            row-gap: 5px;
            padding-bottom: 10px;
          }
        }

        h4 {
          font-size: 18px;
        }

        p {
          font-size: 16px;
        }

        .profile-left,
        .profile-right {
          width: 50%;
          row-gap: 20px;

          input,
          textarea {
            width: 100%;
            border: none;
            outline: none;
          }

          textarea {
            min-height: 50px;
          }
        }

        h2 {
          font-size: 24px;
        }
      }
    }

    .top-sec {
      padding: 20px 30px;

      .plan,
      .payment,
      .renewal,
      .cancel {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }

      .payment,
      .renewal,
      .cancel {
        span {
          font-size: 14px;
        }
      }

      .cancel {
        button {
          padding: 14px 30px;
          font-size: 17px;
        }
      }

      p {
        font-size: 19px;
        line-height: 120%;
      }
    }

    .top-empty {
      p {
        font-size: 19px;
        line-height: 120%;
      }

      button {
        button {
          padding: 14px 30px;
          font-size: 17px;
        }
      }
    }
  }
}

@media screen and (max-width: 1310px) {
  .profile-comp {
    .profile-section {
      border-radius: 14px;
      border: 1px solid #eceef6;
      background: #fff;
      box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.09);
      padding: 30px 40px 40px 50px;
      row-gap: 20px;

      .profile-top {
        column-gap: 10px;

        img {
          width: 80px;
          height: 80px;
          border-radius: 92px;
        }

        .about {
          display: flex;
          flex-direction: column;
          row-gap: 2px;

          h6 {
            font-size: 20px;
          }

          p {
            font-size: 16px;
          }
        }
      }

      .deatils {
        padding-right: 20px;
        column-gap: 30px;

        .edit-profile {
          font-size: 16px;
          line-height: 16px;
          padding: 12px 20px;
          right: 10px;
          border-radius: 96px;
        }

        .content {
          display: flex;
          flex-direction: column;
          row-gap: 10px;

          div {
            row-gap: 5px;
            padding-bottom: 5px;
          }
        }

        h4 {
          font-size: 16px;
        }

        input,
        textarea {
          font-size: 15px;
        }

        .profile-left,
        .profile-right {
          width: 50%;
          row-gap: 10px;

          textarea {
            min-height: 50px;
          }
        }

        h2 {
          font-size: 20px;
        }
      }
    }

    .top-sec {
      padding: 20px 30px;

      .plan,
      .payment,
      .renewal,
      .cancel {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
      }

      .payment,
      .renewal,
      .cancel {
        span {
          font-size: 14px;
        }
      }

      .cancel {
        button {
          padding: 10px 20px;
          font-size: 16px;
          line-height: 100%;
        }
      }
    }

    .top-empty {
      button {
        padding: 10px 20px;
        font-size: 16px;
        line-height: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .profile-comp {
    margin: 0px;
    row-gap: 20px;

    .profile-section {
      border-radius: 14px;
      border: 1px solid #eceef6;
      background: #fff;
      box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.09);
      margin: 0px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .profile-top {
        column-gap: 10px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 60px;
        }

        .about {
          display: flex;
          flex-direction: column;
          row-gap: 4px;

          h6 {
            font-size: 18px;
            line-height: 100%;
          }

          p {
            font-size: 12px;
            line-height: 100%;
          }
        }
      }

      .deatils {
        display: flex;
        flex-direction: column;
        padding-right: 0px;
        padding-bottom: 60px;
        row-gap: 20px;
        position: relative;

        .edit-profile {
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 15px;
          padding: 12px 20px;
          position: absolute;
          right: 0px;
          bottom: 0px;
        }

        .content {
          display: flex;
          flex-direction: column;
          row-gap: 10px;

          div {
            row-gap: 5px;
            padding-bottom: 5px;
          }
        }

        h4 {
          font-size: 17px;
          line-height: 120%;
        }

        input,
        textarea {
          font-size: 15px;
          line-height: 120%;
        }

        .profile-left,
        .profile-right {
          width: 100%;

          row-gap: 10px;

          textarea {
            min-height: 50px;
          }
        }

        h2 {
          font-size: 22px;
          line-height: 100%;
        }
      }
    }

    .top-sec {
      padding: 20px;
      flex-direction: column;
      gap: 10px;

      .plan,
      .payment,
      .renewal,
      .cancel {
        row-gap: 10px;
      }

      .renewal {
        display: none;
      }

      .payment,
      .renewal,
      .cancel {
        margin-top: 0px;

        span {
          font-size: 14px;
          line-height: 100%;
        }
      }

      .cancel {
        justify-content: end;

        button {
          padding: 10px 20px;
          width: fit-content;
          font-size: 16px;
          line-height: 100%;
        }
      }

      p {
        font-size: 16px;

        span {
          font-size: 24px;
        }
      }
    }

    .top-empty {
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      p {
        font-family: "Inter";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: 0.203px;
        margin: 0px;

        span {
          font-size: 24px;
        }
      }

      button {
        padding: 10px 20px;
        width: fit-content;
        font-size: 16px;
        line-height: 100%;
      }
    }
  }
}
