.admin-dashboard {
  display: flex;
  min-height: 100vh;

  .filter {
    position: absolute;
    top: 20px;
    right: 20px;
    display: none;
  }

  .sidebar {
    position: fixed;
    width: 240px;
    height: 100vh;
    padding: 21px 0px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(90deg, #332a97 0%, rgb(31, 45, 92) 100%);
    z-index: 99;

    .top {
      .dashboard-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 90px;
        cursor: pointer;

        img {
          max-width: 172px;
          max-height: 40px;
          width: 100%;
          height: 100%;
        }
      }

      .center {
        display: flex;
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;

        a {
          width: 100%;
          padding: 10px 45px 8px 45px;
          color: #fff;
          font-family: "Inter";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;
        }

        .active {
          color: #000;
          border-radius: 6px;
          background: #fff;
        }
      }
    }

    .bottom {
      display: flex;
      margin-left: 15px;
      flex-direction: column;
      align-items: flex-start;

      button {
        background: transparent;
        border: none;
        outline: none;
        color: var(--primary-blue-50, #f7f7fb);
        display: flex;
        align-items: center;
        font-family: "General Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;

        svg {
          margin-right: 20px;
        }
      }
    }
  }

  .content-side {
    width: 100%;
    min-height: 100%;
    background-size: cover;

    .top {
      display: flex;
      justify-content: space-between;
      padding: 20px 20px;
      column-gap: 8px;
      background: linear-gradient(270deg, #292354 0%, #000 100%);
      position: sticky;
      top: 0;
      z-index: 5;
      align-items: center;

      .profile-section {
        display: flex;
        align-items: center;

        .profile-img {
          border-radius: 50%;
          border: 2px solid var(--primary-blue-100, #d8d8fe);
          width: 40px;
          height: 40px;
        }

        a {
          text-decoration: none;
          color: white;
        }
      }

      .ico {
        cursor: pointer;
      }
    }

    .search-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #1a181f;
      padding: 10px 20px;
      position: sticky;
      top: 75px; // adjust this value based on the height of your topbar
      z-index: 4;
      
      input[type="text"] {
        width: 50%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 20px;

        &::placeholder {
          color: #999; // adjust this color as needed
        }
    
      }

      button {
        padding: 10px 20px;
        background: #5549ae;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 1px;
        font-size: 16px;
        width: 125px;
        font-weight: 500;

        &:hover {
          background: #000;
        }
        .filter-icon {
          margin-right: 8px;
          width: 16px;
          height: 16px;
          
        }  
      }
    }

    .components-sec {
      margin-left: 0px;
      margin-top: 0px; // adjust this value based on the combined height of your topbar and search-filters
    }
  }

  .mobile-sidebar {
    .sidebar {
      position: relative;
      width: 100%;
      height: auto;
      padding: 0;

      .top {
        margin-bottom: 20px;

        .dashboard-logo {
          margin-bottom: 60px;
        }

        .center a {
          padding: 10px 20px;
        }
      }

      .bottom {
        margin-left: 0;
        align-items: stretch;

        button {
          justify-content: flex-start;
          padding: 10px 20px;
        }
      }
    }
  }
}

.mobile-sidebar {
  position: fixed;
  width: 400px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(360deg, #292354 0%, #000 100%);

  .top {
    .dashboard-logo {
      display: flex;
      justify-content: center;
      margin-bottom: 90px;
      cursor: pointer;

      img {
        max-width: 172px;
        max-height: 40px;
        width: 100%;
        height: 100%;
        margin-top: 10px;
      }
    }

    .center {
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      padding-right: 5px;

      a {
        width: 100%;
        padding: 10px 45px 8px 15px;
        color: #fff;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }

      .active {
        color: #000;
        border-radius: 6px;
        background: #fff;
      }
    }
  }

  .bottom {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
    align-items: flex-start;

    button {
      background: transparent;
      border: none;
      outline: none;
      color: var(--primary-blue-50, #f7f7fb);
      display: flex;
      align-items: center;
      font-family: "General Sans";
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;

      svg {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .admin-dashboard {
    .sidebar {
      width: 201px;
      display: none;

      .top {
        .dashboard-logo {
          margin-bottom: 60px;

          img {
            max-width: 150px;
          }
        }
      }
    }

    .content-side {
      .top {
        justify-content: end;
      }

      .components-sec {
        margin-left: 0px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .admin-dashboard {
    background-size: none;

    .filter {
      display: block;
    }

    .sidebar {
      display: none;
    }

    .content-side {
      width: 100%;
      min-height: 100%;

      .top {
        justify-content: space-between;
        padding: 20px;

        .ico {
          margin: 0 !important;
        }
      }

      .components-sec {
        margin: 0;
      }
    }
  }
}
