.simplee-footer {
  background: rgb(0, 0, 0);
  padding: 60px 150px;
  overflow: hidden;
  .top {
    padding-bottom: 30px;
    .simplee-footer-content {
      display: flex;

      justify-content: space-between;
      .center-content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        ul {
          gap: 15px;
          li {
            a {
              color: var(--white);
              font-family: "Poppins";
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 36px;
              &:hover {
                color: #32c4c0;
              }
            }
          }
        }
      }
      .left-content {
        // max-width: 490px;
        max-width: 600px;

        p {
          max-width: 100% !important;
        }
        .footer-logo {
          max-width: 200px;
        }
      }

      .right-content {
        ul {
          gap: 15px;
        }
        li {
          width: 45px;
          height: 45px;
          background: #ebe9e9;
          border-radius: 11.418px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0px 0px 0px;
    border-top: 1px solid white;
    p {
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      color: white;
      a {
        text-decoration: none;
        color: #32c4c0;
      }
    }
  }
}
@media (max-width: 600px) {
  .simplee-footer {
    padding: 20px !important;

    .top {
      padding-bottom: 20px;
      .simplee-footer-content {
        flex-direction: column;
        row-gap: 10px;

        .right-content {
          p {
            font-size: 20px;
            text-align: center;
          }
          ul {
            gap: 10px !important;
            margin-top: 10px !important;
            display: flex;
            justify-content: center;
          }
          li {
            width: 35px !important;
            height: 35px !important;
            border-radius: 8.418px;
            svg {
              width: 20px !important;
              height: 20px !important;
            }
          }
        }
        .left-content {
          display: flex;
          justify-content: center;
          .footer-logo {
            max-width: 150px !important;
          }
        }
        .center-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0px;
          ul {
            gap: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            li {
              height: 30px;
              a {
                font-size: 16px !important;
              }
            }
          }
        }
      }
    }
    .bottom {
      padding: 20px 0px 0px 0px;
      border-top: 1px solid white;
      p {
        font-size: 16px;
        line-height: 150%;
        text-align: center;
      }
    }
  }
}
