.price-card {
  padding: 40px 20px 40px 40px;
  border-radius: 24px;
  border: 1px solid var(--neutral-300, #eff0f6);
  background: var(--neutral-100, #fff);
  width: 394px;
  box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  .card-header {
    p {
      color: #6f6c90;
      /* Text Single/200/Medium */
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 111.111% */
    }
    .type {
      display: flex;
      align-items: center;
      gap: 18px;

      label {
        color: #170f49;
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 35px; /* 145.833% */
      }
    }
  }

  .card-body {
    h6 {
      color: #170f49;
      /* Headings/Typography Size 1 */
      font-family: "DM Sans";
      font-size: 54px;
      font-style: normal;
      font-weight: 700;
      line-height: 66px; /* 122.222% */

      span {
        color: var(--neutral-600, #6f6c90);
        /* Text Single/300/Medium */
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 110% */
      }
    }
    label {
      color: #170f49;
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 111.111% */
      margin-bottom: 16px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 17px;
      li {
        display: flex;
        align-items: flex-start;
        img {
          margin-right: 14px;
        }
        span {
          width: 100%;
          color: #170f49;
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 111.111% */
        }
      }
    }
  }
  .card-footer {
    margin-top: 40px;
  }

  &.active-card {
    background: linear-gradient(180deg, #dc2424 0%, #4a569d 99.09%);
    position: relative;
    border: 1px solid var(--neutral-300, #eff0f6);
    background: linear-gradient(180deg, #32c4c0 0%, #1f2d5c 99.09%);
    box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
    bottom: 30px;
    .card-header {
      p {
        color: white !important;
      }
      .type {
        label {
          color: white !important;
        }
      }
    }

    .card-body {
      h6 {
        color: white !important;
        span {
          color: white;
        }
      }

      label {
        color: white;
      }
      ul {
        li {
          span {
            color: white;
          }
        }
      }
    }
    .card-footer {
      button {
        background: white !important;
        color: black !important;
        filter: unset;
      }
    }
  }
}

@media (max-width: 1400px) {
  .price-card {
    &.active-card {
      bottom: 0px !important;
    }
  }
}
