
@media (max-width: 2500px) {
 .outer-card {
    z-index: 1;
    width: 100%;
    max-width: 87.5rem; /* 1400px */
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1280px;
  }

  .inner-card-container {
    display: flex;
    justify-content: space-between; /* Space between items */
    align-items: center; /* Center items vertically */
    gap: 3rem;
    margin-bottom: 6rem;
  }

  .inner-card-container2 {
    display: flex;
    justify-content: space-between; /* Space between items */
    align-items: center; /* Center items vertically */
    gap: 3rem;
    margin-bottom: 6rem;
  }

  .right-card,
  .left-card {
    max-width: 50%; /* Ensure they don't exceed 50% of the container width */
    flex: 1;
  }

  .right-card img {
    width: 100%; /* Ensure the image takes the full width of its container */
    height: auto; /* Maintain aspect ratio */
    background: none;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 2px rgb(31 45 92 / 25%);
    border-top: 1px solid rgba(74, 58, 255, .11);
    border-left: 1px solid rgba(74, 58, 255, .11);
  }

  .container2 {
    gap: 1rem;
    width: 100%;
    display: flex;
    position: relative;
    margin-bottom: 2rem;
    align-items: center;

    border-left: 0.375rem solid #4A3AFF;
    border-right: 0.375rem solid #4A3AFF;
  }

  .container2 h1 {
    font-size: calc(4vw + 2vh);
    font-weight: 600;
    width: 50%;
  }

  .container2 h3 {
    width: 50%;
  }



  .content-block {
    width: 100%;
    max-width: 35rem;
    position: relative;
  }

  .content-text {
    gap: 1.25rem;
    flex-direction: column;
    display: flex;
  }

  .content-rich-text {
    color: var(--grey-1);
  }
  .content-rich-text p{
    font-size: .85rem;
    letter-spacing: 0.2px;
  }

  .w-richtext:before,
  .w-richtext:after {
    content: " ";
    grid-area: 1 / 1 / 2 / 2;
    display: table;
  }
  .w-richtext:after {
    clear: both;
  }

  h3 {
    font-size: 1.75rem;
    line-height: 1.12;
  }
  .stat-row {
    grid-column-gap: 3rem;
    justify-content: space-between;
    width: 100%;
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .stat-block.is-feature {
    border-left: .375rem solid #4A3AFF;
    width: 100%;
    max-width: 17.5rem;
    padding-left: 1.25rem;
  }

  .stat-block {
    grid-row-gap: .75rem;
    flex-direction: column;
    display: flex;
  }

  .stat {
    color: rgb(198, 198, 198);
    flex: none;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.06;
    padding-left: 25px;
  }

  .stat-label {
    max-width: 17.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.2;
    color: rgb(81, 81, 81);
    letter-spacing: 1.1;
  }

  .testimonial {
    font-size: 1.4em; /* Adjust as needed */
    position: relative;
    color: rgb(114, 114, 114);
    margin-top: 4rem;
    padding: 20px;
  }

  .content-block-c {
    width: 100%;
    max-width: 30rem;
    position: relative;
  }

  .author {
      font-size: 0.9em; /* Slightly smaller for the author line */
      color: #555; /* Adjust color as needed */
      margin-top: 10px;
      display: block;
      text-align: right;
  }


  .benefits-sec {
    background: rgb(26, 24, 31);
  }

  .inner-card-container-b {
    display: flex;
    justify-content: space-between; /* Space between items */
    align-items: center; /* Center items vertically */
    gap: 2rem;
    margin-bottom: 5rem;
    margin-top: 30px;
  }

  .right-card-b,
  .left-card-b {
    max-width: 50%; /* Ensure they don't exceed 50% of the container width */
    flex: 1;
  }

  .content-block-b {
    width: 100%;
    max-width: 35rem;
    position: relative;
    
  }

  .content-text-b h2{
    gap: 1.25rem;
    flex-direction: column;
    display: flex;
    font-size: calc(4vw + 3vh);
    font-weight: 600;
    text-align: left;
    background: linear-gradient(to top, #4A3AFF, #317e7c, #32c4c0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.25;
    padding-left: 10px;
    letter-spacing: 1px;
  }

  .content-text-b h3{
  margin-top: 10px;
  padding: 0px;
  font-size: 18px;
  color: rgb(0, 0, 0);
  text-align: left;
  font-weight: 300;
  line-height: 1.5;
  }

  .content-text-b h4 {
    color: rgb(81, 81, 81);
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.2px;
  }

  .container3 {
    gap: 1rem;
    flex-direction: column;
    width: 100%;
    max-width: 62rem;
    display: flex;
    position: relative;
    margin-bottom: 3rem;
    margin-top: 5rem;
  }

  .bg-img {
    max-width: 968px;
    width: 100%;
    text-align: center;
    background-color: #317e7c;
  }

  .met-button {
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 20px;
  }

  button1 {
    border: 2px solid #000;
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    width: 150px;
    height: 40px;
  }

  .button-spacing3 {
    margin-right: 0px;
    margin-bottom: 10px;
    background: transparent;
    margin-top: 0px;
    transition: border 0.4s , color 0.4s ease;
    border-radius: 20px;
    text-align: center;
}      

  button1:hover {

    color: #4A3AFF;             // Optional: Change text color if needed
    border-color: #4A3AFF;
    transform: scale(.99);
    cursor: pointer
  }


}


@media (max-width: 1024px) {
  .inner-card-container {
    flex-direction: column;
    align-items: center;
  }

  .right-card,
  .left-card {
    max-width: 100%;
  }

  .right-card img {
    margin-bottom: 2rem; /* Add some space between cards */
  }
 
  .container2 {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .container2 h1, .container2 h3 {
    width: 100%;
    text-align: center;
  }

  .container2 h1 {
    font-size: calc(6vw + 2vh);
  }

  .container2 h3 {
    font-size: calc(3vw + 1vh);
  }

  .inner-card-container2{
    flex-direction: column-reverse;
  }

}


@media (max-width: 768px) {
  .outer-card {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  h3 {
    font-size: 1.5rem; /* Adjust font size */
  }
  .stat-row {
    justify-content: center; /* Center the items */
    grid-column-gap: 1rem; /* Adjust the gap between items */
    grid-row-gap: 1rem; /* Adjust the gap between rows */
    flex-wrap: wrap;
  }
  
  .stat-block.is-feature {
    max-width: none; /* Allow it to fill available space */
    flex: 1 1 calc(50% - 1rem); /* Take up half the width with some gap */
  }
  
  .stat-block {
    max-width: none; /* Allow it to fill available space */
  }

  .inner-card-container-b {
    flex-direction: column; /* Stack the items vertically */
    align-items: center; /* Align items to the start */
  }

  .left-card-b,
  .right-card-b {
    max-width: 100%; /* Make sure both sections take full width */
    padding: 5px;
  }

  .content-text-b h2 {
    text-align: center; /* Center-align the header text */
    font-size: 2.3rem; /* Adjust the font size if needed */
  }

  .content-text-b h4 {
    text-align: left; /* Align the text to the left */
    font-size: 1.2rem; /* Adjust the font size if needed */
  }
}


@media (max-width: 600px) {
  .outer-card {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  h3 {
    font-size: 1.25rem; /* Adjust font size */
  }


  .container2 {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .container2 h1, .container2 h3 {
    width: 100%;
    text-align: center;
  }

  .container2 h1 {
    font-size: calc(8vw + 2vh);
  }

  .container2 h3 {
    font-size: calc(4vw + 1vh);
  }

  .inner-card-container{
    margin-bottom: 3rem;
  }

  .inner-card-container2{
    margin-bottom: 3rem;
  }
}




@media (max-width: 480px) {
  .outer-card {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .container2 h1 {
    font-size: calc(6vw + 5vh);
    font-weight: 600;
  }

  .content-text h4 {
    font-size: 2rem;
  }


  h3 {
    font-size: 1rem; /* Adjust font size */
  }

  .container2 {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .container2 h1, .container2 h3 {
    width: 100%;
    text-align: center;
  }

  .container2 h1 {
    font-size: calc(10vw + 2vh);
  }

  .container2 h3 {
    font-size: calc(5vw + 1vh);
  }

}
