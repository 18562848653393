@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@400;500&family=Zen+Kaku+Gothic+Antique:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@400;500&family=Zen+Kaku+Gothic+Antique:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo&display=swap");

@font-face {
  font-family: General Sans;
  src: url("../assets/font//generalSans/GeneralSans-Regular.otf");
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 5.25rem;
  font-weight: 700;
  line-height: 1.03;
}

h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.06;
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.06;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.1875;
}

h6 {
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

p {
  margin-bottom: 0;

}
label {
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
label.small {
  font-size: 12.15px;
  font-weight: 400;
}

p {
  font-family: "poppins";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.56;
}
.body-large {
  font-size: 24px;
}
.body-medium {
  font-size: 18px;
}
.body-small {
  font-size: 16px;
}
.body-extra-small {
  font-size: 12px;
}

//---------------------- text colors
.text-pink {
  color: #ff3654;
}
.text-pinkish-red {
  color: var(--pinkish-red);
  color: var(--cyan) !important;
}
.text-pinkish-dark-red {
  color: var(--pinkish-dark);
  color: var(--cyan) !important;
}
.text-blue {
  color: var(--blue) !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.text-cyan {
  color: var(--cyan) !important;
}

.text-grey {
  color: #bdbdbd !important;
}

.text-gen-sans {
  font-family: General Sans !important;
}

@media (max-width: 600px) {
  .body-medium {
    font-size: 10px;
    line-height: 18px;
  }
}
