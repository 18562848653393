
.box-body{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 5rem;
}

.container5 {
    width: 100%;
    height: 600px;
    border-radius: 1em;  
    display: flex;
    gap: 0.75em;
}

.rowb{
    border-radius: 1em;
}

.rowb-1{
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 0.75em;
}
.rowb-2{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.75em;
}

.rowb-3{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.75em;
}

.colb{
    flex: 1;
    border-radius: 1em;
    display: flex;
    gap: 0.75em;
    max-height: 300px;
}

.colb.colb-2{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.col-box {
    flex: 1;
    border-radius: 1em;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    transition: transform .45s cubic-bezier(.6,.6,0,1);
}

.col-boxx {
    flex: 1;
    border-radius: 1em;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform .45s cubic-bezier(.6,.6,0,1);
    max-width: 475px;
}

.col-boxx h2{
    font-size: calc(5vw + 3vh);
    padding-left: 10px;
}
.col-box, .boxb {
    flex: 1;
    height: 100%;
    border-radius: 1em;
    position: relative;
    overflow: hidden; /* Hide overflowing content */
  }

.boxb{
    flex: 1;
    border-radius: 1em;
    overflow: hidden;
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid #ffffff;
    transition: 0.3 ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;  
}

.col-box{
    background: rgba(255, 255, 255, 0);
    border: 2px solid #ffffff10;
    transition: 0.3s ease-in-out;
}

.boxb{
    background: rgba(255, 255, 255, 0);
    border: 2px solid #ffffff10;
    transition: 0.3s ease-in-out;
}

.col-box:hover{
    transform: scale(1.05);
    border: 2px solid #ffffff50;
}

.boxb:hover{
    transform: scale(1.06);
    border: 2px solid #ffffff50;
}

.list-box{
    position: absolute;
    top: 0;
    width: 100%;
    height: 80%;
    overflow: hidden;
}

.free-list1 {
display: flex;
flex-wrap: wrap;
padding: 0;
list-style-type: none;
width: 100%;
margin-bottom: 4rem;
align-items: center;
justify-content: center;
margin: 0;
-webkit-mask-image: linear-gradient(180deg, #d9d9d9 23%, rgba(217, 217, 217, 0) 100%);
mask-image: linear-gradient(180deg, #d9d9d9 23%, rgba(217, 217, 217, 0) 100%);
transition: -webkit-mask-image 0.45s cubic-bezier(0.6, 0.6, 0, 1);
transition: mask-image 0.45s cubic-bezier(0.6, 0.6, 0, 1);
overflow: hidden;
position: relative;
color: white;
transition: transform .45s cubic-bezier(.6,.6,0,1);
}

.free-list1:hover{
    transform: translateY(-80px);
}

.list-box:hover .free-list1 {
    -webkit-mask-image: none;
    mask-image: none;
}

.boxb-box:hover {
    -webkit-mask-image: none;
    mask-image: none;
}

.col-text{
    border-radius: 5px 5px 16px 16px;
    border: 2px solid #ffffff50;
    background: #1a181feb; 
    margin: 0 4px 4px;
    padding-top: 16px;
    padding-bottom: 20px;
    text-align: center;
    height: 75px;
    width: 225px;
    position: absolute;
    bottom: 0;
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
}

.col-text1{
    border-radius: 5px 5px 16px 16px;
    border: 2px solid #ffffff50;
    background: #1a181feb; 
    margin: 0 4px 4px;
    padding-top: 16px;
    padding-bottom: 20px;
    text-align: center;
    height: 75px;
    width: 225px;
    position: absolute;
    bottom: 0;
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
}

.boxb-box{
    position: relative;
    overflow: hidden;
    mask-image: linear-gradient(180deg, #d9d9d9 20%, rgba(217, 217, 217, 0) 100%);
}



.boxb-cards{
    border-radius: 15px;
    border: 0.5px solid #3c3749;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    background-color: #29272f;
    margin: 10px;
}

.boxb-cards .header{
    background-color: #3c3749;
    color: #ffffff;
    padding: 5px;
    border-radius: 15px 15px 0 0;
    font-size: 12px;
    margin-bottom: 10px;
}

.boxb-box p{
    color: #a3a3a3;
    margin: 5px;
    font-size: 11px;
    line-height: 1.5;

}

.boxa-box{
    height: 70%;
    transition: transform .45s cubic-bezier(.6,.6,0,1);
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
}

.boxa-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rowb-box{
    height: 90%;
    transition: transform .45s cubic-bezier(.6,.6,0,1);
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
}

.rowb-box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sum-cards{
    border: 1.583px solid #3c3749;
    border-radius: 6.33px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
    padding: 13px 15px;
    width: 100%;
    color: white;
    word-spacing: 1px;
    letter-spacing: 0.1px;
    margin-left: 100px;

}

.sum-cards1{
    border: 1.583px solid #3c3749;
    border-radius: 6.33px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
    padding: 13px 15px;
    width: 100%;
    color: white;
    word-spacing: 1px;
    letter-spacing: 0.1px;
    margin-left: 25px;
    background: #5f5f5f42;

}

.sum-cards2{
    border: 1.583px solid #3c3749;
    border-radius: 6.33px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
    padding: 13px 15px;
    width: 100%;
    color: white;
    word-spacing: 1px;
    letter-spacing: 0.1px;
    margin-left: 75px;

}

.sum-header{
    font-style: normal;
    line-height: 140%;
    font-size: 11px;
    font-weight: 500;
    
}

.sum-header p{
    font-size: 10px;
    font-weight: 300;
    line-height: 140%;
    font-style: normal;
}

.rowb-box{
    flex-shrink: 0;
    margin: 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    ;
}

.issue-date {
    display: flex;
    justify-content: end;
    padding-bottom: 2px;
    font-size: 10px;
    margin-left: 10px;

}

.h-between{
    font-size: 10px;
    font-weight: 300;
}

@media(max-width:768px){
    .box-body{
        display: none;
    }

}
