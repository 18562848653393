.package-card-simple {
  display: flex;
  flex-direction: column;
  width: 394px;
  height: 486px;
  padding: 51px 43px 54px 44px;
  border-radius: 24px;
  color: var(--neutral-600, #6f6c90);
  font-family: DM Sans;
  border: 1px solid var(--neutral-300, #eff0f6);
  background: var(--neutral-100, #fff);
  box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);

  .card-header {
    display: flex;
    gap: 20px;
    .card-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
    }
    .card-type {
      color: #170f49;
      font-size: 24px;
      font-weight: 700;
      line-height: 35px;
    }
  }
  .card-body {
    color: #170f49;
    .pack-description {
      color: var(--neutral-600, #6f6c90);
      margin-top: 18px;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
    }
    .pack-price {
      text-align: center;
      padding-top: 29px;
      padding-bottom: 53;
      .price-text {
        color: var(--neutral-600, #6f6c90);
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        .price {
          color: #170f49;
          margin-right: 10px;
          font-size: 54px;
          font-weight: 700;
          line-height: 66px;
        }
      }
    }
  }
  .card-footer {
    .pack-card-btn {
      width: 100%;
      background-color: white;
      padding: 26px 38px;
      border-radius: 96px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
  }
}

.package-card {
  display: flex;
  flex-direction: column;
  width: 394px;
  height: 486px;
  padding: 51px 43px 54px 44px;
  border-radius: 24px;
  color: white;
  font-family: DM Sans;
  border: 1px solid var(--neutral-300, #eff0f6);
  background: linear-gradient(180deg, #32c4c0 0%, #4a569d 99.09%);
  box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);

  .card-header {
    display: flex;
    gap: 20px;
    .card-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 20px;
    }
    .card-type {
      font-size: 24px;
      font-weight: 700;
      line-height: 35px;
    }
  }
  .card-body {
    .pack-description {
      margin-top: 18px;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
    }
    .pack-price {
      text-align: center;
      padding-top: 29px;
      padding-bottom: 53;
      .price-text {
        font-size: 20px;
        font-weight: 500;
        line-height: 22px;
        .price {
          margin-right: 10px;
          font-size: 54px;
          font-weight: 700;
          line-height: 66px;
        }
      }
    }
  }
  .card-footer {
    .pack-card-btn {
      width: 100%;
      background-color: white;
      padding: 26px 38px;
      border-radius: 96px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
  }
}
