.v-center {
  display: flex;
  align-items: center;
}
.h-center {
  display: flex;
  justify-content: center;
}
.h-between {
  display: flex;
  justify-content: space-between;
}
.h-end {
  display: flex;
  justify-content: flex-end;
}
.br-35 {
  border-radius: 35px !important;
}
.pointer {
  cursor: pointer;
}

// =------------------------------Background Colors--------------
.bg-cyan {
  background-color: var(--cyan);
}

.fw-500 {
  font-weight: 500;
}
