.stats-section {
  margin-top: 200px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
}

.container1 {
  max-width: 1280px;
  margin: 0 auto;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stats-cards {
  display: flex;
  justify-content: center;
  gap: 35px;
}

.card-container {
  width: 275px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square-card,
.square-card1,
.square-card2 {
  width: 275px;
  min-height: 300px;
  background: none;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 2px rgba(31, 45, 92, 0.4);
  border-top: 1px solid #4a3aff1c;
  border-left: 1px solid #4a3aff1c;
  transition: box-shadow 0.3s;
}

.square-card1 {
  width: 300px;
  min-height: 350px;
}

.square-card h1,
.square-card1 h1,
.square-card2 h1 {
  font-size: 80px;
  margin-bottom: 30px;
  text-align: right;
  padding-right: 20px;
  padding-top: 20px;
  color: #32c4c0;
  font-weight: 350;
}

.square-card h5,
.square-card1 h5,
.square-card2 h5 {
  font-size: 23px;
  text-align: right;
  padding-right: 20px;
  color: #000000;
  padding-bottom: 10px;
  font-weight: 500;
}
.square-card1 h5 {
  padding-top: 0;
  padding-left: 5px;
}

.square-card1 h1 {
  padding-top: 30px;
  font-size: 85px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.08;
}

.text-purple1,
.text-purple {
  color: #4A3AFF;
  font-weight: 400;
  font-size: 75px;
}

.text-purple1 {
  font-size: 80px;
}

.stats-description {
  text-align: center;
  margin-top: -150px;
  margin-bottom: 65px;
}



.stats-description h1 {
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-size: calc(4vw + 2vh);
  font-weight: 600;
  margin-bottom: 20px;
  background: linear-gradient(to right, #32c4c0, #6667e6, #4A3AFF, #0A1988);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.05;
}

.stats-description h3 {
  font-family: 'Poppins', sans-serif;
  color: #000;
  font-size: 28px;
  margin-bottom: 0;
  line-height: 1.4;
}

.square-card:hover,
.square-card1:hover,
.square-card2:hover {
  box-shadow: 0px 8px 25px rgba(31, 45, 92, 0.6);
}

/* Media query for max-width 1024px */
@media (max-width: 1024px) {
  .card-container {
    flex: 1 1 calc(50% - 20px);
  }
  .container1 {
    max-width: 1024px;
  }
  .stats-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }
  .square-card,
  .square-card1,
  .square-card2 {
    width: 275px;
    min-height: 300px;
    box-shadow: 0px 0px 35px 3px rgba(31, 45, 92, 0.4);
  }

.square-card1 h1{
  font-size: 100px;
    margin-bottom: 10px;
    text-align: right;
    padding-right: 20px;
    padding-top: 20px;
    color: #32c4c0;
    font-weight: 350;
    line-height: 1;
}

.square-card1 h5 {
  font-size: 22px ;
}
}

/* Media query for max-width 768px */
@media (max-width: 768px) {

  .stats-description h1 {
    font-size: 75px;
  }
  .container1 {
    margin: 0 auto;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .square-card,
  .square-card1,
  .square-card2 {
    width: 400px;
    min-height: 380px;
    box-shadow: 0px 0px 35px rgba(31, 45, 92, 0.3);
    margin-bottom: 20px;
  }
  .square-card h1,
  .square-card1 h1,
  .square-card2 h1 {
    font-size: 100px;
    line-height: 1.1;
  }
  .square-card h5,
  .square-card1 h5,
  .square-card2 h5 {
    font-size: 30px;
  }
  .text-purple1 {
    font-size: 95px;
  }
}

/* Media query for max-width 600px */
@media (max-width: 600px) {
  .square-card,
  .square-card1,
  .square-card2 {
    width: 100%;
    min-height: 300px;
    box-shadow: 0px 0px 35px rgba(31, 45, 92, 0.3);
    margin-bottom: 20px;
  }
  .square-card h1,
  .square-card1 h1,
  .square-card2 h1 {
    font-size: 80px;
  }
  .square-card h5,
  .square-card1 h5,
  .square-card2 h5 {
    font-size: 25px;
  }
  .text-purple1 {
    font-size: 80px;
  }
  .stats-description h1 {
    font-size: calc(4vw + 2vh);
  }
}

/* Media query for max-width 480px */
@media (max-width: 480px) {
  .square-card,
  .square-card1,
  .square-card2 {
    width: 100%;
    min-height: 250px;
    box-shadow: 0px 0px 30px rgba(31, 45, 92, 0.3);
    margin-bottom: 20px;
  }
  .square-card h1,
  .square-card1 h1,
  .square-card2 h1 {
    font-size: 70px;
  }
  .square-card h5,
  .square-card1 h5,
  .square-card2 h5 {
    font-size: 20px;
    padding-bottom: 10px;
  }
  .text-purple1 {
    font-size: 80px;
  }
  .stats-description h1 {
    font-size: calc(5vw + 4vh);
  }

}

/* Media query for max-width 350px */
@media (max-width: 350px) {
  .square-card,
  .square-card1,
  .square-card2 {
    width: 100%;
    min-height: 200px;
    box-shadow: 0px 0px 25px rgba(31, 45, 92, 0.3);
    margin-bottom: 15px;
  }
  .square-card h1,
  .square-card1 h1,
  .square-card2 h1 {
    font-size: 60px;
  }
  .square-card h5,
  .square-card1 h5,
  .square-card2 h5 {
    font-size: 18px;
  }
  .text-purple1 {
    font-size: 60px;
  }
  .stats-description h1 {
    font-size: calc(7vw + 5vh);
  }
}
