.blogs-sec {
  // .blog-banner {
  //   background-image: url("../assets/image/home/bannerBack.png");
  //   background-size: cover;
  //   text-align: center;
  //   padding: 348px 381px 348px 381px;
  // }

  .blog-second-sec {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-content: center;
    gap: 115px;
    background-color: #fff;
    padding: 127px 166px 216px 167px;

    .right-side {
      // carpanter section --------------------------------------------------------------------------- //
      .carpanter-card {
        .carpanterImg {
          border-radius: 26.154px;
          width: 100%;
          min-height: 727px;
          object-fit: cover;
        }
      }
      .carpanterBtn {
        margin-top: 25px;
        margin-bottom: 39px;
        gap: 12px;
        color: black;
        display: flex;
        .carp-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 117px;
          height: 35.477px;
          border-radius: 35.477px;
          background-color: #dddbdb;

          p {
            font-size: 12.077px;
            font-style: normal;
            font-weight: 400;
            line-height: 51.329px; /* 425% */
          }
        }
      }

      .carp-blog-content {
        .blog-head-text {
          font-size: 48px;
          line-height: 71px;
          padding-bottom: 51px;
        }
        .blog-text {
          color: #2d2d2d;
          font-size: 22px;
        }
      }

      // writers section --------------------------------------------------------------------------- //
      .writers {
        display: flex;
        align-items: center;
        gap: 27px;
        margin-top: 54px;
        margin-bottom: 68px;
        .writer-img {
          display: flex;
          align-items: center;
          .writer1 {
            z-index: 1;
            width: 90px;
            height: 90px;
            border-radius: 10px;
          }
          .writer2 {
            margin-left: -35px;
            width: 90px;
            height: 90px;
            border-radius: 50px;
            // display: none;
          }
        }
        .writer-detail {
          color: #707070;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          span {
            color: #000;
            font-weight: 600;
          }
        }
      }
    }

    .left-side {
      // newsletter section --------------------------------------------------------------------------- //
      .blog-newsletter {
        width: 77%;
        padding: 27px 51px 27px 39px;
        border-radius: 22.388px;
        background: #1f2d5c;
        margin-bottom: 42px;

        .news-text {
          padding-bottom: 8px;
          font-size: 15px;
          line-height: normal;
        }
        .blog-news-head {
          padding-bottom: 21px;
          font-size: 39.265px;
          line-height: 44px;
        }

        .news-form {
          display: flex;
          flex-direction: column;
          align-items: center;
          .email-input {
            width: 70%;
            padding: 14px 0px 15px 21px;
            margin-bottom: 8px;
            border-radius: 31px;
            border: none;
          }
          .news-sub-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70%;
            padding: 14px 0px 15px 0px;
            border-radius: 31px;
            border: none;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      // ai-card section --------------------------------------------------------------------------- //
      .ai-card {
        margin-bottom: 41px;
        img {
          width: 77%;
        }

        .ai-card-btn {
          margin-top: 25px;
          gap: 12px;
          color: black;
          display: flex;
          .ai-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 156px;
            height: 35.477px;
            border-radius: 35.477px;
            background-color: #dddbdb;

            p {
              font-size: 12.077px;
              font-style: normal;
              font-weight: 400;
              line-height: 51.329px; /* 425% */
            }
          }
        }
        .ai-head {
          margin-top: 14px;
          max-width: 600px;
          h4 {
            font-size: 39.265px;
            font-weight: 600;
            line-height: normal;
          }
          p {
            color: #707070;
            font-family: "Poppins";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      // Info-card section --------------------------------------------------------------------------- //
      .info-card {
        margin-bottom: 41px;
        img {
          width: 77%;
        }

        .info-card-btn {
          margin-top: 25px;
          gap: 12px;
          color: black;
          display: flex;
          .info-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 156px;
            height: 35.477px;
            border-radius: 35.477px;
            background-color: #dddbdb;

            p {
              font-size: 12.077px;
              font-style: normal;
              font-weight: 400;
              line-height: 51.329px; /* 425% */
            }
          }
        }
        .info-head {
          margin-top: 14px;
          max-width: 550px;
          h4 {
            font-size: 39.265px;
            font-weight: 600;
            line-height: normal;
          }
          p {
            color: #707070;
            font-family: "Poppins";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }

  // featured section --------------------------------------------------------------------------- //
  .featured-sec {
    padding: 0px 166px 100px 167px;

    .featured-cards-sec {
      margin-top: 52px;
      padding: 86px 156px 112px 163px;
      background-image: url("../assets/image/home/featuredBack.png");
      background-size: cover;
      border-radius: 54px;
    }
  }

  // Latest blogs section --------------------------------------------------------------------------- //
  .latest-blogs-sec {
    padding: 62px 302px 39px 350px;

    .latest-blogs-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
    }

    .latest-blogs-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 95px;
      .load-more-btn {
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 68px; /* 425% */
        width: 204.63px;
        height: 68px;
        border-radius: 47px;
        border: none;
      }
    }
  }

  // Might Like section --------------------------------------------------------------------------- //
  .might-like-sec {
    padding: 122px 313px 100px 344px;
    .might-heading {
      padding-bottom: 46px;
      text-align: center;
      color: #000;
      font-size: 50px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .might-cards {
      display: grid;
      column-gap: 35.5px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .might-card {
        .might-card-img {
          img {
            width: 100%;
          }
        }
        .might-btns {
          margin-top: 13.68px;
          margin-bottom: 10px;
          display: flex;
          gap: 6.49px;
          .idea-text {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45.419px;
            height: 18.924px;
            border-radius: 19.183px;
            background-color: #dddbdb;
            p {
              color: #000;
              font-size: 6.53px;
              font-style: normal;
              font-weight: 400;
              line-height: 27.754px; /* 425% */
            }
          }
          .ai-text {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 84.349px;
            height: 18.924px;
            border-radius: 19.183px;
            background-color: #dddbdb;
            p {
              color: #000;
              font-size: 6.53px;
              font-style: normal;
              font-weight: 400;
              line-height: 27.754px; /* 425% */
            }
          }
        }

        .might-content {
          h3 {
            color: #000;
            font-size: 21.23px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 3px;
          }
          p {
            color: #707070;
            font-size: 8.11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
}

// Media Queries Section -------------------------------------------------------------------------------------- //

@media (max-width: 1800px) {
  .blogs-sec {
    .blog-banner {
      padding: 300px 250px 200px 250px;
    }

    // Second section ------------------------------------------------------------------------------------------ //
    .blog-second-sec {
      padding: 127px 100px 100px 100px;
      gap: 50px;

      // Left section ------------------------------------------------------------------------------------------ //
      .left-side {
        // AI card section ------------------------------------------------------------------------------------------ //
        .ai-card {
          .ai-head {
            h4 {
              font-size: 32px;
            }
            p {
              font-size: 14px;
            }
          }
        }

        // Info card section ------------------------------------------------------------------------------------------ //
        .info-card {
          .info-head {
            h4 {
              font-size: 32px;
            }
            p {
              font-size: 14px;
            }
          }
        }
      }

      // Right section ------------------------------------------------------------------------------------------ //
      .right-side {
        // carpanter section --------------------------------------------------------------------------- //
        .carpanter-card {
          .carpanterImg {
            min-height: fit-content;
          }
        }

        .carp-blog-content {
          .blog-head-text {
            font-size: 38px;
            line-height: 60px;
            padding-bottom: 30px;
          }
          .blog-text {
            font-size: 18px;
          }
        }
      }
    }

    // Featured section ------------------------------------------------------------------------------------------ //
    .featured-sec {
      padding: 0px 100px 100px 100px;

      .featured-cards-sec {
        padding: 40px 100px 80px 100px;
      }
    }

    // Latest blogs section ------------------------------------------------------------------------------------------ //
    .latest-blogs-sec {
      padding: 40px 200px 39px 200px;
    }

    // Might section ------------------------------------------------------------------------------------------ //
    .might-like-sec {
      padding: 80px 200px 100px 200px;
    }
  }
}

@media (max-width: 1500px) {
  .blogs-sec {
    // Banner section ------------------------------------------------------------------------------------------ //
    .blog-banner {
      padding: 250px 100px 200px 100px;

      .banner-content {
        h1 {
          font-size: 50px;
          line-height: normal;
          margin-bottom: 30px !important;
        }
      }
    }

    // Second section ------------------------------------------------------------------------------------------ //
    .blog-second-sec {
      padding: 127px 50px 100px 50px;
      gap: 30px;

      // Left section ------------------------------------------------------------------------------------------ //
      .left-side {
        .blog-newsletter {
          padding: 27px 30px 27px 30px;
          .blog-news-head {
            padding-bottom: 15px;
            font-size: 22px;
            line-height: 30px;
          }
        }
        // AI card section ------------------------------------------------------------------------------------------ //
        .ai-card {
          .ai-head {
            h4 {
              font-size: 20px;
            }
            p {
              font-size: 12px;
            }
          }
        }

        // Info card section ------------------------------------------------------------------------------------------ //
        .info-card {
          .info-head {
            h4 {
              font-size: 20px;
            }
            p {
              font-size: 12px;
            }
          }
        }
      }

      // Right section ------------------------------------------------------------------------------------------ //
      .right-side {
        // carpanter section --------------------------------------------------------------------------- //
        .carpanter-card {
          .carpanterImg {
            min-height: fit-content;
          }
        }

        .carp-blog-content {
          .blog-head-text {
            font-size: 32px;
            line-height: 45px;
            padding-bottom: 10px;
          }
          .blog-text {
            font-size: 16px;
            line-height: 23px;
          }
        }

        .writers {
          margin-top: 40px;
          margin-bottom: 20px;
        }
      }
    }

    // Featured section ------------------------------------------------------------------------------------------ //
    .featured-sec {
      padding: 0px 50px 100px 50px;

      h3 {
        font-size: 38px;
        line-height: 20px;
      }

      .featured-cards-sec {
        padding: 0px 20px 70px 20px;

        h3 {
          font-size: 38px;
          line-height: 50px;
        }
      }
    }

    // News card section ------------------------------------------------------------------------------------------ //
    .s-newsCard {
      p {
        font-size: 14px;
      }
      .card-body {
        .stack {
          font-size: 12px;
        }
        p {
          font-size: 22px;
        }
      }
    }

    // Latest blogs section ------------------------------------------------------------------------------------------ //
    .latest-blogs-sec {
      padding: 0px 50px 39px 50px;

      h3 {
        font-size: 38px;
        line-height: 20px;
      }
      .latest-blogs-btn {
        padding-top: 60px;
      }
    }

    // Might section ------------------------------------------------------------------------------------------ //
    .might-like-sec {
      padding: 50px 50px 100px 50px;

      .might-heading {
        font-size: 32px;
      }

      // Might cards section ------------------------------------------------------------------------------------------ //
      .might-cards {
        .might-card {
          .might-content {
            h3 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .blogs-sec {
    // Banner section ------------------------------------------------------------------------------------------ //
    .blog-banner {
      padding: 250px 50px 200px 50px;

      .banner-content {
        h1 {
          font-size: 50px;
          line-height: normal;
          margin-bottom: 30px !important;
        }
        p {
          font-size: 16px;
        }
      }
    }

    // Second section ------------------------------------------------------------------------------------------ //
    .blog-second-sec {
      grid-template-columns: repeat(1, 1fr);
      padding: 40px;

      // Left section ------------------------------------------------------------------------------------------ //
      .left-side {
        display: flex;
        flex-direction: column;
        align-items: center;
        .blog-newsletter {
          width: 70%;
          padding: 30px;
          margin-bottom: 60px;
          .blog-news-head {
            max-width: 700px;
            padding-bottom: 30px;
            font-size: 22px;
            line-height: 30px;
          }
          .news-form {
            .email-input {
              width: 70%;
            }
            .news-sub-btn {
              width: 70%;
            }
          }
        }
        // AI card section ------------------------------------------------------------------------------------------ //
        .ai-card {
          width: 70%;
          img {
            width: 100%;
          }
          .ai-head {
            h4 {
              font-size: 28px;
            }
            p {
              font-size: 14px;
            }
          }
        }

        // Info card section ------------------------------------------------------------------------------------------ //
        .info-card {
          width: 70%;
          img {
            width: 100%;
          }
          .info-head {
            h4 {
              font-size: 28px;
            }
            p {
              font-size: 14px;
            }
          }
        }
      }

      // Right section ------------------------------------------------------------------------------------------ //
      .right-side {
        // carpanter section --------------------------------------------------------------------------- //
        .carpanter-card {
          .carpanterImg {
            min-height: fit-content;
          }
        }

        .carp-blog-content {
          .blog-head-text {
            font-size: 38px;
            line-height: 50px;
            padding-bottom: 20px;
          }
          .blog-text {
            font-size: 18px;
            line-height: 27px;
          }
        }

        .writers {
          margin-top: 20px;
          margin-bottom: 10px;
          gap: 15px;

          .writer-img {
            gap: 10px;
            img {
              width: 60px !important;
            }
          }
          p {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }

    // Featured section ------------------------------------------------------------------------------------------ //
    .featured-sec {
      padding: 0px;
      h3 {
        font-size: 38px;
        line-height: 20px;
      }

      .featured-cards-sec {
        padding: 30px;
        background-image: none;
        background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
        border-radius: 0px;
        h3 {
          font-size: 38px;
          line-height: 50px;
        }

        .featured-cards {
          display: grid !important;
          grid-template-columns: repeat(2, 1fr) !important;
        }
      }
    }

    // News card section ------------------------------------------------------------------------------------------ //
    .s-newsCard {
      p {
        font-size: 14px;
      }
      .card-body {
        .stack {
          font-size: 12px;
        }
        p {
          font-size: 22px;
        }
      }
    }

    // Latest blogs section ------------------------------------------------------------------------------------------ //
    .latest-blogs-sec {
      padding: 50px 50px 39px 50px;

      h3 {
        font-size: 38px;
        line-height: 20px;
      }
      .latest-blogs-cards {
        grid-template-columns: repeat(2, 1fr);
      }
      .latest-blogs-btn {
        padding-top: 60px;
      }
    }

    // Might section ------------------------------------------------------------------------------------------ //
    .might-like-sec {
      padding: 50px 50px 100px 50px;

      .might-heading {
        font-size: 32px;
      }

      // Might cards section ------------------------------------------------------------------------------------------ //
      .might-cards {
        grid-template-columns: 1fr 1fr;
        .might-card {
          .might-content {
            h3 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .blogs-sec {
    // Banner section ------------------------------------------------------------------------------------------ //
    .blog-banner {
      padding: 200px 100px 100px 100px;

      .banner-content {
        h1 {
          font-size: 16px;
          line-height: normal;
          margin-bottom: 10px !important;
        }
        p {
          font-size: 8px;
          line-height: 15px;
        }
      }
    }

    // Second section ------------------------------------------------------------------------------------------ //
    .blog-second-sec {
      grid-template-columns: repeat(1, 1fr);
      padding: 20px;

      // Left section ------------------------------------------------------------------------------------------ //
      .left-side {
        display: flex;
        flex-direction: column;
        align-items: center;
        .blog-newsletter {
          width: 70%;
          padding: 20px;
          margin-bottom: 30px;

          .news-text {
            font-size: 12px;
          }
          .blog-news-head {
            padding-bottom: 10px;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
          }
          .news-form {
            font-size: 10px;
            .email-input {
              width: 70%;
              padding: 10px;
            }
            .news-sub-btn {
              width: 70%;
              font-size: 10px;
              padding: 10px;
            }
          }
        }
        // AI card section ------------------------------------------------------------------------------------------ //
        .ai-card {
          width: 70%;
          img {
            width: 100%;
          }
          .ai-head {
            h4 {
              font-size: 16px;
              line-height: 22px;
            }
            p {
              font-size: 10px;
            }
          }

          .ai-card-btn {
            .ai-sm {
              width: 60px !important;
            }
            .ai-btn {
              width: 120px;
              height: 25px;

              p {
                font-size: 9px;
                line-height: normal;
              }
            }
          }
        }

        // Info card section ------------------------------------------------------------------------------------------ //
        .info-card {
          width: 70%;
          img {
            width: 100%;
          }
          .info-head {
            h4 {
              font-size: 16px;
              line-height: 22px;
            }
            p {
              font-size: 10px;
            }
          }

          .info-card-btn {
            .info-btn {
              width: 80px;
              height: 25px;

              p {
                font-size: 9px;
                line-height: normal;
              }
            }
          }
        }
      }

      // Right section ------------------------------------------------------------------------------------------ //
      .right-side {
        // carpanter section --------------------------------------------------------------------------- //
        .carpanter-card {
          .carpanterImg {
            width: 80%;
            min-height: fit-content;
          }
        }

        .carp-blog-content {
          .blog-head-text {
            font-size: 14px;
            line-height: 20px;
            padding-bottom: 10px;
          }
          .blog-text {
            font-size: 11px;
            line-height: 15px;
          }
        }

        .carpanterBtn {
          .carp-btn {
            max-width: 70px;
            height: 25px;
            // border-radius: 10px;

            p {
              font-size: 9px;
              line-height: normal;
            }
          }
        }

        .writers {
          margin-top: 10px;
          margin-bottom: 10px;
          gap: 10px;

          .writer-img {
            gap: 10px;
            img {
              width: 50px !important;
            }
          }
          p {
            font-size: 10px;
            line-height: 15px;
          }
        }
      }
    }

    // Featured section ------------------------------------------------------------------------------------------ //
    .featured-sec {
      padding: 0px;
      h3 {
        font-size: 26px;
      }

      .featured-cards-sec {
        padding: 20px;
        background-image: none;
        background: linear-gradient(90deg, #1f2d5c 0%, #32c4c0 100%);
        border-radius: 0px;
        h3 {
          font-size: 22px;
          line-height: 30px;
        }

        .featured-cards {
          display: grid !important;
          place-items: center;
          grid-template-columns: repeat(1, 1fr) !important;
        }
      }
    }

    // News card section ------------------------------------------------------------------------------------------ //
    .s-newsCard {
      p {
        font-size: 11px;
      }
      .card-body {
        .stack {
          font-size: 12px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    // Latest blogs section ------------------------------------------------------------------------------------------ //
    .latest-blogs-sec {
      padding: 20px;

      h3 {
        font-size: 26px;
      }
      .latest-blogs-cards {
        grid-template-columns: repeat(1, 1fr);
        place-items: center;
      }
      .latest-blogs-btn {
        padding-top: 60px;
      }
    }

    // Might section ------------------------------------------------------------------------------------------ //
    .might-like-sec {
      padding: 50px 50px 100px 50px;

      .might-heading {
        font-size: 24px;
      }

      // Might cards section ------------------------------------------------------------------------------------------ //
      .might-cards {
        grid-template-columns: 1fr;
        gap: 30px;
        .might-card {
          .might-content {
            h3 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
