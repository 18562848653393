.simpli-navbar {
  position: fixed; // Keep navbar fixed at the top
  left: 50%; // Position halfway across the screen
  top: 0;
  transform: translateX(-50%); // Shift it back by half of its own width
  width: 100%;
  max-width: 1280px; // Maximum width of the navbar
  padding: 0 0px;
  height: 75px;
  z-index: 1050;
  background-color: rgba(255, 0, 0, 0.5); // Semi-transparent black background
  border-radius: 15px; // Rounded edges
  box-shadow: 0 2px 10px rgb(0, 0, 0); // Subtle shadow for depth
  overflow: hidden;
  margin-top: 15px;
  backdrop-filter: blur(10px);



  .navbar-brand {
    img {
      max-width: 200px;
    }
  }

  // Custom background styles for specific states if needed
  &.simpli-back {
    background: linear-gradient(270deg, #4A3AFF 0%, #32C4C0 100%);
    background-size: cover;
  }

  .navbar-nav {
    gap: 56px;

    .nav-item {
      padding: 0;

      a {
        padding: 0;
        color: var(--white);
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        position: relative;

        &::after {
          content: "";
          left: 0;
          bottom: -10px;
          position: absolute;
          width: 0;
          height: 2px;
          background-color: white;
          transition: all 0.3s linear;
        }

        &.active, &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
  }


      .calendly-badge-widget {
        padding: 0;
        color: var(--white);
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        position: relative;
        box-shadow: none !important;
        bottom: 0px;
        left: 0px;

        .calendly-badge-content {
          background: none !important;
          box-shadow: none !important;
          // bottom: 0%;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          padding: 0;
          font-weight: 600;
          height: 0%;
        }

        &::after {
          content: "";
          left: 0;
          bottom: -10px;
          position: absolute;
          width: 100%;
          height: 2px;

          background-color: white !important;
          width: 0;
          transition: all 0.3s linear;
        }

        &.active {
          &::after {
            width: 100%;
          }
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }

    button {
      width: 174px;
      height: 43px;
      border-radius: 21.5px;
      border: 2px solid #cde4dc;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      background: transparent;
      line-height: 36px;
      /* 225% */
      margin-left: 0px;
    }
  


.mobile-menu {
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  width: 100%;

  &.customMbNav {
    // background-color: rgb(119, 211, 142);
    background: linear-gradient(270deg, #292354 0%, #000 100%);
  }
}
.mb-menu-slider {
  background: linear-gradient(270deg, rgba(74,58,255,1) 0%, rgba(31,55,92,1) 100%);
  width: 100% !important;
  opacity: 0.95;
  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-left: 10px;
    li {
      a {
        padding: 0;
        color: var(--white);
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        width: fit-content;
        position: relative;

        &::after {
          content: "";
          left: 0;
          bottom: -3px;
          position: absolute;
          width: fit-content;
          height: 2px;
          background-color: white;
          width: 0;
          transition: all 0.3s linear;
        }

        &.active {
          &::after {
            width: 100%;
          }
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
    button {
      width: 174px;
      height: 43px;
      border-radius: 21.5px;
      border: 2px solid #cde4dc;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      background: transparent;
      margin-top: 20px;
    }
    form {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex-direction: column-reverse;
    }
    .calendly-badge-widget {
      position: relative !important;
      top: unset;
      right: unset;

      bottom: unset;
    }
  }
  .btn-close {
    filter: invert(1);
  }
  .calendly-badge-widget .calendly-badge-content {
    background: none !important;
    box-shadow: none !important;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    padding: 0;
    font-weight: 600;
    height: 0%;
  }
}
.mb-logo {
  max-width: 160px;
}

@media screen and (max-width: 1500px) {
  .simpli-navbar {
    padding: 0 30px;

    .navbar-nav {
      gap: 36px;
    }
  }
}



@media screen and (max-width: 1024px) {
  .navbar-toggler-icon {
    width: 1em;
    height: 1em;
  }

  .simpli-navbar {
    padding: 0 0px;

    .navbar-brand {
      margin-left: 20px;

      img {
        max-width: 135px !important;
      }
    }

    .navbar-toggler {
      margin-right: 20px;
      // width: 40px;

      &:focus {
        box-shadow: none;
      }
    }

    .container-fluid {
      padding: 0 !important;
    }

    .navbar-nav {
      gap: 56;

      nav-item {
        padding: 0;
  
        a {
          padding: 0;
          color: var(--white);
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          position: relative;
  
          &::after {
            content: "";
            left: 0;
            bottom: -10px;
            position: absolute;
            width: 0;
            height: 2px;
            background-color: white;
            transition: all 0.3s linear;
          }
  
          &.active, &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }

}

@media (max-width: 1040px) {
  nav {
    top: -19px !important;
  }
}

