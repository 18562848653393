.faq-sec {
  background: url("/assets/image/features/lines.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 130% auto; /* increase the width to 120% */
  padding: 100px 150px;

  h1 {
    color: #000;

    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-align: center;
    margin-bottom: 46px;
  }

  .faq {
    max-width: 1300px !important;
    width: 100%;
    margin: 0px auto;
    button {
      color: #282828;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 166.667% */
      height: 100%;
    }
    p {
      color: #5f6980;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 157.143% */
    }
    .accordion-button {
      transition: all 0.4s linear;
    }
    .accordion-button:focus {
      z-index: 3;
      border-color: none !important;
      outline: 0;
      box-shadow: none !important;
      transition: all 0.4s linear;
    }
    .accordion-button:not(.collapsed) {
      transition: all 0.4s linear;
      color: white;
      background: linear-gradient(270deg, #000000 0%, #1f2d5c 100%);
      box-shadow: none !important;
    }
    .accordion-button:not(.collapsed)::after {
      filter: brightness(0) invert(1);
    }
    .accordion-body {
      padding: 20px;
    }
  }
}

@media screen and (max-width: 1300px) {
  .faq-sec {
    h1 {
      font-size: 40px;
      margin-bottom: 30px;
    }

    .faq {
      max-width: 1300px !important;
      width: 100%;
      margin: 0px auto;
    }
  }
}
@media screen and (max-width: 600px) {
  .faq-sec {
    background-size: 100%;
    padding: 20px;
    h1 {
      font-size: 30px;
      margin-bottom: 20px;
    }

    .faq {
      button {
        font-size: 14px;
      }
      p {
        font-size: 15px;
      }

      .accordion-body {
        padding: 15px 20px;
      }
    }
  }
}
