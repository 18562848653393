.change-password {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  .content {
    display: flex;
    flex-direction: column;
    border-radius: 14.6px;
    border: 1px solid #eceef6;
    background: #fff;
    box-shadow: 0px 2.32px 20.31px 0px rgba(0, 0, 0, 0.09);
    max-width: 600px;
    width: 100%;
    margin: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    h4 {
      color: #000;
      font-family: Inter;
      font-size: 35px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
    }
    label {
      color: #1f2d5c;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    input {
      color: #858585;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border: none;
      outline: none;
    }
    .inner {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      div {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #e8e8e8;
      }
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 10px;
        border: none;
        .change {
          border-radius: 96px;
          background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
          padding: 10px 20px;
          border: none;

          color: var(--neutral-100, #fff);
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;

          /* Text Single/200/Bold */
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 111.111% */
        }
        .cancel {
          background: transparent;
          color: #000;
          text-align: center;
          font-feature-settings: "clig" off, "liga" off;
          padding: 10px 20px;
          /* Text Single/200/Bold */
          font-family: DM Sans;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 111.111% */
          border-radius: 96px;
          border: 3px solid var(--as, #32c4c0);
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .change-password {
    padding: 20px;
    .content {
      padding: 20px;
      row-gap: 20px;
      h4 {
        font-size: 25px;
        line-height: 100%;
      }
      label {
        font-size: 16px;
      }
      input {
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
      }
      .inner {
        row-gap: 15px;
        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: center;
          column-gap: 10px;
          border: none;
          .change {
            font-size: 16px;
            line-height: 100%;
          }
          .cancel {
            font-size: 16px;
            line-height: 100%;
          }
        }
      }
    }
  }
}
