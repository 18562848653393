.h-banner {
  height: 100vh;
  background-image: url("../assets/image/home/bannerBack.png");
  padding: 100px 100px 0 100px;
  background-size: cover;
  .left-content {
    width: 50%;
    h2 {
      max-width: 750px;
    }
    p {
      max-width: 552px;
    }
  }
  .right-content {
    width: 50%;

    img {
      max-width: 90%;
    }
  }
}
.h-categories {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 140px;

  .search-box {
    max-width: 1040px;
    height: 92px;
    border-radius: 54.5px;
    background: rgba(235, 244, 241, 0.8);
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(11.5px);
    display: flex;
    align-items: center;
    padding: 0 80px;
    margin: auto;
    margin-top: -46px;
    input {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      font-family: "Poppins";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 68px; /* 377.778% */
    }
  }
  .categories {
    margin-top: 150px;
    margin-bottom: 100px;
    .category-box {
      width: 322px;
      height: 185px;
      border-radius: 10px;
      background: linear-gradient(90deg, #1f2d5c 0.31%, #0a1988 100.16%);
      cursor: pointer;
      position: relative;
      overflow: hidden;
      .after-layer {
        position: absolute;
        background: linear-gradient(90deg, #54b0e4 0.31%, #32c4c0 100.16%);
        backdrop-filter: blur(6.25197696685791px);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
      }
      &:hover {
        .after-layer {
          display: flex;
        }
      }
    }
  }
}
.business-wrapper {
  padding: 80px 360px 260px 260px;
  position: relative;
  .right-content {
    max-width: 522px;
  }
  .back-img {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.new-opportunity {
  background-color: #f2f2f2;
  background-image: url("../assets/image/home/tenderback.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 80px 100px 100px 100px;
  .tender-wrapper {
    gap: 50px;
    .tender-box {
      max-width: 100%;

      height: 317.409px;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.63);
      backdrop-filter: blur(7.5px);
      &:hover {
        p {
          color: var(--cyan) !important;
        }
        h4 {
          color: var(--cyan) !important;
        }
        button {
          background: var(--cyan) !important;
        }
      }
    }
  }
}

.feature-benefit-wrapper {
  padding: 150px 200px;
  position: relative;
  .left-content {
    ul {
      padding-left: 38px;
      border-left: 4px solid var(--black);
      display: flex;
      flex-direction: column;
      gap: 50px;

      li {
        color: #1f2d5c;
        font-family: "Poppins";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        &.active {
          font-weight: 600;
          position: relative;
          &:after {
            position: absolute;
            content: "";
            width: 34px;
            height: 34px;
            border-radius: 100%;
            left: -56px;
            top: 50%;
            transform: translateY(-50%);
            background-image: url("/assets/icons/Ellipsecircle.svg");
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .right-content {
    width: 942px;
    height: 522px;
    border-radius: 17px;
    background: linear-gradient(
      90deg,
      rgba(205, 228, 220, 0.6) 0%,
      rgba(255, 171, 180, 0.6) 100%
    );
    background-color: white;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.43);
    backdrop-filter: blur(7px);
    padding: 60px 10px 20px 60px;
    display: flex;
    align-items: flex-start;
    position: relative;
    border-radius: 17px;
    background: linear-gradient(
      90deg,
      rgba(205, 228, 220, 0.6) 0%,
      rgba(50, 196, 192, 0.6) 100%
    );
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.43);
    backdrop-filter: blur(7px);
    .left {
      max-width: 410px;
      h4 {
        margin-bottom: 55px;
      }
    }
    // .bg-layer {
    //   position: absolute;
    //   content: "";
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   border-radius: 22px;
    //   background: #9d99ef;
    //   transform: rotate(-3.36deg);
    //   z-index: -1;
    // }
  }

  .finger-tips {
    background-image: url("../assets/image/home/fingerBack.png");
    background-size: cover;
    width: 1447px;
    height: 189px;
    border-radius: 16px;
    margin-top: 180px;
    h5 {
      color: #fff;
      font-family: "Poppins";
      font-size: 42.231px;
      font-style: normal;
      font-weight: 600;
    }
    .btn-wrapper {
      margin-top: -35px;
      margin-right: 30px;
      position: relative;
      img {
        position: absolute;
        width: 123.726px;
        height: auto;
      }
    }
  }
  .back-img {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    img {
      max-width: 70%;
    }
  }
}

.how-to-use {
  .use-content {
    padding: 80px 0;
    .header {
      p {
        margin: auto;
        max-width: 670px;
      }
    }
    .video-box {
      width: 1452px;
      height: 766px;
      border-radius: 44px;
      background: #fff;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.26);
      margin: auto;
      margin-top: 25px;
      padding: 15px;
      position: relative;
      img {
        &:first-child {
          width: 100%;
          height: 100%;
          border-radius: 44px;
        }
      }
      .play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
    .partners {
      .header {
        margin-top: 100px;
      }
    }
  }
  .partners-content {
    background: #dfdfdf;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 30px;
    padding: 100px 170px;
  }
}

.latest-news {
  padding: 70px 23px 70px;
  .content-wrapper {
    .header {
      max-width: 668px;
      margin: auto;
      margin-bottom: 100px;
    }
    .l-news {
      display: flex;
      align-items: stretch;
      justify-content: center;
      gap: 30px;
    }
  }
}

.h-pricing {
  padding-top: 30px;
  .header {
    position: relative;

    .select-plan {
      margin-top: 60px;
      color: #170f49;
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 166.667% */
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: center;
      input[type="checkbox"] {
        position: relative;
        width: 74px;
        height: 33px;
        -webkit-appearance: none;
        appearance: none;
        background: linear-gradient(90deg, #dc2424 0%, #4a569d 100%);
        background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);

        outline: none;
        border-radius: 2rem;
        cursor: pointer;
      }

      input[type="checkbox"]::before {
        content: "";
        width: 33px;
        height: 33px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.5s;
      }

      input[type="checkbox"]:checked::before {
        transform: translateX(125%);
        background: #fff;
      }

      input[type="checkbox"]:checked {
        // background: linear-gradient(90deg, #dc2424 0%, #4a569d 100%);
        background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
      }
    }
  }
  a {
    color: #ca0d20;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 150% */
  }

  .price-card-wrapper {
    padding: 0 130px;
    display: flex;
    justify-content: center;
    gap: 30px;
  }
}
.h-testimonials {
  background: url("../assets/image/home/testimonialBack.png");
  background-size: cover;
  padding: 50px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  .left-content {
    width: 35%;
    h2 {
      color: #fff;
      font-family: "Poppins";
      font-size: 64.982px;
      font-style: normal;
      font-weight: 600;
      line-height: 64.982px; /* 100% */
      letter-spacing: -0.866px;
    }
    p {
      color: #e1e1e1;
      font-family: "Poppins";
      font-size: 21.429px;
      font-style: normal;
      font-weight: 400;
      line-height: 32.143px; /* 150% */
      margin-top: 40px;
    }
  }
  .right-content {
    display: flex;
    gap: 30px;
    justify-content: flex-end;
    width: fit-content;
    flex-direction: column;
    .large-review {
      display: flex;
      gap: 30px;
    }
    .small-review {
      display: flex;
      gap: 30px;
      .reviews-card {
        display: flex;
        align-items: start;
        gap: 10px;
        padding: 15px;
        .card-body {
          padding: 0 !important;
        }
        img {
          width: 34.606px;
          height: 34.606px;
          border-radius: 100%;
        }
      }
    }
    .reviews-card {
      width: 219.942px;
      border-radius: 12.659px;
      background: #efefef;
      box-shadow: 0px 3.16463px 16.6143px 0px rgba(157, 175, 181, 0.25);
      overflow: hidden;
      .card-header {
        border-radius: 12.659px;
        height: 149.529px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .card-body {
        padding: 15px;
        p {
          color: #232527;
          font-family: Poppins;
          font-size: 11.535px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 17.303px */
        }
      }

      label {
        color: #293238;
        font-family: "Poppins";
        font-size: 11.076px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        flex-direction: column;
        span {
          color: #7d7a7a;
          font-family: Poppins;
          font-size: 11.076px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 1800px) {
  .feature-benefit-wrapper {
    padding: 150px 100px;
    position: relative;
    .finger-tips {
      width: 100%;
      margin-top: 160px;
      height: 160px;
      h5 {
        font-size: 35px;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .h-banner {
    .left-content {
      h2 {
        font-size: 65px;
        line-height: 75px;
      }
      p {
        max-width: 552px;
      }
    }
    .right-content {
      img {
        max-width: 80%;
      }
    }
  }

  .business-wrapper {
    padding: 80px 160px 160px 160px;
    position: relative;
    .back-img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 40%;
    }
  }
  .how-to-use {
    .use-content {
      .video-box {
        width: 80%;
        height: auto;

        .play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          max-width: 10%;
        }
      }
    }
  }

  .price-card-wrapper {
    .price-card {
      padding: 30px 20px 30px 30px;

      .card-header {
        p {
          font-size: 16px;
        }
        .type {
          gap: 15px;
          label {
            font-size: 20px;
          }
        }
      }
      .card-body {
        h6 {
          font-size: 42px;
          span {
            font-size: 18px;
          }
        }
        label {
          font-size: 16px;
        }
        ul {
          gap: 12px;
          li {
            span {
              font-size: 16px;
            }
          }
        }
      }
      .card-footer {
        button {
          height: 60px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .feature-benefit-wrapper {
    padding: 100px;
    position: relative;
    .right-content {
      width: 65%;
      height: 522px;
      padding: 60px 10px 20px 60px;
    }
    .finger-tips {
      background-color: red;
      width: 100%;
      margin-top: 160px;
      height: 160px;
      h5 {
        font-size: 35px;
      }
    }
  }
  .how-to-use .partners-content {
    padding: 80px 80px;
    row-gap: 60px;
    img {
      max-width: 70%;
      &:last-child {
        max-width: 50%;
      }
    }
  }
  .h-pricing {
    padding-bottom: 50px;
    .price-card-wrapper {
      padding: 0px 80px 20px 80px !important;
      .price-card {
        padding: 30px 20px 30px 30px;

        .card-header {
          p {
            font-size: 14px;
          }
          .type {
            gap: 15px;
            label {
              font-size: 18px;
            }
          }
        }
        .card-body {
          h6 {
            font-size: 32px;
            span {
              font-size: 16px;
            }
          }
          label {
            font-size: 16px;
          }
          ul {
            gap: 12px;
            li {
              display: flex;
              align-items: flex-start;
              img {
                margin-top: 3px;
                max-width: 18px;
              }
              span {
                font-size: 14px;
              }
            }
          }
        }
        .card-footer {
          button {
            height: 60px !important;
          }
        }
      }
    }
  }
  .simplee-footer {
    padding: 60px 90px;
    .simplee-footer-content {
      .left-content {
        p {
          font-size: 16px;
          max-width: 360px;
        }
      }
      .right-content {
        ul {
          // display: grid !important;
          // grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .business-wrapper {
    padding: 0px 100px 180px 100px;
    position: relative;
    .back-img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 40%;
    }
  }

  .feature-benefit-wrapper {
    padding: 80px;
    .left-content {
      ul {
        padding-left: 38px;
        gap: 30px;
        li {
          font-size: 24px;
        }
      }
    }

    .right-content {
      height: 500px;
      padding: 40px 10px 20px 40px;
      p {
        font-size: 18px;
      }
      h4 {
        font-size: 30px;
        margin-bottom: 30px !important;
      }
      .left {
        max-width: 350px;
      }
    }
    .finger-tips {
      h5 {
        font-size: 30px;
      }
    }
  }

  .h-testimonials {
    gap: 50px;
    justify-content: space-evenly;
    .left-content {
      width: 30%;
      h2 {
        font-size: 50px;
        line-height: 64.982px; /* 100% */
      }
      p {
        font-size: 18px;
        line-height: 25px;
        margin-top: 30px;
      }
    }
    .right-content {
      gap: 20px;
      .large-review {
        gap: 20px;
      }
      .small-review {
        display: flex;
        gap: 20px;
        .reviews-card {
        }
      }
    }
  }
  .h-pricing {
    .header {
      ol {
        padding: 0 200px;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .h-banner {
    height: 100vh;
    .left-content {
      h2 {
        font-size: 50px;
        line-height: 60px;
      }
      p {
        margin-bottom: 30px !important;
      }
    }
    .right-content {
      img {
        max-width: 80%;
      }
    }
  }

  .business-wrapper {
    padding: 0px 100px 180px 100px;
    position: relative;
    gap: 50px;
    .back-img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 40%;
    }

    .right-content {
      h3 {
        font-size: 40px;
        line-height: 50px;
      }

      p {
        font-size: 16px !important;
      }
    }
  }
  .new-opportunity {
    padding: 60px 80px 60px 80px;

    h4 {
      margin-bottom: 30px !important;
      padding-bottom: 0 !important;
    }
    .tender-wrapper {
      gap: 35px;
      .tender-box {
        height: 300px;
        img {
          margin-top: 10px;
          max-width: 20%;
        }
        h4 {
          font-size: 24px;
          margin-bottom: 0px !important;
          span {
            font-size: 18px;
          }
        }
      }
    }
  }
  .h-pricing {
    .header {
      p {
        font-size: 18px;
      }
      ol {
        padding: 0 100px;
      }
    }
    .price-card-wrapper {
      display: flex;
      justify-content: flex-start;
      overflow-x: auto;
      .price-card {
        min-width: 300px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .h-banner {
    height: 75vh;
    .left-content {
      h2 {
        font-size: 42px;
        line-height: 60px;
      }
      p {
        margin-bottom: 30px !important;
      }

      button {
        height: 58px !important;
      }
    }
    .right-content {
      img {
        max-width: 80%;
      }
    }
  }
  .h-categories {
    padding-bottom: 100px;
    .search-box {
      height: 72px;
      padding: 0 40px;
      img {
        width: 32px;
      }
    }
    .categories {
      margin-top: 100px;
      margin-bottom: 80px;
      .category-box {
        height: 170px;
        p {
          font-size: 20px !important;
          line-height: 24px !important;
        }
      }
    }
  }

  .business-wrapper {
    padding: 0px 80px 180px 80px;
    position: relative;
    gap: 50px;
    justify-content: space-around !important;
    .back-img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 40%;
    }

    .left-content {
      display: flex;
      justify-content: center;
      img {
        max-width: 70%;
      }
    }
    .right-content {
      max-width: unset;
      width: 50%;

      h3 {
        font-size: 32px;
        line-height: 40px;
      }

      p {
        font-size: 16px !important;
      }
    }
  }

  .feature-benefit-wrapper {
    padding: 80px;
    .feature-content {
      flex-direction: column;
    }
    .left-content {
      ul {
        padding-left: 0px;
        gap: 30px;
        border: none;
        flex-direction: row;
        overflow: auto;
        padding-bottom: 10px;
        margin-bottom: 40px;
        li {
          min-width: fit-content;
          font-size: 17px;
          width: fit-content;
          &.active {
            color: #ca0d20;
            &:after {
              display: none;
            }
          }
          &:first-child,
          &:last-child {
            display: none;
          }
        }
      }
    }

    .right-content {
      height: 100%;
      padding: 40px 10px 40px 40px;
      width: 75%;
      .left {
        max-width: 320px;
      }
    }
    .finger-tips {
      height: 130px;
      h5 {
        font-size: 25px;
      }
      button {
        height: 58px !important;
      }
    }
  }

  .simplee-footer {
    padding: 60px 40px;
    .simplee-footer-content {
      .right-content {
        ul {
          li {
            width: 40px !important;
            height: 40px !important;
          }
        }
      }
    }
  }

  .h-testimonials {
    gap: 50px;
    justify-content: space-evenly;
    .left-content {
      width: 35%;
      h2 {
        font-size: 50px;
        line-height: 48px;
      }
      p {
        font-size: 18px;
        line-height: 25px;
        margin-top: 30px;
      }
    }
    .right-content {
      gap: 20px;
      .large-review {
        gap: 20px;
        .reviews-card {
          &:last-child {
            display: none;
          }
        }
      }
      .small-review {
        display: flex;
        gap: 20px;
        .reviews-card {
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .price-card-wrapper {
    flex-wrap: wrap;
    justify-content: center !important;
    width: 100%;
  }
  .price-card.active-card {
  }
}

@media screen and (max-width: 600px) {
  .h-banner {
    height: auto;
    padding: 150px 23px 70px 23px;
    background-size: cover;
    .left-content {
      width: 100%;
      h2 {
        font-size: 30px;
        line-height: 45px;
      }
      p {
        font-size: 16px;
        width: 100%;
      }
    }
    .right-content {
      display: none;
    }
  }
  .h-categories {
    padding-left: 23px;
    padding-right: 23px;
    padding-bottom: 80px;
    .search-box {
      margin-top: -30px;
      height: 52px;
      padding: 0 40px;
      img {
        width: 24px;
      }
    }
    .categories {
      margin-top: 60px;
      margin-bottom: 60px;
      flex-direction: column;
    }
  }

  .business-wrapper {
    padding: 0px 23px 60px 23px;

    gap: 50px;
    flex-direction: column-reverse;
    justify-content: space-between !important;
    .back-img {
      display: none;
    }

    .left-content {
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        max-width: 70%;
      }
    }
    .right-content {
      max-width: unset;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h3 {
        font-size: 24px;
        line-height: 40px;
        text-align: center;
      }

      p {
        font-size: 16px !important;
        text-align: center;
      }
    }
  }

  .new-opportunity {
    padding: 60px 23px 60px 23px;
    background: none;

    h4 {
      font-size: 26px;
      line-height: 40px;
      margin-bottom: 30px !important;
      padding-bottom: 0 !important;
    }
    .tender-wrapper {
      gap: 35px;
      flex-direction: column;
      .tender-box {
        height: 300px;
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.43);

        img {
          margin-top: 10px;
          max-width: 20%;
        }
        h4 {
          font-size: 24px;
          margin-bottom: 0px !important;
          span {
            font-size: 18px;
          }
        }
      }
    }
  }

  .feature-benefit-wrapper {
    padding: 20px 20px;
    h3 {
      font-size: 35px;
      line-height: 40px;
    }
    .feature-content {
      flex-direction: column;
    }
    .left-content {
      width: -webkit-fill-available;
      ul {
        padding-left: 0px;
        gap: 30px;
        border: none;
        flex-direction: row;
        overflow: auto;
        padding-bottom: 10px;
        margin-bottom: 40px;
        li {
          min-width: fit-content;
          font-size: 17px;
          width: fit-content;
          &.active {
            color: #ca0d20;
            &:after {
              display: none;
            }
          }
          &:first-child,
          &:last-child {
            display: none;
          }
        }
      }
    }

    .right-content {
      padding: 20px 10px 20px 20px;
      width: 100%;
      flex-direction: column;
      h4 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 10px !important;
      }
      .left {
        max-width: 100%;
      }
    }
    .finger-tips {
      height: 130px;
      margin-top: 80px;
      h5 {
        padding: 0 10px;
        font-size: 20px;
      }
      .btn-wrapper {
        margin-top: -21px;
        margin-right: 10px;
        position: relative;
      }
      button {
        height: 42px !important;
        width: 180px !important;
      }
    }
  }
  .how-to-use {
    .use-content {
      padding-left: 23px;
      padding-right: 23px;
      .header {
        h3 {
          font-size: 32px !important;
        }
        p {
          font-size: 18px !important;
        }
      }
      .video-box {
        padding: 10px;
        width: 100%;
        border-radius: 16px;
        img {
          &:first-child {
            border-radius: 16px;
          }
        }
        .play-icon {
          max-width: 10%;
        }
      }

      .partners {
        .header {
          margin-top: 60px;
          h3 {
            font-size: 32px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
    .partners-content {
      padding: 80px 0px;
      row-gap: 30px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .h-pricing {
    padding-bottom: 30px;
    // padding-top: 30px;
    .header {
      padding: 20px;
      p {
        font-size: 16px;
        margin-top: 15px;
      }
      ol {
        padding: 0 0px;
        li {
          font-size: 14px !important;
          span {
            font-size: 14px !important;
            font-weight: 600 !important;
          }
        }
      }
      h3 {
        font-size: 32px;
        line-height: 43px;
        margin-bottom: 10px;
      }
    }
    .price-card-wrapper {
      padding: 50px 20px 20px 20px !important;
      .price-card {
        padding: 30px 20px 30px 30px;
      }
    }
  }

  .simplee-footer {
    padding: 60px 20px;
    .simplee-footer-content {
      flex-direction: column;
      gap: 30px;
      .left-content {
        p {
          font-size: 16px !important;
          max-width: 100%;
        }
      }
      .center-content {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 24px;
      }
    }
    & > img {
      max-width: 200px;
    }
  }

  .latest-news {
    padding: 20px 23px 40px 23px;
    .content-wrapper {
      .header {
        margin-bottom: 30px;
        h3 {
          font-size: 30px;
          line-height: 36px;
        }
        p {
          font-size: 16px;
        }
      }
      .l-news {
        flex-direction: column;
        gap: 30px;
        .s-newsCard {
          .card-body {
            p {
              &:last-child {
                font-size: 20px;
              }
            }
          }
          .card-footer {
            p {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  .h-testimonials {
    gap: 50px;
    justify-content: space-evenly;
    flex-direction: column;
    background: none;
    background: linear-gradient(90deg, #1f2d5c 0%, #c54333 100%);
    .left-content {
      width: 100%;
      h2 {
        font-size: 28px;
        line-height: 38px;
      }
      p {
        font-size: 18px;
        margin-top: 25px;
      }
    }
    .right-content {
      gap: 20px;
      flex-direction: column;
      .large-review {
        flex-direction: column;
        gap: 20px;
        .reviews-card {
          &:last-child {
            display: none;
          }
        }
      }
      .small-review {
        flex-direction: column;
        display: flex;
        gap: 20px;
        .reviews-card {
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}
