.appointments-sec {
  padding: 70px 75px 150px 65px;
  .top-bar {
    margin-bottom: 53.35px;
    padding: 15px 36px;
    column-gap: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 56.854px;
    background: var(--base-background-white, #fff);
    box-shadow: 0px -0.94756px 0px 0px #f1f1f1 inset,
      0px 0.94756px 14.21341px 0px rgba(0, 0, 0, 0.09);
    .bar-left,
    .bar-right {
      display: flex;
      align-items: center;
      column-gap: 12px;
      .dropdown-toggle {
        border-radius: 30.322px;
        border: 0.948px solid var(--gray-200, #eaecee);
        background: var(--base-background-white, #fff);
        color: var(--gray-900, #0c1116);
        font-family: General Sans;
        font-size: 13.266px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        padding: 12px 19px;
      }
    }
    .bar-left {
      .search-bar {
        display: flex;
        justify-content: space-between;
        max-width: 226.467px;
        align-items: center;
        padding: 11.371px 15.161px;
        border-radius: 30.322px;
        border: 0.948px solid var(--gray-200, #eaecee);
        background: var(--base-background-white, #fff);
        input {
          width: 100%;
          height: 100%;
          border: none;
          color: black;
          font-family: Inter;
          font-size: 13.266px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          &::placeholder {
            color: var(--gray-300, #d6dade);
          }
        }
      }
    }
    .bar-right {
      .empty-dropdown {
        padding: 0px;
        .dropdown-toggle {
          height: 39.2px;
          width: 39.2px;

          display: flex;
          align-items: center;
          justify-content: center;
          color: transparent;
          &::after {
            display: inline-block;

            content: url("/assets/image/dashboard/more-vert.png");
          }
        }
      }
    }
  }
  .cards-sec {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0px 30px 30px 30px;
    gap: 33px 60px;
    .appoint-card {
      border-radius: 14.6px;
      border: 1px solid #eceef6;
      background: #fff;
      box-shadow: 0px 2.32px 20.31px 0px rgba(0, 0, 0, 0.09);
      padding: 29px 27px 15px 11px;
      display: flex;
      column-gap: 8px;
      .img-side {
        img {
          width: 56px;
          height: 56px;
          object-fit: cover;
          border-radius: 100%;
        }
      }
      .detail-side {
        padding-top: 12px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        column-gap: 50px;
        .det-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          row-gap: 50px;
          .det-top {
            h4 {
              color: #1f2d5c;
              font-family: "Poppins";
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%;
            }
            p {
              color: #7d7a7a;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 140%; /* 19.6px */
            }
          }
          .det-bottom {
            h6 {
              color: #a6a6a6;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 140%; /* 19.6px */
            }
            h5 {
              color: #000;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
            }
          }
        }
        .det-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          row-gap: 50px;

          .detail-top {
            display: flex;
            column-gap: 27px;
            .icon-side {
              display: flex;
              align-items: flex-start;
              column-gap: 3px;
              h6 {
                color: #a6a6a6;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 140%; /* 19.6px */
              }
              h5 {
                color: #000;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
              }
            }
            .star-icon {
              width: 35px;
              height: 35px;
              object-fit: cover;
            }
          }
          .det-buttons {
            display: flex;
            column-gap: 10px;
            .cancel-btn {
              border-radius: 96px;
              border: 2px solid var(--as, #32c4c0);
              padding: 7px 22px 7px 21px;
              color: #000;
              text-align: center;
              font-feature-settings: "clig" off, "liga" off;
              font-family: DM Sans;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 111.111% */
              background: transparent;
            }
            .accept-btn {
              border-radius: 96px;
              background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
              padding: 7px 19px 7px 18px;
              color: var(--neutral-100, #fff);
              text-align: center;
              font-feature-settings: "clig" off, "liga" off;

              /* Text Single/200/Bold */
              font-family: DM Sans;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 20px; /* 111.111% */
              border: none;
            }
          }
        }
      }
    }
  }
  .see-more {
    display: flex;
    margin: 0px auto;
    text-align: center;
    color: #898989;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    border: none;
    background: transparent;
  }
}

@media screen and (max-width: 1600px) {
  .appointments-sec {
    padding: 0px 50px 100px 50px;
    .top-bar {
      margin-bottom: 40px;
    }
    .cards-sec {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0px 30px 30px 30px;
      gap: 33px 40px;
      .appoint-card {
        column-gap: 8px;
        .img-side {
          img {
            width: 45px;
            height: 45px;
          }
        }
        .detail-side {
          padding-top: 6px;
          column-gap: 30px;
          .det-left {
            row-gap: 30px;
          }
          .det-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 40px;

            .detail-top {
              display: flex;
              column-gap: 20px;
              .icon-side {
                display: flex;
                align-items: flex-start;
                column-gap: 3px;
                h6 {
                  color: #a6a6a6;
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 140%; /* 19.6px */
                }
                h5 {
                  color: #000;
                  font-family: Poppins;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 140%;
                }
              }
              .star-icon {
                width: 30px;
                height: 30px;
                object-fit: cover;
              }
            }
            .det-buttons {
              display: flex;
              column-gap: 10px;
              .cancel-btn {
                font-size: 17px;
                line-height: 100%;
              }
              .accept-btn {
                font-size: 17px;
                line-height: 100%; /* 111.111% */
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .appointments-sec {
    padding: 0px 40px 70px 40px;
    .top-bar {
      margin-bottom: 30px;
      padding: 15px 36px;
      column-gap: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 56.854px;
      background: var(--base-background-white, #fff);
      box-shadow: 0px -0.94756px 0px 0px #f1f1f1 inset,
        0px 0.94756px 14.21341px 0px rgba(0, 0, 0, 0.09);
      .bar-left,
      .bar-right {
        display: flex;
        align-items: center;
        column-gap: 12px;
        .dropdown-toggle {
          border-radius: 30.322px;
          border: 0.948px solid var(--gray-200, #eaecee);
          background: var(--base-background-white, #fff);
          color: var(--gray-900, #0c1116);
          font-family: General Sans;
          font-size: 13.266px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          padding: 12px 19px;
        }
      }
      .bar-left {
        .search-bar {
          display: flex;
          justify-content: space-between;
          max-width: 226.467px;
          align-items: center;
          padding: 11.371px 15.161px;
          border-radius: 30.322px;
          border: 0.948px solid var(--gray-200, #eaecee);
          background: var(--base-background-white, #fff);
          input {
            width: 100%;
            height: 100%;
            border: none;
            color: black;
            font-family: Inter;
            font-size: 13.266px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            &::placeholder {
              color: var(--gray-300, #d6dade);
            }
          }
        }
      }
      .bar-right {
        .empty-dropdown {
          padding: 0px;
          .dropdown-toggle {
            height: 39.2px;
            width: 39.2px;

            display: flex;
            align-items: center;
            justify-content: center;
            color: transparent;
            &::after {
              display: inline-block;

              content: url("/assets/image/dashboard/more-vert.png");
            }
          }
        }
      }
    }
    .cards-sec {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0px;
      gap: 20px 25px;
      margin-bottom: 30px;
      .appoint-card {
        padding: 20px 20px 15px 11px;
        .img-side {
          img {
            width: 40px;
            height: 40px;
          }
        }
        .detail-side {
          padding-top: 6px;
          column-gap: 20px;
          .det-left {
            row-gap: 30px;
            .det-top {
              h4 {
                font-size: 16px;
                line-height: 100%;
              }
              p {
                font-weight: 300;
                line-height: 100%;
              }
            }
          }
          .det-right {
            row-gap: 30px;

            .detail-top {
              display: flex;
              justify-content: space-between;
              column-gap: 20px;
              .icon-side {
                h6 {
                  line-height: 100%;
                }
                h5 {
                  line-height: 100%;
                }
              }
              .star-icon {
                width: 25px;
                height: 25px;
              }
            }
            .det-buttons {
              .cancel-btn {
                padding: 7px 14px;
                font-size: 15px;
                line-height: 100%; /* 111.111% */
              }
              .accept-btn {
                padding: 7px 14px;
                font-size: 15px;
                line-height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .appointments-sec {
    .cards-sec {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      padding: 0px;
      gap: 20px 25px;
      margin-bottom: 30px;
      .appoint-card {
        padding: 20px 20px 15px 11px;
        column-gap: 20px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .appointments-sec {
    padding: 0px;
    .top-bar {
      margin-bottom: 15px;
      padding: 10px;
      column-gap: 5px;

      .bar-left,
      .bar-right {
        justify-content: space-between;
        column-gap: 5px;
        .dropdown-toggle {
          font-size: 12px;
          padding: 10px;
        }
      }
      .bar-left {
        .search-bar {
          padding: 10px 15px;
          input {
            font-size: 12px;
          }
        }
      }
      .bar-right {
        justify-content: end;
        .empty-dropdown {
          padding: 0px;
          .dropdown-toggle {
            height: 34px;
            width: 34px;
          }
        }
      }
    }
    .cards-sec {
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;
      .appoint-card {
        padding: 20px 20px 15px 11px;
        column-gap: 10px;
        position: relative;
        .img-side {
          img {
            width: 40px;
            height: 40px;
          }
        }
        .detail-side {
          padding-top: 10px;
          row-gap: 10px;
          flex-direction: column;

          .det-left {
            row-gap: 10px;
            .det-top {
              h4 {
                font-size: 16px;
                line-height: 100%;
              }
              p {
                font-size: 14px;
                line-height: 100%;
              }
            }
            .det-bottom {
              h6 {
                line-height: 120%;
              }
              h5 {
                line-height: 140%;
              }
            }
          }
          .det-right {
            row-gap: 10px;

            .detail-top {
              column-gap: 15px;
              .icon-side {
                h6 {
                  line-height: 120%;
                }
                h5 {
                  line-height: 120%;
                }
              }
              .star-icon {
                width: 20px;
                height: 20px;
                position: absolute;
                top: 20px;
                right: 20px;
              }
            }
            .det-buttons {
              display: flex;
              column-gap: 10px;
              .cancel-btn {
                padding: 7px 15px;
                font-size: 15px;
                line-height: 15px;
              }
              .accept-btn {
                padding: 7px 15px;
                font-size: 15px;
                line-height: 15px;
              }
            }
          }
        }
      }
    }
    .see-more {
      display: flex;
      margin: 0px auto;
      text-align: center;
      color: #898989;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 140%;
      border: none;
      background: transparent;
    }
  }
}

@media screen and (max-width: 400px) {
  .appointments-sec {
    .top-bar {
      margin-bottom: 15px;
      padding: 10px;
      column-gap: 5px;

      .bar-left,
      .bar-right {
        justify-content: space-between;
        column-gap: 5px;
        .dropdown-toggle {
          font-size: 11px;
          padding: 10px;
        }
      }
      .bar-left {
        .search-bar {
          padding: 10px;
          input {
            font-size: 11px;
          }
        }
      }
      .bar-right {
        justify-content: end;
        .empty-dropdown {
          padding: 0px;
          .dropdown-toggle {
            height: 34px;
            width: 34px;
          }
        }
      }
    }
  }
}
