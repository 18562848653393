.summary-asidebar {
  width: 100%;
  flex-shrink: 0;
  box-shadow: 8.7053px -17.41059px 22.95033px 0px rgba(46, 46, 46, 0.1);
  padding: 50px 20px 30px 20px;
  background: rgba(26, 24, 31);
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background: white;
  border: none;
  border-radius: 10px;
  overflow: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  padding: 20px;
  z-index: 1000;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e3dede;
    padding-bottom: 10px;
    margin-bottom: 20px;

    h2 {
      margin: 0;
      font-size: 1.5rem;
      color: black;
      font-weight: 500;
    }

    .modal-close-button {
      background: none;
      border: none;
      font-size: 1rem;
      color: black;
      cursor: pointer;
    }
  }
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.contract-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-auto-rows: minmax(175px, auto); /* Ensures each row has a minimum height */
  gap: 16px; /* Adjust the gap as needed */

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr; /* 1 column on mobile */
  }
}

.summary-contract-card {
  width: 100%;
  min-height: 175px; /* Minimum height for the card */
  border-radius: 6.331px;
  border: 1.583px solid #e3dede;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
  cursor: pointer;

  label {
    color: #1f2d5c;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }

  p {
    color: #1f2d5c;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    margin-top: auto;
  }

  .card-content {
    flex-grow: 1;
  }

  .card-footer {
    margin-top: auto; /* Pushes the footer to the bottom */
    p {
      color: #606060;
    }
    .issue-date {
      display: flex;
      justify-content: end;
      padding-bottom: 5px;
      .issue {
        padding-right: 42px;
      }
      .due {
        padding-right: 1px;
      }
    }
  }

  &.active {
    background: linear-gradient(270deg, rgb(0, 0, 0) 0%, rgb(31, 45, 92) 100%);
    position: relative;

    label {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }
}



.summary-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  row-gap: 15px;

  .left-content {
    width: 100%;

    .search-box {
      padding: 0px 17.04px;
      height: 43.97px;
      border-radius: 6.491px;
      border: 0.811px solid #9ba4f1;
      background: #f8fbff;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .search-input::placeholder {
        color: #999; /* Change this to the desired color */
        opacity: 1; /* For Firefox */
      }

      input {
        border: none;
        background: transparent;
        width: 100%;
        color: #1f2d5c;
        font-family: Inter;
        font-size: 12.983px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }
}

.summary-content {
  margin-top: 131px;
}

.summarize-topbar {
  .left-content {
    .menu-icon {
      display: none;
    }
  }
}



.summary-box-wrapper {
  display: flex;
  flex-direction: column;
  gap: 33px;
  padding-bottom: 80px;
  position: relative;
  z-index: 3;

  .summary-box {
    border-radius: 22.703px;
    background: #fff;
    box-shadow: 0px 1.46473px 36.61817px 0px rgba(0, 0, 0, 0.15);
    padding: 15px 15px;

    .summary-table {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .summary-row {
        display: flex;
        align-items: center;
        padding: 7px;
        border-bottom: 1px solid #e7e7e7;

        &:last-child {
          grid-column: 1 / -1;
        }

        .summary-title {
          width: 178px;
        }

        .summary-details {
          width: fit-content;
          max-width: 320px;

          a {
            word-break: break-all;
          }
        }
      }

      p {
        color: #5c5b5b;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.577px;
      }
    }
  }

  .note {
    .content-dis {
      display: flex;
      justify-content: space-between;
    }
  }
}

.resetfilter{
  width: 135px;
  height: 37px;
  border-radius: 50px;
  color: #fff;
  border: 2px solid #cde4dc;
  font-family: poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  background: transparent;
  line-height: 36px;
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.searchbtn{
  width: 174px;
  height: 43px;
  border-radius: 21.5px;
  color: #fff;
  border: 2px solid #cde4dc;
  font-family: poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  background: transparent;
  line-height: 36px;
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.paginations {
  padding-bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .paginate-box {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dfe3e8;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;

    &.active {
      border: 1px solid #1f2d5c;
    }

    &.disabled,
    &:first-child {
      transform: rotate();
    }
  }
}

.rc-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .rc-pagination-prev {
    button {
      background-image: url("../assets/icons/chevron-left.svg");
      background-position: 50%;
      background-color: #919eab;
      background-color: #11373e;
    }
  }

  .rc-pagination-next {
    button {
      background-image: url("../assets/icons/chevron-right.svg");
      background-position: 50%;
    }
  }

  .rc-pagination-disabled {
    button {
      background-color: #919eab00;
    }
  }

  .rc-pagination-item {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #dfe3e8;
    background: #fff;
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #dfe3e8;
    cursor: pointer;
  }
}

.subscription {
  .subscription-report {
    width: 100%;
    overflow: auto;
    margin: 0 auto;
    padding-top: 55px;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;
  }

  .summary-filter {
    .right-content {
      justify-content: space-between;
    }
  }
}

.arrow-up-icon {
  background: #0f085c;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: 20px;
  bottom: 40px;
}

@media screen and (max-width: 1900px) {
  .summary-report {
    padding-left: 50px;
    padding-right: 50px;
  }

  .summary-box-wrapper .summary-box .summary-table {
    display: flex;
    flex-direction: column;
  }

  .subscription {
    .subscription-report {
      padding-left: 30px;
      padding-right: 30px;
    }

    .summary-box-wrapper .summary-box .summary-table {
      display: flex;
      flex-direction: column;
    }

    .summary-filter {
      .right-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .summary-report {
    padding-left: 50px;
    padding-right: 50px;
  }

  .summary-filter {
    .left-content {
      .search-box {
        width: 350px;

        img {
          width: 18px;
        }
      }
    }
  }

  .summary-asidebar {
    width: 100%;
  }

  .summary-box-wrapper
    .summary-box
    .summary-table
    .summary-row
    .summary-details {
    max-width: 600px;
    width: 100%;
  }

  .subscription .summary-filter .right-content {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1600px) {
  .top-layer {
    img {
      width: 800px;
      height: 600px;
    }
  }

  .summary-filter {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;

    .left-content {
      width: 100%;

      .search-box {
        width: 100%;
      }
    }

    .right-content {
      .simpliee-dropdown {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          gap: 10px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .summary-box-wrapper .summary-box .summary-table .summary-row .summary-title {
    width: 237px;
  }
}

@media screen and (max-width: 600px) {
  .top-layer {
    img {
      display: none;
    }
  }

  .summary-content {
    background: none !important;
  }

  .summarize-topbar {
    height: 150px;
    flex-direction: column;
    gap: unset !important;
    padding-inline: 20px !important;

    .left-content {
      display: flex;
      margin-top: 15px;
      align-self: flex-start;

      .menu-icon {
        position: fixed;
        display: flex;
        right: 15px;
      }
    }

    .right-content {
      width: fit-content !important;
      gap: 10px;
      padding: 10px 20px !important;
      margin-bottom: 10px;

      .pointer {
        font-size: 12px;
      }

      .new-user-btn {
        button {
          height: 25px !important;
          width: 80px !important;
        }
      }
    }
  }



  .summary-asidebar-show {
    width: 100%;
    display: block;
    position: absolute;
    z-index: 10;

    animation: slideFromLeft 0.9s ease forwards;

    @keyframes slideFromLeft {
      from {
        transform: translateX(-100%);
      }

      to {
        transform: translateX(0);
      }
    }
  }

  .summary-filter {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;

    .left-content {
      width: 100%;

      .search-box {
        width: 100%;

        input {
          font-size: 12px;
        }
      }
    }

    .right-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;

      .selected-item {
        min-height: 43.97px;
        height: unset;
      }

      .simpliee-dropdown {
        width: 100%;

        button {
          gap: 50px;
          width: 100%;
        }
      }
    }
  }

  .summary-report {
    padding-top: 40px;
    padding-inline: 20px;

    .summary-reports {
      .header {
        margin-top: 20px !important;

        .summary-btns {
          flex-wrap: wrap;
          justify-content: space-between !important;

          button {
            width: 100% !important;

            &.print-btn {
              width: 100% !important;
              order: 1;
            }
          }

          svg {
            order: 2;
            margin: 0px auto;
          }
        }
      }

      .summary-box-wrapper {
        padding-bottom: 100px;
      }
    }

    .summary-box {
      padding: 20px;

      .summary-row {
        flex-direction: column;
        align-items: flex-start !important;

        .summary-title {
          p {
            font-size: 15px;
            font-weight: 600;
          }
        }

        .summary-details {
          p {
            font-size: 14px;
          }
        }
      }
    }
  }


}

@media screen and (max-width: 1500px) {
  .summary-filter .right-content {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
  }

  .subscription .summary-filter .right-content {
    justify-content: space-between;
  }
}

@media screen and (max-width: 1400px) {
  .subscription
    .summary-box-wrapper
    .summary-box
    .summary-table
    .summary-row
    .summary-details {
    max-width: 400px;
    width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .subscription {
    .subscription-report {
      padding-left: 20px;
      padding-right: 20px;
    }

    .summary-report {
      .btn-prime {
        font-size: 11px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .subscription {
    .summary-asidebar {
      .summary-contract-card {
        padding: 13px 10px;
      }
    }

    .top-layer {
      img {
        display: none;
      }
    }

    .summary-content {
      background: none !important;
    }

    .summarize-topbar {
      height: 150px;
      flex-direction: column;
      gap: unset !important;
      padding-inline: 20px !important;

      .left-content {
        display: flex;
        margin-top: 15px;
        align-self: flex-start;

        .menu-icon {
          position: fixed;
          display: flex;
          right: 15px;
        }
      }

      .right-content {
        width: fit-content !important;
        gap: 10px;
        padding: 10px 20px !important;
        margin-bottom: 10px;

        .pointer {
          font-size: 12px;
        }

        .new-user-btn {
          button {
            height: 25px !important;
            width: 80px !important;
          }
        }
      }
    }



    .summary-asidebar-show {
      width: 100%;
      left: 0;
      display: block;
      position: absolute;
      z-index: 10;

      animation: slideFromLeft 0.9s ease forwards;

      @keyframes slideFromLeft {
        from {
          transform: translateX(-100%);
        }

        to {
          transform: translateX(0);
        }
      }
    }

    .summary-filter {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 30px;

      .left-content {
        width: 100%;

        .search-box {
          width: 100%;

          input {
            font-size: 12px;
          }
        }
      }

      .right-content {
        .selected-item {
          min-height: 43.97px;
          height: unset;
        }

        .simpliee-dropdown {
          width: 100%;

          button {
            gap: 50px;
            width: 100%;
          }
        }
      }
    }

    .subscription {
      .summary-report {
        padding-top: 40px;
        padding-inline: 20px;

        .summary-reports {
          .header {
            margin-top: 20px !important;

            .summary-btns {
              flex-wrap: wrap;
              justify-content: space-between !important;

              button {
                width: 100% !important;

                &.print-btn {
                  width: 100% !important;
                  order: 1;
                }
              }

              svg {
                order: 2;
                margin: 0px auto;
              }
            }
          }

          .summary-box-wrapper {
            padding-bottom: 50px;
          }
        }

        .summary-box {
          padding: 20px;

          .summary-row {
            flex-direction: column;
            align-items: flex-start !important;

            .summary-title {
              p {
                font-size: 13px;
                font-weight: 600;
              }
            }

            .summary-details {
              p {
                font-size: 11px;
              }
            }
          }
        }
      }


    }
  }
}

.note {
  h5 {
    font-weight: bold;
    color: #1f2d5c;
    font-size: 18px;
    padding: 3px;
  }

  p {
    font-size: 12px;
    line-height: 22px;
    margin-top: 5px;
    padding: 3px;
  }
}

@media screen and (max-width: 600px) {
  .note p {
    font-size: 12px;
  }

  .subscription .subscription-report {
    padding-left: 0px;
    padding-right: 0px;
  }

  .arrow-up-icon {
    right: 10px;
    bottom: 50px;
  }
}
