.container {
    width: 100%;
    max-width: 72rem;
    /* adjust to your preferred maximum width */
    margin: 0 auto;
    /* centers the container */
}
.containers {
    width: 100%;
    max-width: 90rem;
    /* adjust to your preferred maximum width */
    margin: 0 auto;
    /* centers the container */
}
ol.lst-kix_hizi0dxlaqcs-4.start {
    counter-reset: lst-ctn-kix_hizi0dxlaqcs-4 0
}

ol.lst-kix_hizi0dxlaqcs-2.start {
    counter-reset: lst-ctn-kix_hizi0dxlaqcs-2 0
}

ol.lst-kix_hizi0dxlaqcs-7.start {
    counter-reset: lst-ctn-kix_hizi0dxlaqcs-7 0
}

.lst-kix_hizi0dxlaqcs-0>li:before {
    content: "" counter(lst-ctn-kix_hizi0dxlaqcs-0, decimal) ". ";
    font-size: 28px;
    font-family: Arial, Helvetica, sans-serif;
}
.lst-kix_hizi0dxlaqcs-0001>li:before {
    content: "" counter(lst-ctn-kix_hizi0dxlaqcs-0, decimal) ". ";
    font-size: 28px;
    font-family: Arial, Helvetica, sans-serif;

    
}

.lst-kix_hizi0dxlaqcs-1>li {
    counter-increment: lst-ctn-kix_hizi0dxlaqcs-1
}

ol.lst-kix_hizi0dxlaqcs-7 {
    list-style-type: none
}

ol.lst-kix_hizi0dxlaqcs-8 {
    list-style-type: none
}

.lst-kix_hizi0dxlaqcs-1>li:before {
    content: "" counter(lst-ctn-kix_hizi0dxlaqcs-1, lower-latin) ". "
}

ol.lst-kix_hizi0dxlaqcs-6.start {
    counter-reset: lst-ctn-kix_hizi0dxlaqcs-6 0
}

.lst-kix_hizi0dxlaqcs-2>li:before {
    content: "" counter(lst-ctn-kix_hizi0dxlaqcs-2, lower-roman) ". "
}

.lst-kix_hizi0dxlaqcs-5>li {
    counter-increment: lst-ctn-kix_hizi0dxlaqcs-5
}

ol.lst-kix_hizi0dxlaqcs-0 {
    list-style-type: none
}
ol.lst-kix_hizi0dxlaqcs-0001 {
    list-style-type: none
}
ol.lst-kix_hizi0dxlaqcs-1 {
    list-style-type: none
}

.lst-kix_hizi0dxlaqcs-2>li {
    counter-increment: lst-ctn-kix_hizi0dxlaqcs-2
}

ol.lst-kix_hizi0dxlaqcs-2 {
    list-style-type: none
}

.lst-kix_hizi0dxlaqcs-8>li {
    counter-increment: lst-ctn-kix_hizi0dxlaqcs-8
}

ol.lst-kix_hizi0dxlaqcs-1.start {
    counter-reset: lst-ctn-kix_hizi0dxlaqcs-1 0
}

ol.lst-kix_hizi0dxlaqcs-3 {
    list-style-type: none
}

ol.lst-kix_hizi0dxlaqcs-4 {
    list-style-type: none
}

ol.lst-kix_hizi0dxlaqcs-5 {
    list-style-type: none
}

ol.lst-kix_hizi0dxlaqcs-6 {
    list-style-type: none
}

.lst-kix_hizi0dxlaqcs-8>li:before {
    content: "" counter(lst-ctn-kix_hizi0dxlaqcs-8, lower-roman) ". "
}

.lst-kix_hizi0dxlaqcs-6>li {
    counter-increment: lst-ctn-kix_hizi0dxlaqcs-6
}

.lst-kix_hizi0dxlaqcs-7>li:before {
    content: "" counter(lst-ctn-kix_hizi0dxlaqcs-7, lower-latin) ". "
}

.lst-kix_hizi0dxlaqcs-3>li {
    counter-increment: lst-ctn-kix_hizi0dxlaqcs-3
}

.lst-kix_hizi0dxlaqcs-6>li:before {
    content: "" counter(lst-ctn-kix_hizi0dxlaqcs-6, decimal) ". "
}

.lst-kix_hizi0dxlaqcs-4>li:before {
    content: "" counter(lst-ctn-kix_hizi0dxlaqcs-4, lower-latin) ". "
}

.lst-kix_hizi0dxlaqcs-3>li:before {
    content: "" counter(lst-ctn-kix_hizi0dxlaqcs-3, decimal) ". "
}

.lst-kix_hizi0dxlaqcs-5>li:before {
    content: "" counter(lst-ctn-kix_hizi0dxlaqcs-5, lower-roman) ". "
}

ol.lst-kix_hizi0dxlaqcs-8.start {
    counter-reset: lst-ctn-kix_hizi0dxlaqcs-8 0
}

li.li-bullet-0:before {
    margin-left: -18pt;
    color: #000000;
    font-weight: bold;
    font-size: 1.875rem;
}

ol.lst-kix_hizi0dxlaqcs-3.start {
    counter-reset: lst-ctn-kix_hizi0dxlaqcs-3 0
}

ol.lst-kix_hizi0dxlaqcs-0.start {
    counter-reset: lst-ctn-kix_hizi0dxlaqcs-0 0
}

ol.lst-kix_hizi0dxlaqcs-0001.start {
    counter-reset: lst-ctn-kix_hizi0dxlaqcs-0 0
}
.lst-kix_hizi0dxlaqcs-4>li {
    counter-increment: lst-ctn-kix_hizi0dxlaqcs-4
}

.lst-kix_hizi0dxlaqcs-7>li {
    counter-increment: lst-ctn-kix_hizi0dxlaqcs-7
}

ol.lst-kix_hizi0dxlaqcs-5.start {
    counter-reset: lst-ctn-kix_hizi0dxlaqcs-5 0
}

.lst-kix_hizi0dxlaqcs-0>li {
    counter-increment: lst-ctn-kix_hizi0dxlaqcs-0;
    font-size: 28px;
    
}

.lst-kix_hizi0dxlaqcs-0001>li {
    counter-increment: lst-ctn-kix_hizi0dxlaqcs-0;
    font-size: 28px;

}


.c9 {
    color: #000000;
    font-weight: bold;
    font-size: 2.125rem;
    line-height: 0px;
    font-family: Arial, Helvetica, sans-serif;
}
.h2 {
    color: #000000;
    font-weight: bold;
    font-size: 1.875rem;
    font-family: Arial, Helvetica, sans-serif;
}



.c1 {
    color: #434343;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 14pt;
    
    font-style: normal
}


.c13 {
    padding-top: 20pt;
    padding-bottom: 10pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c6 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    
    font-style: normal
}

.c2 {
    padding-top: 0pt;
    padding-bottom: 10pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: center
}

.c7 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    font-size: 16pt;
    font-family: Arial, Helvetica, sans-serif;
}



.c11 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    
    font-style: normal
}

.c12 {
    background-color: #ffffff;
    /* max-width: 468pt; */
    padding: 72px 0px 72px 0px
}

.c8 {
    padding: 0;
    margin: 0
}

.c5 {
    margin-left: 18pt;
    padding-left: 0pt
}

.c0 {
    font-size: 44px;
    font-weight: 800;

    font-family: Arial, Helvetica, sans-serif;
}





@media (max-width: 600px) {
    .container {
        max-width: 90%;
    }
    .c0{
        font-size: 18px;
    }
    .c9{
        font-size: 20px;
        font-weight: 800;
    }
    .c5{
        margin-left: 20px;
    }
    li{
        font-size: 20px;
    }   
}
.padding-adding{
    padding: 75px 0px 75px 0px;
}
.padding-adding h3{
    line-height: 2.5rem;
    padding-bottom: 20px;
    padding-top: 10px;

}