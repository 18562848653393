.detail-modal-body {
  overflow: scroll;
  max-height: 740px;
}
@media screen and (max-width: 600px) {
  .detail-modal-body {
    overflow: scroll;
    max-height: 600px;
  }
}

.fav-upgrade-btn {
  // width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  button {
    border-radius: 96px;
    border: 2px solid #fff;
    background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
    padding: 15px 43px;
    color: var(--neutral-100, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
}
