.btn-prime {
  background-color: transparent;
  color: #fff;
  border: 1px solid #ffffff;
  border-radius: 30px;
  padding: 10px 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex; // Use flexbox
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
  height: 40px; // Fixed height
  text-align: center;
  width: auto; // Auto width based on content plus padding
  transition: background-color 0.4s, color 0.4s; 
}

.btn-prime:hover {
  background-color: none;      /* Change background color on hover */
  color: #ffffff;                  /* Change text color on hover */
  border: 1px solid #32C4C0;
}


.btn-prime-1 {
  font-family: General Sans;
  font-size: 10.748px;
  font-style: normal;
  font-weight: 400;
  border: none;
  line-height: 100%; /* 10.748px */
}

.btn-grey {
  background: #cde4dc;
}
.btn-gradient {
  // background: linear-gradient(90deg, #7b4397 0%, #dc2430 100%);
  // filter: drop-shadow(4px 4px 6px rgba(242, 41, 61, 0.5));

  background: linear-gradient(90deg, #1f2d5c 0%, #000000 100%);
}
.btn-blue {
  border-radius: 5.347px;
  background: linear-gradient(90deg, #202c59 0%, #112e9b 100%);
}

​ .btn-primary {
  font-family: "Arboria-Medium";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--gold);
  border: 1px solid var(--gold);
  border-radius: 8px;
  &:hover {
    color: var(--gold);
    background: transparent;
  }
}
.btn-secondary {
  font-family: "Arboria-Medium";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: transparent;
  border: 1px solid var(--medium-grey);
  border-radius: 8px;
  color: var(--white);
  &:hover {
    color: var(--black);
    background: var(--medium-grey);
  }
}
.btn-primary-2 {
  font-family: "Arboria-Book";
  font-size: 20px;
  &:hover {
    color: var(--black);
    background: var(--gold);
  }
}
​ .font-book {
  font-family: "Arboria-Book";
}

.selected-item {
  height: 43.97px;
  border-radius: 8px;
  border: 1px solid #9ba4f1;
  background: #f8fbff;
  padding: 5px 10px;
  transition: all 0.3s linear;
  &:hover {
    .ico {
      color: red !important;
    }
  }
}
