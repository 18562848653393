.registerParent {
  overflow: hidden;
  background: linear-gradient(315deg, #fff, #32c4c0, #4A3AFF, #231f37, #000);
  background-size: 250% 250%;

}

.reg-container{
  display: flex;
  width: 1000px;
  height: 550px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  backdrop-filter: blur(300px);
  margin-top: 75px;
}

::placeholder{
  color: white;
  letter-spacing: 0.5px;
  font-size: 14px;
}

.text-center1{
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
  margin-bottom: 50px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.text-center1-forgot {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 140px;
  margin-bottom: 50px;
  font-weight: 500;
  letter-spacing: 0.5px;

}

.register-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;




  .left {
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    width: 55%;
    background: linear-gradient(270deg, #1F2D5C 0%, #000 100%);
    backdrop-filter: blur(600px);
    border-radius: 5% 30% 20% 5%;
    transition: border-radius .3s;


    .simpli-logo {
      max-width: 270px;
      margin-bottom: 25px;
    }

    .content {
      max-width: 500px;
      text-align: center;
      color: white;
      width: 400px;
      margin-top: 25px;

      h4 {
        line-height: 35px;
        margin-bottom: 10px;
        font-family: "Zen Kaku Gothic Antique";
      }
    }
  }

  .right {
    position: relative;
    width: 45%;
    padding: 20px;
  }

  .btnbtn-box{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }


  .btn1{
    font-weight: 500;
    padding: 5px 30px;
    border: none;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: .2;
  }

  .button-2{
    background: #4A3AFF;

  }

  .btn1:hover{
    opacity: 0.85;
  }

  .form-sec{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 4vw;
    transition: .3s;
  }

  .form-group{
    position: relative;
  }

  .form-group2{
    position: relative;
    margin-bottom: 25px;
  }

  .form-control1{
    width: 100%;
    height: 40px;
    padding: 0 15px;
    margin: 10px 0;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    border: none;
    border-radius: 10px;
    outline: none;
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .form-control2{
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    margin: 10px 0;
    background: #1F2D5C;
    color: white;
    border: none;
    border-radius: 10px;
    outline: none;
    backdrop-filter: blur(20px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  
    

      .agree-sec {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        margin-top: 10px;

        p {
          flex: 1;
          color: #757575;
          font-family: "Zen Kaku Gothic Antique";
          font-size: 12px;
          font-weight: 400;
          line-height: 22.53px; /* 220.02% */

          input {
            width: 21.524px;
            height: 22px;
            cursor: pointer;
          }
        }

        .forget, .sign-up {
          height: 35px;
          width: 140px;
          padding: 0 40px;
          border-radius: 56px;
          border: 1px solid;
          color: white;
          text-align: center;
          font-family: "Zen Kaku Gothic Antique";
          font-size: 12.8px;
          font-weight: 700;
          line-height: 22.53px; /* 176.016% */
        }

        .forget {
          background: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%);
        }
      }
    }

    .own-account {
      margin-top: 30px;
      color: #424242;
      text-align: center;
      font-family: "Zen Kaku Gothic Antique";
      font-size: 12.8px;
      font-weight: 400;

      a {
        color: #32C4C0;
        font-weight: 500;
        text-decoration: underline;
        letter-spacing: 0.5px;
      }
    }

  


.terms-modalheader {
  background: linear-gradient(270deg, #1f2d5c 0%, #0A1988 100%);
  color: white;
  padding: 15px 50px;
  position: relative;

  .btn-close {
    filter: brightness(0) invert(1);
    position: absolute;
    right: 20px;
    border: 1px solid #fff;
  }

  .modal-title {
    display: flex;
    margin: 0 auto;
  }
}

.terms-modalbody {
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  p, a, h4, ul li {
    color: #393939;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 300;
    line-height: 140%;
    text-align: justify;
  }

  h4 {
    color: #1f2d5c;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    line-height: 120%; /* 121.429% */
  }

  ul {
    padding-left: 20px;
  }
}


@media (max-width: 1500px) {
  .register-sec .left {
    padding: 50px 100px;
    gap: 30px;


  }
}

@media (max-width: 1400px) {
  .register-sec .right {
    gap: 10px;

    h4 {
      font-size: 24px;
      line-height: 100%;
    }

    .form-sec {
      width: 380px;

      .form-group input {
        line-height: 100%;
      }

      .agree-sec .sign-up {
        padding: 0 25px;
        height: 40px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .register-sec .right .form-sec {
    width: 100%;

    .agree-sec {
      column-gap: 10px;
    }
  }
}

@media (max-width: 600px) {
  .register-sec .left {
    display: none;
  }

  .register-sec .right {
    width: 100%;
    margin-left: 0;

    .twobtns {
      column-gap: 15px;

      .googlebtn, .githubbtn {
        width: 150px;
        height: 35px;
        gap: 10px;
      }
    }

    .form-sec {
      width: 100%;
      max-width: 400px;

      .agree-sec .forget, .agree-sec .sign-up {
        height: 40px;
        padding: 0 20px;
      }
    }
  }

  .terms-modalheader {
    padding: 10px;

    .btn-close {
      right: 10px;
      width: 10px;
      height: 10px;
      padding: 8px;
    }

    .modal-title {
      font-size: 18px;
    }
  }

  .terms-modalbody {
    padding: 10px;
    row-gap: 2px;

    h4 {
      font-size: 20px;
    }
  }

}
