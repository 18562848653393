.s-newsCard {
  border-radius: 12px;
  border: 1px solid var(--secondary-100, #e8e8ea);
  background: var(--White, #fff);
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.15);
  max-width: 392px;
  max-height: 392px;
  width: 100%;
  padding: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  
  .card-header {
    width: 100%;
    height: 240px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .card-body {
    .stack {
      padding: 5px 10px;
      border-radius: 6px;
      background: rgba(75, 107, 251, 0.05);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      color: #4b6bfb;
      width: fit-content !important;
    }

    .blog-title{
      font-size: 1.2rem;
      margin-top: 5px;
      line-height: 1.4;
      font-weight: 600;
    }
  }
  .card-footer {
    margin-top: 25px;
    img {
      width: 30px;
      height: 30px;
    }
  }
}
