.usa-tenders {
  .usa-banner {
    padding-block: 290px;
    padding-inline: 393px;
    background-image: url("../assets/image/home/usaBack.png");
    background-size: cover;

    .content {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      gap: 24px;

      .usa-banner-btn {
        padding-top: 41px;
        button {
          width: 204.63px;
          height: 68px;
          border-radius: 47px;
          flex-shrink: 0;
          border: unset;
          outline: unset;
          cursor: pointer;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 68px; /* 425% */
        }
      }
    }
  }
}

// Media Queries --------------------------------------------------------------------------------------------------- //
@media screen and (min-width: 360px) and (max-width: 600px) {
  .usa-tenders {
    .usa-banner {
      padding-top: 150px;
      padding-bottom: 100px;
      padding-inline: 20px;

      .content {
        gap: 10px;
        .usa-banner-title {
          h1 {
            font-size: 24px;
            line-height: 35px;
          }
        }
        .usa-banner-subtitle {
          p {
            font-size: 10px;
            line-height: 15px;
          }
        }

        .usa-banner-btn {
          padding-top: 35px;

          button {
            width: 160px;
            height: 50px;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .usa-tenders {
    .usa-banner {
      padding-bottom: 200px;
      padding-inline: 100px;

      .content {
        .usa-banner-title {
          h1 {
            font-size: 76px;
            line-height: 90px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .usa-tenders {
    .usa-banner {
      padding-block: 250px;
      padding-inline: 120px;

      .content {
        .usa-banner-title {
          h1 {
            font-size: 78px;
            line-height: 92px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1501px) and (max-width: 1800px) {
  .usa-tenders {
    .usa-banner {
      padding-inline: 200px;
    }
  }
}
