.blog-detail {
  background-color: #fff;
  padding: 173px 100px 185.11px 100px;

  .blog-content {
    max-width: 1300px;
    margin: 0 auto;

    .content-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10.52px;
      width: 117px;
      height: 35.477px;
      border-radius: 35.477px;
      background-color: #dddbdb;

      p {
        font-size: 12.077px;
        font-style: normal;
        font-weight: 400;
        line-height: 51.329px; /* 425% */
      }
    }
    p {
      color: #2d2d2d;
      font-family: Inter;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .trick-sec {
      h2 {
        font-size: 59.962px;
        font-style: normal;
        font-weight: 600;
        line-height: 71.621px; /* 119.444% */
        margin-bottom: 40px;
      }

      .writers {
        display: flex;
        align-items: center;
        gap: 27px;
        margin-top: 54px;
        margin-bottom: 68px;
        .writer-img {
          display: flex;
          align-items: center;
          .writer1 {
            z-index: 1;
            width: 50px;
            height: 50px;
            border-radius: 10px;
          }
          .writer2 {
            margin-left: -35px;
            width: 90px;
            height: 90px;
            border-radius: 50px;
            display: none;
          }
        }
        .writer-detail {
          color: #707070;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          span {
            color: #000;
            font-weight: 600;
          }
        }
      }
    }

    .carpanter-sec {
      .carp-img {
        img {
          width: 100%;
          border-radius: 26.154px;
          object-fit: cover;
        }
      }
      .carpan-text {
        padding-top: 50px;
      }
    }

    .other-sec {
      padding-top: 40px;
      display: none;
      .butterfly-sec {
        display: flex;
        padding-right: 100px;
        padding-bottom: 48px;
        gap: 12px;
        h3 {
          font-size: 35px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          position: relative;
          padding-left: 15px;
          &::after {
            position: absolute;
            content: "";
            width: 6px;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 2px;
            background: linear-gradient(180deg, #cc2b5e 0%, #06118e 100%);
          }
        }
      }

      .worker-img {
        display: flex;
        align-items: center;
        gap: 51.46px;
        padding-bottom: 51.47px;
        .right {
          width: 50%;
        }
        .left {
          width: 50%;
        }
      }

      .worker-group {
        padding-top: 20px;
        padding-bottom: 57px;
        img {
          width: 100%;
        }
      }

      .social-media {
        display: flex;
        justify-content: end;
        align-items: center;
        .content {
          p {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            span {
              font-weight: 600;
            }
          }
        }
        .social-icons {
          display: flex;
          gap: 13.66px;
          .icons-bg {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48.818px;
            height: 48.818px;
            border-radius: 12.195px;
            background: #000;
            cursor: pointer;
          }
        }
      }
    }

    .might-like-sec {
      display: none;
      .might-heading {
        text-align: center;
        color: #000;
        font-family: Poppins;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .might-cards {
        display: grid;
        column-gap: 35.5px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .might-card {
          .might-card-img {
            img {
              width: 100%;
            }
          }
          .might-btns {
            margin-top: 13.68px;
            margin-bottom: 10px;
            display: flex;
            gap: 6.49px;
            .idea-text {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 45.419px;
              height: 18.924px;
              border-radius: 19.183px;
              background-color: #dddbdb;
              p {
                color: #000;
                font-size: 6.53px;
                font-style: normal;
                font-weight: 400;
                line-height: 27.754px; /* 425% */
              }
            }
            .ai-text {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 84.349px;
              height: 18.924px;
              border-radius: 19.183px;
              background-color: #dddbdb;
              p {
                color: #000;
                font-size: 6.53px;
                font-style: normal;
                font-weight: 400;
                line-height: 27.754px; /* 425% */
              }
            }
          }

          .might-content {
            h3 {
              color: #000;
              font-size: 21.23px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 3px;
            }
            p {
              color: #707070;
              font-size: 8.11px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

.news-letter {
  padding: 0 100px;
  display: flex;
  justify-content: center;
  .subscribe-sec {
    padding-top: 60px;
    padding-bottom: 60px;

    .subscribe-bg {
      display: flex;
      padding: 37px 31px 36px 66px;
      background: #1f2d5c;
      border-radius: 22.388px;
      max-width: 1287px;
      min-height: 350px;
      align-items: center;
      gap: 60px;
      .subscribe-content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
          color: #fff;
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 44px; /* 112.06% */
          padding-bottom: 15px;
        }
        p {
          color: #fff;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          max-width: 530px;
        }
      }

      .subscribe {
        padding: 43px 42px 42px 41px;
        width: 50%;
        border-radius: 13px;
        border: 1px solid #fff;
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(24.5px);
        .input-box {
          margin-bottom: 18px;
          input {
            width: 100%;
            padding: 14px 30px;
            border: none;
            border-radius: 31px;
            background: #fff;
            color: #797878;
            font-size: 15px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }
        .subscribe-btn {
          button {
            width: 100%;
            border-radius: 31px;
            padding: 14px 30px;
            color: #fff;
            background: #32c4c0;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
          }
        }
      }
    }
  }
}

//Media Queries ------------------------------------------------------------------------------------ //
@media screen and (max-width: 1500px) {
  .blog-detail {
    padding: 173px 70px 185.11px 70px;
  }
}

@media (max-width: 1200px) {
  .blog-detail {
    padding: 173px 70px 185.11px 70px;
  }
  .subscribe-sec {
    .subscribe-bg {
      display: flex;
      flex-direction: column;
      align-items: center;
      .subscribe {
        width: 50%;
        margin-top: 40px;
      }
    }
  }

  .news-letter .subscribe-sec .subscribe-bg {
    padding: 40px;
    gap: 60px;
    width: 100%;
    .subscribe {
      padding: 43px 42px 42px 41px;
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .blog-detail {
    padding: 80px 50px;

    .blog-content {
      .trick-sec {
        h2 {
          font-size: 38.962px;
          line-height: 52px;
          margin-bottom: 20px;
        }
        p {
          font-size: 18px;
          line-height: 24px;
        }
        .writers {
          margin-top: 40px;
          margin-bottom: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .blog-detail {
    padding: 50px 20px;

    .blog-content {
      .trick-sec {
        h2 {
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 20px;
        }
        p {
          font-size: 12px;
          line-height: 16px;
        }
        .writers {
          margin: 30px 0;
        }
      }
    }
  }

  .news-letter {
    padding: 0 20px !important;
    .subscribe-sec {
      padding-top: 60px;
      padding-bottom: 60px;
      .subscribe-bg {
        padding: 30px;

        .subscribe-content {
          h3 {
            font-size: 22px;
            line-height: 32px;
            padding-bottom: 10px;
          }
          p {
            font-size: 16px;
          }
        }

        .subscribe {
          padding: 30px;
          .input-box {
            margin-bottom: 12px;
            input {
              font-size: 12px;
              padding: 10px 20px;
            }
          }
          .subscribe-btn {
            button {
              font-size: 12px;
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }
}
