.features-sec {
  .banner-wrapper {
    position: relative;
    height: 95vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: linear-gradient(315deg, #fff, #32c4c0, #4A3AFF, #231f37, #000);
    background-size: 250% 250%;
    /*animation: color 18s ease-in-out infinite;*/
 
    @keyframes color {
      0%{
        background-position: 0 50%;
      }
      50%{
        background-position: 100% 50%;
      }
      100%{
        background-position: 0 50%;
      }
    }

    .herobg ul li{
      position: absolute;
      width: 35px;
      height: 35px;
      list-style: none;
      opacity: .5;
      background: radial-gradient(#32C4C0, transparent, transparent);
      bottom: 0;
      left: 10vw;
      animation: animate 15s linear infinite;
    }

        
    .herobg ul li:nth-child(2){
      left: 37vw;
      animation-delay: 2s;
    }
    .herobg ul li:nth-child(3){
      left: 25vw;
      animation-delay: 6s;
    }
    .herobg ul li:nth-child(4){
      left: 60vw;
      animation-delay: 10s;
    }
    .herobg ul li:nth-child(5){
      left: 75vw;
      animation-delay: 8s;
    }
    .herobg ul li:nth-child(2){
      left: 77vw;
      animation-delay: 2s;
    }
    .herobg ul li:nth-child(3){
      left: 25vw;
      animation-delay: 6s;
    }
    .herobg ul li:nth-child(4){
      left: 30vw;
      animation-delay: 10s;
    }
    .herobg ul li:nth-child(5){
      left: 55vw;
      animation-delay: 8s;
    }



    @keyframes animate {

      0%{
        transform: scale(0);
        opacity: 1;
        bottom: 0;
      }

      100% {
        transform: scale(10);
        opacity: 0;
        bottom: 80vh;
      }
      
    }

    
    .features-video {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-bottom: 60px;
    }
    .features-videonormal {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .features-banner {
      * {
        z-index: 2;
        text-align: center;
      }

      padding: 0px 100px;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      // gap: 20px;
      .dynamic-text{
        color: #32c4c0;
      }

      .highlight-free {
        color: #32C4C0;
      }

      .left {
        color: white;
        max-width: 1280px;

        // features.scss
        .features-text-container {
          display: flex;
          align-items: center;
          justify-content:flex-start;
          margin: -17px 20px 20px 50px;
          font-size: 25px;
          height: 100px;
          overflow: hidden;

          .static-text {
            color: #ffffff; 
            margin-right: 10px;
            line-height: 1.2;
          }
        }

        button {
          border: 2px solid #cde4dc;
          color: #fff;
          font-family: Poppins;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
        }

        .button-spacing {
          margin-right: 20px;
          margin-bottom: 20px;
          background: transparent;
          margin-top: 0px;
          transition: border 0.4s ease, color 0.4s ease, background-color 0.4 ease;
          border-radius: 20px;
      }      

        button:hover {
          background-color: rgba(0, 0, 0, 0.225);  // New background color on hover
          color: #32c4c0;             // Optional: Change text color if needed
          border-color: #32C4C0;
          transform: scale(0.99);
        }
        .forever-txt {
          color: transparent;
          -webkit-text-stroke: 2px white;
        }
      }
    }
  }

.button-spacing1 {
  border-radius: 18px;
  color: #000;
  border: 2px solid #000;
  transition: border 0.1s ease, color 0.1s ease, border-color 0.1s ease, transform 0.1s ease;
  margin-top: 0px;
  margin-bottom: 25px;
  height: 40px;
  width: 175px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .3px;
}



.button-spacing1:hover {
  color: #4A3AFF;
  border-color: #4A3AFF;
  font-weight: 700;
  border: 2.2px solid;
  transform: scale(0.98); /* Scale down slightly */
}

.button-spacing5 {
  border-radius: 18px;
  color: #ffffff;
  border: 2px solid #ffffff;
  transition: border 0.1s ease, color 0.1s ease, border-color 0.1s ease, transform 0.1s ease;
  margin-top: -20px;
  margin-bottom: 50px;
  height: 40px;
  width: 175px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: .5px;
}



.button-spacing5:hover {
  color: #4A3AFF;
  border-color: #4A3AFF;
  font-weight: 700;
  border: 2.2px solid;
  transform: scale(0.98); /* Scale down slightly */
}

.free-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style-type: none;
  width: 100%;
  margin-bottom: 4rem;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.free-list li{
  flex: 1 1 calc(25% - 20px);
  border: 1px solid #ccc;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
  margin: 5px;
  white-space: nowrap;
  border-radius: 15px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
}

.container4 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  align-items: center;
  height: 15vh;
  margin-bottom: 6rem;
}

.container4 p {
  max-width: 40%;
  font-size: 0.85rem;
  margin-right: 20px;
  line-height: 1.56;
  letter-spacing: 0.1px;
} 

.free-list li span {
  display: inline-block;
  padding: 0 10px;
}  
  // Features second section ----------------------------------------------------------------------------------- //
  .features-second-sec {

    padding: 195px 120px 89.53px 120px;
    background-color: white;
    margin-top: -350px;


    // Left side --------------------------------------- //
    .left {
      .content {
        color: #1f2d5c;
        max-width: 591px;

        p {
          color: #848484;
          margin-top: 38px;
          margin-bottom: 43px;
        }
      }

      .steps-sec {
        .step {
          display: flex;
          gap: 20px;
          margin-bottom: 16px;

          p {
            font-size: 22px;
          }
        }
      }
    }

    // Right side --------------------------------------- //
    .right {
      img {
        width: 100%;
      }
    }
  }

  .lines-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .lines {
      position: absolute;
      margin-top: 420px;
      z-index: 0;
      display: none;
    }
  }
}

  // Metrics section --------------------------------------------------------------------------------------------- //
  .metrics-sec {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: #1f2d5c;

    h1 {
      font-family: "Poppins", sans-serif;
      text-align: center;
      line-height: 1.05;
      margin-bottom: 20px;
      color: #000;
    }

    h3 {
      text-align: center;
      color: #000;
    }

    h4 {
      color: #000;
      font-size: 1.75rem;
    }

    p {
      color: #000;
      line-height: 1.56;
    }


    .metrics-card-sec {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 117px;

      .metrics-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 388px;
        height: 388px;
        border-radius: 388px;
        background: #fff;
        box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.09);

        .metrics-card-head {
          line-height: 23.705px;
        }
        .metrics-card-body {
          padding-inline: 35px;
        }
      }
    }
  }

  // Benefits section --------------------------------------------------------------------------------------------- //
  .benefits-sec {
    padding: 200px 319px 200px 319px;

    h3 {
      padding-bottom: 51px;
    }
    
    .free-list1 li {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: calc(25% - 20px);
      padding: 5px;
      box-sizing: border-box;
      text-align: center;
      margin: 5px;
      white-space: nowrap;
      border-radius: 15px;
      border: 0.5px solid #3c3749;
      font-size: 12px;
      position: relative;
      background-color: #1a181f; /* Set the background color */
      color: #fff; /* Set the text color to white */
    }
    
    .free-list1 li:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      z-index: -1; /* Place it behind the content */
      margin: -2px; /* Adjust based on border width */
    }
    

  }
  // Core Features Section --------------------------------------------------------------------------------------------- //


  // Newsletter Section --------------------------------------------------------------------------------------------- //

.video {
  padding-top: 50px;
  // padding-bottom: 140px;
  position: relative;
  .top {
    // padding-top: 80px;
    // position: relative;
    // z-index: 2;
    .years {
      color: #fff;
      background: #00cdac;
      width: 323px;
      height: 166px;
      border-radius: 13px;
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 0;
      right: 12%;
      z-index: 4;
    }
    .client-video {
      // transform: scale(1);
      transition: all 0.5s linear;
      border-radius: 24px;
      position: relative;
      max-width: 1250px;
      border-radius: 16px;
      z-index: 3;
      img {
        transition: all 0.4s linear;
      }
      video {
        border-radius: 6px;
        width: 100%;
        transition: all 0.4s linear;
        height: 100%;
      }
      .animation {
        position: absolute;
        top: 35%;
        left: 50%;
        right: 50%;
        transform: translate(-50%);
        z-index: 6;
        border-radius: 100%;
        width: 180px;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        .inner {
          object-fit: cover;
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 10;
          border-radius: 100%;
          box-shadow: 0px 0px 19.269px #00cdac;
        }
        .outer {
          position: absolute;
          top: -46px;
          left: -43px;
          right: 50%;
          width: 270px;
          height: 270px;
          object-fit: cover;
          z-index: 10;
          animation: rotateImage 5s infinite linear;
        }
        @keyframes rotateImage {
          0% {
            transform: rotate(0deg);
          }
          20% {
            transform: rotate(72deg);
          }
          40% {
            transform: rotate(144deg);
          }
          60% {
            transform: rotate(216deg);
          }
          80% {
            transform: rotate(288deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

      &:hover {
        transition: all 0.4s linear;
        .mainImage {
          box-shadow: 0px 0px 25px 0px #00cdab89;
          transition: all 0.7s linear;
          border-radius: 10px;
          // transform: scale(1.1);
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: -80px;
    z-index: 1;
    width: 100%;
    padding: 200px 50px 30px 50px;
    gap: 75px;
    background: #000;
    color: #fff;
    .content {
      h2 {
        // font-size: 3vw;
      }
      h3 {
        font-size: 3vw;
      }
    }
    .projects {
      // padding-bottom: 10px;
      h6 {
        font-size: 2.5vw;
      }
    }
  }
}

// Modal
.video-header {
  border-bottom: 0;
}
.modal-video {
  padding: 0;
  video {
    width: 100%;
    height: 100%;
  }
}

.video-modal {
  .modal-content {
    background: none !important;
    border: none;
  }
}

@media screen and (max-width: 1280px) {
  .metrics-sec .metrics-card-sec , 
  .metrics-sec .metrics-card-sec {
      bottom: -60px !important;
  }
  .video .bottom .content h2 {
      font-size: 70px;
  }
}

@media screen and (max-width: 1450px) {
  .video {
    margin-top: 20px !important;
    .top {
      .years {
        width: 250px;
        height: 130px;
        top: 40px;
      }
      .client-video {
        transform: scale(0.9);
      }
    }
    .bottom {
      bottom: 10px;
      .content {
        h2 {
          font-size: 2.5vw;
        }
        h3 {
          font-size: 2.5vw;
        }
      }
      .projects {
        padding-bottom: 0;
        h6 {
          font-size: 2vw;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .video {
    padding-top: 0;
    .top {
      padding-top: 40px;
      .years {
        top: 2%;
        width: 150px;
        height: 60px;
        left: 7%;
        h2 {
          font-size: 30px;
        }
        .features-text-container {
          display: flex;
          align-items: center;
          justify-content: start;
          margin: 20px;
          font-size: 32px;

          .static-text {
            color: #ffffff; // Example color
            font-weight: bold;
            margin-right: 10px;

          .button-spacing {
            margin-right: 0px;
          } 

          }
        }
      }
      .client-video {
        .animation {
          width: 60px;
          height: 60px;
          .outer {
            width: 150px;
            height: 150px;
            display: none;
          }
        }
        &:hover {
          .mainImage {
            transform: scale(1);
          }
        }
      }
    }
    .bottom {
      padding: 100px 20px 30px 20px;
      gap: 30px;
      bottom: 0;
      .content {
        align-items: unset;
        h2 {
          font-size: 30px !important;
        }
        h3 {
          font-size: 20px !important;
        }
      }
      .projects {
        h6 {
          font-size: 15px;
        }
      }
    }
  }
}



@media (max-width: 1024px) {
  .features-sec .benefits-sec {
    padding: 180px 200px 200px 200px;
  }
  .features-sec {
    .features-banner {
      padding: 150px 100px 90px 100px;

      .right {
        img {
          width: 100%;
          height: 580px;
        }
      }
    }
    .features-second-sec {
      padding: 150px 100px 89.53px 100px;
      gap: 40px;
    }

    .depend-sec {
      padding: 93px 100px 96px 100px;
    }
  }
}

@media (max-width: 1280px) {
  .features-sec .metrics-sec {
    display: flex;
    flex-direction: column;
  }
  .features-sec .metrics-sec .metrics-card-sec .metrics-card {
    width: 340px;
    height: 342px;
  }
  .features-sec
    .metrics-sec
    .metrics-card-sec
    .metrics-card
    .metrics-card-head
    img {
    width: 70px;
    height: 70px;
  }
  .features-sec
    .metrics-sec
    .metrics-card-sec
    .metrics-card
    .metrics-card-body
  
  .features-sec
    .metrics-sec
    .metrics-card-sec
    .metrics-card
    .metrics-card-body
    p {
    font-size: 16px;
    line-height: 26px;
  }
  .features-sec .benefits-sec {
    padding: 164px 150px 200px 150px;
  }
  .features-sec {
    .banner-wrapper {
      .features-banner {
        padding: 0px 0px 0px 100px;

        .left {
          max-width: 640px;
          p {
            font-size: calc(10px + 1.2vw);
          }
          h2 {
            font-size: 62px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media (max-width: 2500px) {
  .features-sec .benefits-sec .benefits-faq-sec .benefits-faq-blue {
    padding: 20px 104px 20px 70px;
    width: 900px;
    height: 90px;
  }
  .features-sec .benefits-sec .benefits-faq-sec .benefits-faq-blue ul li {
    font-size: 18px;
  }
  .features-sec .benefits-sec .benefits-faq-sec .benefits-faq {
    width: 900px;
    height: 90px;
  }
  .features-sec .benefits-sec .benefits-faq-sec .benefits-faq ul li {
    font-size: 18px;
  }

  .features-sec {
    .banner-wrapper {
      .features-banner {
        padding: 0px 0px;
        margin-top: 2rem;

        .left {
          max-width: 768px;
          p {
            font-size: calc(10px + 1.2vw);
          }
          h2 {
            font-size: 4.5rem;
            text-align: center;
            font-weight: 600;
            color: #ffffff;
            margin-top: 20px;
            margin-bottom: 1.5rem;
            opacity: 0.85;
            width: 100%;
            line-height: 1.1;
            max-width: 1000px;
            letter-spacing: 1.5px;
          }
          .static-text {
            margin-right: 10px;
            color: #FFFFFF;
        }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {

  .features-sec .banner-wrapper .features-banner .right img {
    width: auto;
    height: 400px;
  }
  .features-sec .features-second-sec .left .content {
    h3 {
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 46px;
    }
    p {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 2500px) {
  .features-sec .benefits-sec {
    padding: 0px !important;
  }
  .features-sec .benefits-sec .benefits-faq-sec .benefits-faq-blue {
    padding: 20px 50px 20px 70px;
  }
  .features-sec .benefits-sec .benefits-faq-sec .benefits-faq {
    padding: 20px 50px 20px 70px;
  }
  .features-sec .metrics-sec {
    z-index: 1;
    grid-row-gap: 7.5rem;
    flex-direction: column;
    padding-top: 4rem;
    display: flex;
    position: relative;
    margin-bottom: 5rem;
  }
  .metrics-sec{
    padding: 0px !important;
  
  }
  .text-gradient {
    background: linear-gradient(to left, #32c4c0, #6667e6, #4A3AFF, #0A1988);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .text-gradient1 {
    background: linear-gradient(to left, #32c4c0, #6667e6, #4A3AFF, #0A1988);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .features-sec .metrics-sec .metrics-card-sec .card-upline {
    position: absolute;
    width: 71% !important;
    left: -29px;
    bottom: -22px;
    display: none;
  }
  .metrics-card .metrics-card-body p {
    font-size: 12px !important;
    line-height: 16px !important;
  }
  .features-sec .metrics-sec .metrics-card-sec .card-downline {
    position: absolute;
    width: 72%;
    bottom: -27px;
    display: none;
  }
  .features-sec .metrics-sec .metrics-card-sec .metrics-card {
    width: 230px;
    height: 230px;
  }
  .features-sec .metrics-sec .metrics-card-sec {
    gap: 58px;
  }

  .features-sec {
    .features-banner {
      padding: 150px 100px 90px 100px;
      .right {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .left {
        h2 {
          line-height: 1.1;
          font-size: 3.8vw;
        }
        p {
          font-size: 15px;
        }
      }
    }
    .features-second-sec {
      padding: 100px 100px 0px 100px;
      margin-top: -230px;
      background-color: #ffffff;
      margin-bottom: 5rem;


      // Left side --------------------------------------- //
      .left {
        .content {
          h3 {
            font-size: 26px;
            line-height: 35px;
          }
          p {
            color: #848484;
            font-size: 16px;
            line-height: 20px;
            margin-top: 10px;
            margin-bottom: 20px;
          }
        }

        .steps-sec {
          .step {
            display: flex;
            gap: 20px;
            margin-bottom: 10px;

            p {
              font-size: 18px;
            }
          }
        }
      }
    }

    .depend-sec {
      padding: 80px;
      .depend-card-sec {
        .depend-card {
          border-radius: 10.003px;
          background: linear-gradient(90deg, #1f2d5c 0%, #06118e 100%);
          backdrop-filter: blur(6.25197696685791px);
          max-width: 455.593px;
          height: 230.591px !important;
          h6 {
            font-size: 20px;
            padding: 0 20px;
          }
        }
      }

      h3 {
        font-size: 36px;
      }

      .depend-card-sec {
        .depend-card {
          h6 {
            font-size: 24px;
            line-height: 30px;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 600px)  {
  .features-sec {
    .banner-wrapper {
      justify-content: flex-start;
      height: 90vh;
      .features-banner {
        padding: 0px 60px;

        .left {
          max-width: 640px;
          .features-text-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            margin: 25px;
            font-size: 25px;
            line-height: 0.8;
  
            .static-text {
              color: #ffffff; // Example color
              margin-right: 10px;
              text-align: center;
            }
            .button-spacing {
              margin-right: 0px;
          } 
          }
          h2 {
            font-size: 62px;
            text-align: center;

          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .feature-vid {
    width: 100%;
    height: 280px;
  }

  .features-sec {
    .features-banner {
      padding: 94px 23px 40px 23px;

      .right img {
        display: none;
      }
      .left {
        h1 {
          font-size: 26px;
          line-height: 35px;
        }
        p {
          font-size: 9px;
          line-height: 20px;
        }
      }
    }

    .features-second-sec {
      padding: 20px;

      padding-top: 80px;
      margin-bottom: 2rem;
      flex-direction: column;
      .left {
        .content {
          h3 {
            font-size: 23px;
            text-align: center;
            line-height: 27px;
          }
          p {
            font-size: 11px;
            line-height: 19px;
            text-align: center;
            margin-bottom: 0;
          }
        }
        .steps-sec .step {
          gap: 10px;
          margin-bottom: 5px;

          img {
            width: 23px;
            height: 23px;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
    .depend-sec {
      h3 {
        font-size: 24px;
      }
    }
  }

  .features-sec .depend-sec .depend-card-sec {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 23px;
  }
  .features-sec .depend-sec {
    padding: 10px 23px;
  }
  .features-sec .depend-sec .depend-card-sec .depend-card {
    border-radius: 8.003px;
    height: 150px !important;
    width: 100% !important;
  }
  .features-sec .depend-sec .depend-card-sec .depend-card h6 {
    font-size: 14px;
    line-height: 20px;
  }
  .features-sec .metrics-sec h3 {
    font-size: 33px;
    padding-bottom: 0px !important;
  }
  .features-sec .metrics-sec .metrics-card-sec .card-upline {
    display: none;
  }
  .features-sec .metrics-sec .metrics-card-sec .card-downline {
    display: none;
  }
  .features-sec .metrics-sec {
    padding: 20px 0px;
  }
  .features-sec .metrics-sec .metrics-card-sec {
    row-gap: 21px;
    padding: 23px;
    display: flex;
    flex-direction: column;
  }
  .metrics-card .metrics-card-body p {
    font-size: 12px !important;
    line-height: 12px !important;
  }
  .features-sec
    .metrics-sec
    .metrics-card-sec
    .metrics-card
    .metrics-card-head
    img {
    width: 50px;
    height: 50px;
  }


  .features-sec
    .metrics-sec
    .metrics-card-sec
    .metrics-card
    .metrics-card-body
    h4 {
    font-size: 20px;
    line-height: 120%;
    margin: 10px 0;
  }
  .features-sec .benefits-sec {
    padding: 20px;
  }
  .features-sec .benefits-sec .benefits-faq-sec .benefits-faq-blue ul li {
    font-size: 10px;
  }
  .features-sec .benefits-sec .benefits-faq-sec .benefits-faq-blue {
    border-radius: 15px;
    width: 95%;
    padding: 15px 20px 15px 32px;
  }
  .features-sec .benefits-sec h3 {
    padding-bottom: 30px;
    font-size: 24px;
  }
  .features-sec .benefits-sec .benefits-faq-sec .benefits-faq {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 0px 12.50395px 0px rgba(0, 0, 0, 0.25);
    width: 95%;
    padding: 15px 20px 15px 32px;
  }
  .features-sec .benefits-sec .benefits-faq-sec .benefits-faq ul li {
    font-size: 10px;
  }
  .features-sec .benefits-sec .benefits-faq-sec ul li {
    font-size: 10px;
  }
  
  .features-sec .metrics-sec {
    padding: 23px !important;
    margin-bottom: 3rem;


  .free-list li {
    flex: 1 1 calc(50% - 10px); /* Adjust to 2 items per row */
    max-width: calc(50% - 10px);
  }

  .container4 {
    flex-direction: column; /* Stack items vertically */
    height: auto; /* Adjust height to content */
    margin-bottom: 4rem; /* Adjust bottom margin */
    margin-top: 2rem;
  }

  .container4 p {
    max-width: 90%;
    margin-right: 0;
    margin-bottom: 1rem; /* Add bottom margin for spacing */
    text-align: center; /* Center align text */
  }

  

  }

  .features-sec {
    .banner-wrapper {
      justify-content: flex-start;
      height: auto;
      padding: 80px 0 80px 0;
      margin-bottom: 50px;
      .features-banner {
        padding: 0px 10px;

        .left {
          max-width: 100%;
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          p {
            font-size: 18px;
            margin-top: 10px;
            text-align: center;
          }
          h2 {
            font-size: 60px;
            line-height: 120%;
            text-align: center;
          }
        }
        .right {
          display: none;
        }
      }
    }
  }
}
