.tooltip-inner { 
    border-radius: 10px;
    border: 1px solid #151d37;
    background: #0d101c !important;
}

.tooltip-arrow {
  color: linear-gradient(90deg, #32c4c0 0%, #1f2d5c 100%) !important;
}

#button-tooltip  {
    border-radius: 10px;
}