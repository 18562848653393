* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins";
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
a,
p,
.row,
.col {
  padding: 0;
  margin: 0;
  margin-bottom: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

// ---------------custom margins

.mt-30 {
  margin-top: 30px;
}

// ---------------custom image sizes
.img-100 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input {
  outline: none;

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-outer-spin-button {
    display: none;
  }
}

.toast-div {
  display: grid;
  // grid-template-columns: 2;
  // button {
  //   padding: 10px !important;
  // }
}

// --------------------icons
.circled-icon {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border-radius: 24.566px;
  border: 0.768px solid #eaecee;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

body {
  &::-webkit-scrollbar {
    display: none;
  }
}

.dash-dropdown {
  .dropdown-menu.show {
    display: block;
    background: white;
    display: flex;
    justify-content: center;
    width: fit-content;
    min-width: unset;
    padding: 3px 10px;
    margin-top: 10px;

    a {
      color: black;
    }
  }
}

.rc-pagination-item-active {
  background: #11373e !important;
  color: white !important;
}

.show-on-mobile {
  display: none;
}

.customImportantRed {
  color: rgb(255, 255, 255);
}

.scrollable-menu {
  max-height: 200px;
  /* Set a maximum height for your dropdown menu */
  overflow-y: auto;
  /* Add scroll to the y-axis */
  overflow-x: auto;

  /* Hide the horizontal scroll */
}

.calendly-popup-close {
  display: none !important;
}

.bg-cyan {
  background: linear-gradient(270deg, #4A3AFF 0%, #1f2d5c 100%);
}

.loader {
  bottom: 50%;
  position: fixed;
  right: 37%;
  /* left: 50%; */
  color: #000000;
  /* border-radius: 50%; */
  width: 300px;
  height: 60px;
  z-index: 9999;
}

.customLoadingText {
  letter-spacing: 2px;
  font-family: "Poppins";
  font-weight: 500;
  color: #e9e9e9;
  font-size: 25px;
  text-shadow: 0 0 10px #11373e, 2px 2px 3px #000000;
}

//scope ul
.scope-ul {
  list-style-type: disc;
  // padding-left: 31px;

  li {
    font-size: 12px;
  }
}

.apperannce-auto {
  appearance: auto;
}

.bgpricing{
  position: relative;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: linear-gradient(315deg, #fff, #32c4c0, #4a3aff, #231f37, #000);
  background-size: 250% 250%;
}

@media screen and (max-width: 1024px) {
  .loader {
    bottom: 50%;
    position: fixed;
    right: 5%;
    /* left: 50%; */
    color: #000000;
    /* border-radius: 50%; */
    width: 300px;
    height: 60px;
    z-index: 9999;
  }

  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  .hide-on-mobile {
    display: none !important;
  }

  .show-on-mobile {
    display: flex !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.news-card {
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.pricing-container{
  max-width: 225px;  
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.price-text{
  font-size: 2.1rem;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;

}

.monthly{
  font-size: .75rem;
  font-weight: 400;
  opacity: .7;
  margin-top: 15px; 

}


@media screen and (max-width: 1200px) {
  .news-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .news-grid {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}

.HeroCard__container {
  grid: auto/repeat(auto-fit, minmax(0, 1fr));
  display: grid;
  height: 100%;
  margin-top: 10px;
  border-radius: 6px;
  border: 1px solid var(--neutral-300, #eff0f6);
  background: var(--neutral-100, #fff);
  box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
  cursor: auto;
}

/* For small and medium devices (up to 992px for medium, 576px for small) */
@media (max-width: 650px) {
  .HeroCard__container {
    display: block; /* changes the display property to block on small and medium screens */
    margin-top: 0px;
  }
}

.HeroCard__copy {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: var(--heroCardCopyAlign);
  padding: 20px;
  row-gap: 10px;
}

/* For medium devices (tablets: 768px and up) */
@media (min-width: 768px) {
  .HeroCard__copy {
    padding: 20px; /* Adjust as needed */
  }
}

/* For small devices (landscape phones, 576px and up) */
@media (max-width: 600px) {
  .HeroCard__copy {
    padding: 16px; /* Adjust as needed */
  }
}
.nav-link:hover {
  color: #32C4C0 !important; // This sets the hover color
}


.HeroCard__title.CopyTitle {
  --titlePaddingLeft: 0;
  --titlePaddingRight: 0;
  font-size: 26px;
  color: var(--titleColor);
  letter-spacing: 0;
  font-style: normal;
  font-weight: 600;
}
.CopyBody {
  position: relative;
  padding: 0 var(--bodyPaddingRight) 0 var(--bodyPaddingLeft);
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightNormal);
  font-size: var(--bodyFontSize);
  line-height: var(--bodyLineHeight);
  color: var(--textColor);
  letter-spacing: 0.2px;
  /* max-width: var(--copyMaxWidth); */
}

.CopyBody--variantDetail,
.CopyBody--variantStat {
  font-size: 14px;
  line-height: 1.6;
}
.HeroCard__graphic {
  display: grid;
  justify-items: stretch;
  align-items: stretch;
}

.HeroPricingSubcard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  border-radius: 4px;
  background-color: #f6f9fc;
  margin: 6px;
}

.HeroPricingSubcard__container {
  display: grid;
  justify-items: center;
  row-gap: 4px;
  text-align: center;
}

.HeroPricingSubcard__title {
  --fontSize: 26px;
  --lineHeight: 1.384615385;
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightBold);
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
  color: var(--titleColor);
}

.HeroPricingSubcard__addon,
.HeroPricingSubcard__prefix,
.HeroPricingSubcard__suffix {
  font-size: 14px;
  line-height: 1.5;
  // font: var(--fontWeightNormal), var(--fontSize), var(--fontFamily);
  line-height: var(--lineHeight);
  max-width: 200px;
}

/* styles.css */
.maxwidth {
  margin-left: auto;
  margin-right: auto;
  max-width: 65rem;
}
.textletterspacingupdated {
  font-size: 15px;
  font-weight: 600;
}
.maxwidthwhatsinclude {
  margin-left: auto;
  margin-right: auto;
  max-width: 77rem;
}
.maxwidth-updated-for {
  margin-left: auto;
  margin-right: auto;
  max-width: 69rem;
}
.CardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  z-index: 10;
  margin-top: 2rem;
  max-width: 980px;
}

.blog-hero{
  position: relative;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: linear-gradient(315deg, #fff, #32C4C0, #4A3AFF, #231f37, #000);
  background-size: 250% 250%;
}

.hero-text h2{
  line-height: 1.1;
  font-size: 4rem;
  color: white;
  letter-spacing: 1.5px;
  font-weight: 600;
}

/* Responsive design for medium devices (768px and up) */
@media screen and (max-width: 768px) {
  .CardContainer {
    grid-template-columns: 1fr; /* Stacks cards vertically */
    padding: 0px 10px;
  }
}

.PricingCustomTierSubcard {
  gap: 4px;
  list-style: none;
  margin: 0;
  padding: 0;
  min-height: 50px;
  padding: 6px;
  color: white;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .PricingCustomTierSubcard {
    padding: 4px; /* Adjust smaller padding for smaller screens */
    /* Add more properties as necessary */
  }
}

.PricingCustomTierSubcard__item {
  text-align: center;
  padding: 0 8px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: var(--titleColor); */
}

@media screen and (max-width: 768px) {
  .PricingCustomTierSubcard__item {
    padding: 8px 4px; /* Adjust smaller padding for smaller screens */
    margin: 0 0 10px 90px;
  }
}

.HeroCard__ctaContainer {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 14px;
}
.CtaButton.variant--Button.CtaButton--arrow {
  padding-right: 12px;
}
.CtaButton.variant--Button {
  padding-left: 16px;
  padding-right: 16px;
  background-color: var(--buttonColor);
  color: var(--knockoutColor);
  white-space: nowrap;
  transition-property: background-color, opacity;
}

.CtaButton {
  display: inline-block;
  padding: 6px 0 6px;
  border-radius: 999px;
  font-size: 15px;
  font-weight: 500;
  color: var(--buttonColor);
  transition: var(--hoverTransition);
  outline: none;
}

.badge {
  background-color: #1f446800;
  color: white;
  padding: 6px 12px;
  text-align: center;
  border-radius: 12px;
  font-size: 14px;
  letter-spacing: 0.1px;
  font-weight: 500;
  border: 1px solid white;
}

.displayrow-updated {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 10;
  margin-top: 7rem;
  gap: 2.5rem;
}

.section_our_solution .row {
  align-items: center;
}

.our_solution_category {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.our_solution_category .solution_cards_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.solution_cards_box .solution_card {
  flex: 0 50%;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2),
    0 5px 15px 0 rgba(37, 44, 97, 0.15);
  border-radius: 15px;
  margin: 8px;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 265px;
  transition: 0.7s;
}

.solution_cards_box .solution_card:hover {
  background: #309df0;
  color: #fff;
  transform: scale(1.1);
  z-index: 9;
}

.solution_cards_box .solution_card:hover::before {
  background: rgb(85 108 214 / 10%);
}

.solution_cards_box .solution_card:hover .solu_title h3,
.solution_cards_box .solution_card:hover .solu_description p {
  color: #fff;
}

.solution_cards_box .solution_card:before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  width: 170px;
  height: 400px;
  z-index: -1;
  transform: rotate(42deg);
  right: -56px;
  top: -23px;
  border-radius: 35px;
}

.solution_cards_box .solution_card:hover .solu_description button {
  background: #fff !important;
  color: #309df0;
}

.solution_card .so_top_icon {
}

.solution_card .solu_title div {
  color: #212121;
  font-size: 1.3rem;
  margin-top: 13px;
  margin-bottom: 13px;
}

.solution_card .solu_description p {
  font-size: 15px;
  margin-bottom: 15px;
}

.solution_card .solu_description button {
  border: 0;
  border-radius: 15px;
  background: linear-gradient(
    140deg,
    #4A3AFF 0%,
    #4A3AFF 50%,
    #4A3AFF 75%
  ) !important;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  padding: 5px 16px;
}

.our_solution_content div {
  text-transform: capitalize;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.our_solution_content p {
}

.hover_color_bubble {
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  transform: rotate(-36deg);
  left: -18rem;
  transition: 0.7s;
}

.solution_cards_box .solution_card:hover .hover_color_bubble {
  top: 0rem;
}

.solution_cards_box .solution_card .so_top_icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.solution_cards_box .solution_card .so_top_icon img {
  width: 40px;
  height: 50px;
  object-fit: contain;
}

/*start media query*/
@media screen and (min-width: 320px) {
  .sol_card_top_3 {
    position: relative;
    top: 0;
  }

  .our_solution_category {
    width: 100%;
    margin: 0 auto;
  }

  .our_solution_category .solution_cards_box {
    flex: auto;
  }
}

@media only screen and (min-width: 768px) {
  .our_solution_category .solution_cards_box {
    flex: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .sol_card_top_3 {
    position: relative;
    top: -3rem;
  }

  .our_solution_category {
    width: 80%;
    margin: 0 auto;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 980px;
}

/* Responsive for smaller screens below 800px */
@media (max-width: 800px) {
  .grid-container {
    grid-template-columns: 1fr;
    padding: 25px;
    margin-bottom: 20px;
  }
}

.List__list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}

.Gradient__canvas {
  position: relative;
  display: block;
  width: inherit;
  height: 100%;
}

.Gradient__canvas.isLoaded {
  opacity: 1;
  transition: opacity 1.8s ease-in 50ms;
}

.chat-bot {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 999;
  height: auto;
  iframe {
    width: fit-content;
    height: auto;
  }
}

.hide-svg-updated {
  display: none;
}

::-webkit-scrollbar {
  display: none;
}



/* For devices with small size (Mobiles, 576px and up) */
@media screen and (max-width: 601px) {
  .features-video {

  }
}

a.hoverEffect {
  transition: transform 100ms ease-in-out;
}

a.hoverEffect:hover {
  transition: transform 200ms ease-in-out;

  transform: translateY(4px);
}

.font-Size-Updated {
  font-size: 12px;
  font-weight: 500;
}

.paddingaddingupdated {
  padding: 33px;
}

.ResponsiveText {
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
  line-height: 86px;
  text-align: center;
}

/* For devices with medium size (Tablets, 768px and up) */
@media screen and (max-width: 768px) {
  .ResponsiveText {
    font-size: 48px; /* adjust value as needed */
    line-height: 58px; /* adjust value as needed */
    text-align: center;
  }
}

/* For devices with small size (Mobiles, 576px and up) */
@media screen and (max-width: 576px) {
  .ResponsiveText {
    font-size: 24px; /* adjust value as needed */
    line-height: 30px; /* adjust value as needed */
    text-align: center;
  }
}

.ResponsiveTextupdated {
  font-size: 4.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 86px;
  line-height: 1.2;
  letter-spacing: -1px;
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 4rem;
  letter-spacing: 1.0px;
  max-width: 1000px;
}

/* For devices with medium size (Tablets, 768px and up) */
@media screen and (max-width: 768px) {
  .ResponsiveTextupdated {
    font-size: 48px; /* adjust value as needed */
    line-height: 58px; /* adjust value as needed */
    text-align: center;
    letter-spacing: 0px;
    color: white;
  }
}

/* For devices with small size (Mobiles, 576px and up) */
@media screen and (max-width: 576px) {
  .ResponsiveTextupdated {
    font-size: 32px; /* adjust value as needed */
    line-height: 30px; /* adjust value as needed */
    text-align: center;
    letter-spacing: 0px;
    color: white;
  }

  .displayrow-updated {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    z-index: 10;
    margin-top: 12rem;
    gap: 1.5rem;
  }
}

.svgResponsive {
  width: 7em;
  height: 7em;
}

/* For medium devices (tablets: 768px and up) */
@media (min-width: 768px) {
  .svgResponsive {
    width: 7em;
    height: 7em;
  }
}

/* For small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .svgResponsive {
    width: 5em;
    height: 5em;
  }
}

.cardUpdatedresponsiveness {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 50%;
  padding-top: 30px 
}

@media (max-width: 600px) {
  .cardUpdatedresponsiveness {
    width: 100%; 
  }
}

.textmakingresponsiveness {
  font-size: 12px; /* default font size */
}

/* For medium devices (tablets: 768px and up) */

/* For small devices (landscape phones, 576px and up) */
@media (max-width: 600px) {
  .textmakingresponsiveness {
    font-size: 8px; /* font size for small devices */
  }
}

.ContactusResponsiveness {
  position: relative;
  top: 70px;
  // padding-left: 150px;
  padding-left: 150px;
  padding-right: 150px;
  font-weight: 600;
  font-size: 35px;
}

@media (max-width: 600px) {
  .ContactusResponsiveness {
    padding-left: 50px;
    padding-right: 50px;
  }
}



.responsiveness-text-updated {
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 66px;
}

/* For medium devices (tablets: 768px and up) */
.centeredupdated {
  text-align: start;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

/* For small devices (landscape phones, 576px and up) */
@media (max-width: 600px) {
  .responsiveness-text-updated {
    font-size: 25px; /* adjust as needed */
    line-height: 33px; /* adjust as needed */
  }
  .centeredupdated {
    text-align: center;
  }
}

.yourUniqueClassName {
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
}

.ResponsiveTextforpara {
  padding: 0px 0px 0px 0px;
  text-align: center;
}

@media (max-width: 600px) {
  .ResponsiveTextforpara {
    padding: 0px 10px 0px 10px;
    text-align: center;
  }
}

.padded-svg {
  transform: translateX(5px);
}

.blur-background {
  filter: blur(3px);
  cursor: "pointer";
}

.top-banner-button {
  button {
    border-radius: 96px;
    border: 2px solid #fff;
    background: linear-gradient(90deg, #4A3AFF 0%, #1f2d5c 100%);
    color: var(--neutral-100, #fff);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
}

// svg {
//   position: fixed;
// }

// svg polygon {
//   fill: rgb(255, 0, 0);
// }




.iframe-large {
  width: 100%;
  height: 580px;
  border: none;
}

@media screen and (min-width: 990px) and (max-width: 1064px) {
  .iframe-large {
    height: 670px;
  }
}
@media screen and (min-width: 600px) and (max-width: 681px) {
  .iframe-large {
    height: 670px;
  }
}



@media screen and (min-width: 521px) and (max-width: 600px) {
  .iframe-large {
    height: 480px !important;
  }
}
@media screen and (max-width: 600px) {
  .iframe-large {
    height: 680px;
  }
}



@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.element {
  background: linear-gradient(270deg, #4A3AFF, #1f2d5c);
  background-size: 200% 200%;
  animation: gradient 2s linear infinite;
}